import BigNumber from 'bignumber.js';
import * as Types from './types';

const initialState: Types.ConstantsStoreState = {
  hrc20Decimal: new BigNumber(18),
  landView: 'grid',
  bidTokenPrice: 0,
  marketFee: '1',
};

function appReducer(
  state = initialState,
  action: Types.ConstantsActionTypes,
): Types.ConstantsStoreState {
  switch (action.type) {
    case Types.SET_HRC20_DECIMAL:
      return { ...state, hrc20Decimal: action.payload };
    case Types.SET_LAND_VIEW:
      return { ...state, landView: action.payload };
    case Types.SET_BID_TOKEN_PRICE:
      return { ...state, bidTokenPrice: action.payload };
    case Types.SET_MARKET_FEE:
      return { ...state, marketFee: action.payload };
    default:
      return state;
  }
}
export default appReducer;
