import React from 'react';

const MarketIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="market" transform="translate(-172 -252)">
        <g id="shop" transform="translate(172 252)">
          <path
            id="Vector"
            d="M28.11.42V9.4a7.5,7.5,0,0,1-7.5,7.5H7.5A7.5,7.5,0,0,1,0,9.4V.525A6.115,6.115,0,0,0,4.515,2.46,6.139,6.139,0,0,0,9.36,0a5.631,5.631,0,0,0,4.7,2.46A5.6,5.6,0,0,0,18.72.06a6.149,6.149,0,0,0,4.8,2.4A5.985,5.985,0,0,0,28.11.42Z"
            transform="translate(3.945 16.665)"
            fill="#0bb4e7"
            opacity="0.4"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M10.135,0h-9L.025,11.04A5.833,5.833,0,0,0,.46,13.89a5.6,5.6,0,0,0,5.19,3.36,5.589,5.589,0,0,0,5.2-3.345,5.968,5.968,0,0,0,.45-2.16V11.46Z"
            transform="translate(12.35 1.875)"
            fill="#0bb4e7"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M13.275,10.53,12.84,6.375C12.21,1.845,10.155,0,5.76,0H0L1.11,11.25a5.387,5.387,0,0,1,.03.6,5.727,5.727,0,0,0,.69,2.145A6.219,6.219,0,0,0,7.2,17.25a5.973,5.973,0,0,0,4.92-2.445A6.109,6.109,0,0,0,13.275,10.53Z"
            transform="translate(20.265 1.875)"
            fill="#0bb4e7"
            opacity="0.6"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M7.529,0c-4.41,0-6.45,1.845-7.1,6.42l-.4,4.125a6.166,6.166,0,0,0,1.23,4.38A6.015,6.015,0,0,0,6.1,17.25a6.209,6.209,0,0,0,5.355-3.225,5.583,5.583,0,0,0,.72-2.265L13.349.015H7.529Z"
            transform="translate(2.356 1.875)"
            fill="#0bb4e7"
            opacity="0.6"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M3.345.053A3.743,3.743,0,0,0,0,3.788V8.633H8.625V4.313C8.64,1.178,6.8-.307,3.345.053Z"
            transform="translate(13.68 24.937)"
            fill="#0bb4e7"
          />
          <path id="Vector-6" data-name="Vector" d="M0,0H36V36H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};
export default MarketIcon;
