import React from 'react';
import { CustomIconProps, setColor } from '..';

const UserIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="user" transform="translate(-108 -252)">
        <g id="frame" transform="translate(108 252)">
          <path
            id="Vector"
            d="M3.958,0a3.955,3.955,0,0,0-.1,7.908.672.672,0,0,1,.183,0H4.1A3.955,3.955,0,0,0,3.958,0Z"
            transform="translate(6.042 1.667)"
            fill={iconColor}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M10.1,1.163a8.274,8.274,0,0,0-8.458,0A3.289,3.289,0,0,0,0,3.854,3.262,3.262,0,0,0,1.633,6.529,7.7,7.7,0,0,0,5.867,7.7,7.7,7.7,0,0,0,10.1,6.529a3.288,3.288,0,0,0,1.633-2.692A3.281,3.281,0,0,0,10.1,1.163Z"
            transform="translate(4.133 10.629)"
            fill={iconColor}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H20V20H0Z"
            transform="translate(20 20) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
export default UserIcon;
