import React from 'react';
import { CardProps, Typography, TypographyProps } from '@mui/material';
import { CardBody, CardDescription, IAsset } from 'models/@types';
import CMPCardContent from 'components/global/card-content';
import Div from 'components/global/div';
import CMPImage from 'components/global/image';
import CMPClassicCard, { classes } from './_styles';

interface IProps extends CardProps {
  asset?: IAsset;
  title?: string;
  rank?: number;
  body?: CardBody;
  description?: CardDescription;
  time?: string;
  optionIcons?: React.ReactNode[];
  titleProps?: TypographyProps<'div'>;
  endIcon?: React.ReactNode;
}

const ClassicCard: React.FunctionComponent<IProps> = ({
  asset,
  body,
  description,
  title,
  titleProps,
  time,
  optionIcons,
  endIcon,
  rank,
  ...props
}) => {
  return (
    <CMPClassicCard className="justify-content-between" {...props}>
      {asset && (
        <Div className={classes.imgContainer}>
          <CMPImage asset={asset} width="100%" height="100%" style={{ objectFit: 'cover' }} />
        </Div>
      )}
      <Div className="d-flex justify-content-between" sx={{ width: 'calc(100% - 128px)' }}>
        <Div className="d-flex flex-column justify-content-between ms-3">
          <CMPCardContent
            body={body}
            description={description}
            title={title}
            titleProps={titleProps}
            rank={rank}
            classic
          />
        </Div>
        <Div
          sx={{ height: '100%' }}
          className="d-flex flex-column justify-content-between align-items-end"
        >
          <Div sx={{ width: '100%' }} className="d-flex align-items-center justify-content-end">
            {optionIcons && (
              <Div className="d-flex align-items-center">{optionIcons.map(icon => icon)}</Div>
            )}
            {endIcon}
          </Div>
          <Typography
            sx={{ width: '100%', minHeight: 32, textAlign: 'right' }}
            variant="body2"
            color="text.secondary"
            className="d-flex align-items-center text-capitalize"
            noWrap
          >
            {time}
          </Typography>
        </Div>
      </Div>
    </CMPClassicCard>
  );
};

export default ClassicCard;
