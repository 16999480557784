import BigNumber from 'bignumber.js';
import store from 'store';
import UserApi from 'apis/user';
import { IEmailLoginReq, ISignupReq } from 'apis/user/_';
import { deleteUserDataAction } from 'store/user/actions';
import Strings from 'utils/Strings';
import { CreateHRC20Contract } from 'services/CreateContract';
import { Activity, IActivity } from 'models/Activity';
import { IUser, User } from 'models/User';
import { showSnackbar } from 'components/global/snackbar';

export const signupService = async (data: ISignupReq): Promise<boolean> => {
  const response = await UserApi.Signup(data);
  if (response.success) {
    return true;
  }
  if (!response.errors || response.errors.length === 0) {
    showSnackbar({ severity: 'error', message: response.message || Strings.error });
  }
  return false;
};

export const emailLoginService = async (
  data: IEmailLoginReq,
): Promise<string | number | undefined> => {
  try {
    const response = await UserApi.EmailLogin(data);
    if (response.success) {
      return response.data.token as string;
    } else if (!response.errors || response.errors.length === 0) {
      showSnackbar({ message: response.message || Strings.error, severity: 'error' });
      if (response.message === 'Not Found.') return 404;
      else return 403;
    }
  } catch (e) {
    return;
  }
};

export const logoutService = (): void => {
  store.dispatch(deleteUserDataAction() as any);
};

export const userIsLoggedIn = (): boolean => {
  const { userToken } = store.getState().User;
  return userToken !== null && userToken.token !== '';
};

export const userInformationService = async (
  wallet: string | undefined,
): Promise<User | undefined> => {
  if (!wallet) return;
  const response = await UserApi.getUserInformation({ wallet });
  if (response.success) {
    return new User(response.data as IUser);
  } else if (!response.errors || response.errors.length === 0) {
    showSnackbar({ message: response.message || Strings.error, severity: 'error' });
    return undefined;
  }
};

export const forgotPasswordService = async (email: string | undefined): Promise<void> => {
  if (!email) return;
  const response = await UserApi.forgotPassword({ email });
  if (response.success) {
    showSnackbar({
      message: Strings.verificationCode,
      severity: 'success',
    });
  } else if (!response.errors || response.errors.length === 0) {
    showSnackbar({ message: response.message || Strings.error, severity: 'error' });
  }
};

export const verifyEmailService = async (email?: string, OTP?: string): Promise<boolean> => {
  if (!email || !OTP) return false;
  try {
    const response = await UserApi.verifyEmail({ email, OTP });
    if (response.success) {
      showSnackbar({
        message: Strings.emailVerified,
        severity: 'success',
      });
      return true;
    } else if (!response.errors || response.errors.length === 0) {
      showSnackbar({ message: response.message || Strings.error, severity: 'error' });
      return false;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const resendVerificationService = async (email: string): Promise<boolean> => {
  try {
    const response = await UserApi.resendVerification({ email });
    return !!response.success;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const resetPasswordService = async (token: string, password: string): Promise<boolean> => {
  try {
    const response = await UserApi.resetPassword({ token, password });
    return !!response.success;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getUserHasActivityService = async (): Promise<boolean> => {
  try {
    const response = await UserApi.getHasActivity();
    if (response.success && response.data) {
      return response.data.hasActivity as boolean;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export interface IActivityResponse {
  activities: Activity[];
  hasActivity: boolean;
}

export const getUserActivitiesService = async (): Promise<IActivityResponse> => {
  try {
    const hasActivity = await getUserHasActivityService();
    const response = await UserApi.getUserActivities();
    if (response.success) {
      const data = response.data as IActivity[];
      return {
        activities: data.map(item => new Activity(item)),
        hasActivity,
      };
    }
    return {
      activities: [],
      hasActivity,
    };
  } catch (e) {
    console.log(e);
    return {
      activities: [],
      hasActivity: false,
    };
  }
};

export const getUserMagicBalance = async (): Promise<string> => {
  const hrc20Contract = CreateHRC20Contract();
  const { user } = store.getState().User;
  if (hrc20Contract && user) {
    const balance = await hrc20Contract.balanceOf(user.wallet);
    if (balance) {
      return new BigNumber(balance.toString()).div(new BigNumber(10).pow(18)).toString();
    }
    return '0.00';
  }
  return '0.00';
};
