import React from 'react';
import DashboardPage from 'pages/dashboard';
import LoginPage from 'pages/login';
import MarketPlacePage from 'pages/market-place';
import NftPage from 'pages/nft';
import ProfilePage from 'pages/profile';
import ResetPasswordPage from 'pages/reset-password';
import SignupPage from 'pages/signup';

export interface IRoute {
  path: string;
  element: React.FunctionComponent;
  isRoot?: boolean;
  isPrivate?: boolean;
  isAuth?: boolean;
}

const ApplicationRoutes: Array<IRoute> = [
  {
    path: '/:category',
    element: MarketPlacePage,
    isRoot: true,
  },
  {
    path: '/:category/:contract/:tokenId',
    element: NftPage,
  },
  {
    path: '/dashboard',
    element: DashboardPage,
  },
  {
    path: '/login',
    element: LoginPage,
    isRoot: true,
    isAuth: true,
  },
  {
    path: '/signup',
    element: SignupPage,
    isRoot: true,
    isAuth: true,
  },
  {
    path: '/profile',
    element: ProfilePage,
    isRoot: true,
    isPrivate: true,
  },
  {
    path: '/users/:wallet',
    element: ProfilePage,
  },
  {
    path: '/reset-password/:token',
    element: ResetPasswordPage,
  },
];
export default ApplicationRoutes;
