import React, { useState } from 'react';
import { PixiComponent, Stage } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import { useQuery } from 'react-query';
import { CircularProgress, useTheme } from '@mui/material';
import { regionItems } from 'constants/filters/Lands';
import LandRoadData from 'constants/LandRoadData';
import { getMapData } from 'services/MarketPlace';
import { MapLand } from 'models/MapLand';
import Div from 'components/global/div';
import Tooltip from './_Tooltip';
import CustomViewport from './_ViewPort';
import Zoom from './_Zoom';

interface RectangleProps {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
}

const Rectangle = PixiComponent<RectangleProps, PIXI.Graphics>('Rectangle', {
  create: () => new PIXI.Graphics(),
  applyProps: (ins, _, props) => {
    ins.beginFill(parseInt(props.color.slice(1), 16));
    ins.lineStyle(0.5, 0x000000, 0.1, 0);
    ins.drawRect(props.x, props.y, props.width, props.height);
    ins.endFill();
  },
});

const StrokedRectangle = PixiComponent<RectangleProps, PIXI.Graphics>('StrokedRectangle', {
  create: () => new PIXI.Graphics(),
  applyProps: (ins, _, props) => {
    ins.beginFill(parseInt(props.color.slice(1), 16), 0);
    ins.lineStyle(2.5, 0x140034, 1, 0);
    ins.drawRect(props.x, props.y, props.width, props.height);
    ins.endFill();
  },
});

export interface IState {
  xScreen: number;
  xLand: number;
  yScreen: number;
  yLand: number;
  land: MapLand;
}

const LandCanvas: React.FunctionComponent = () => {
  const { palette } = useTheme();
  const [state, setState] = useState<IState | undefined>(undefined);

  const { data, isLoading } = useQuery<MapLand[]>(['complete-land-data'], () => getMapData(), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  const onSelect = (x: number, y: number, xLand: number, yLand: number) => {
    if (data) {
      const land = data.find(item => item.xCoordinate === xLand && -item.yCoordinate === yLand);
      if (land) {
        setState({
          xLand,
          xScreen: x,
          yLand,
          yScreen: y,
          land,
        });
      }
    }
  };

  return (
    <Div sx={{ position: 'relative', maxWidth: '100%', maxHeight: '100%' }}>
      {isLoading && (
        <Div
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
            display: 'flex',
            paddingTop: '32px',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={68} />
        </Div>
      )}
      <Stage
        width={1090}
        height={1090}
        options={{
          backgroundColor: 0x7bddf0,
          antialias: true,
        }}
      >
        <CustomViewport key={data?.length.toString()} onClick={onSelect}>
          {data && data.length > 0 && (
            <>
              {data.map(square => {
                const color = square.onSale
                  ? '#C70939'
                  : regionItems.find(
                      item => item.text.toLowerCase() === square.attributes?.REGION.toLowerCase(),
                    )?.color || palette.filterCard;
                return (
                  <Rectangle
                    key={`${square.xCoordinate}__${square.yCoordinate}`}
                    x={square.xCoordinate + 54 + 9 * (square.xCoordinate + 54)}
                    y={-square.yCoordinate + 54 + 9 * (-square.yCoordinate + 54)}
                    width={10}
                    height={10}
                    color={color}
                  />
                );
              })}
              {Array.from(Array(21).keys()).map(x => (
                <>
                  {Array.from(Array(21).keys()).map(y => (
                    <Rectangle
                      key={`${x}--${y}`}
                      x={(x + 44) * 10}
                      y={(y + 42) * 10}
                      width={10}
                      height={10}
                      color="#C3C3C3"
                    />
                  ))}
                </>
              ))}
              {LandRoadData.map(item => {
                const itemArray = item.split(', ');
                const x = Number(itemArray[0]);
                const y = Number(itemArray[1]);
                return (
                  <Rectangle
                    key={`${x}--${y}`}
                    x={x + 54 + 9 * (x + 54)}
                    y={-y + 54 + 9 * (-y + 54)}
                    width={10}
                    height={10}
                    color="#C3C3C3"
                  />
                );
              })}
              {state && (
                <StrokedRectangle
                  x={state.xLand + 54 + 9 * (state.xLand + 54)}
                  y={state.yLand + 54 + 9 * (state.yLand + 54)}
                  width={10}
                  height={10}
                  color="#000000"
                />
              )}
            </>
          )}
        </CustomViewport>
      </Stage>
      <Zoom />
      {state && <Tooltip state={state} onClose={() => setState(undefined)} />}
    </Div>
  );
};
export default LandCanvas;
