import store from 'store';
import Utils from 'libs/Utils';
import { IBidToken } from 'models/NFTDetails';

type IStatistic = {
  sales: { bidToken: IBidToken; total: number; volume: string }[];
  totalSales: number;
};

export interface IStatistics {
  dailyListing: IStatistic;
  dailySales: IStatistic;
  monthlyListing: IStatistic;
  monthlySales: IStatistic;
  weeklyListing: IStatistic;
  weeklySales: IStatistic;
}

export class Statistics {
  dailyListing: IStatistic;
  dailySales: IStatistic;
  monthlyListing: IStatistic;
  monthlySales: IStatistic;
  weeklyListing: IStatistic;
  weeklySales: IStatistic;
  constructor(props: IStatistics) {
    this.dailyListing = props.dailyListing || '';
    this.dailySales = props.dailySales || '';
    this.monthlyListing = props.monthlyListing || '';
    this.monthlySales = props.monthlySales || '';
    this.weeklyListing = props.weeklyListing || '';
    this.weeklySales = props.weeklySales || '';
  }

  get dailyVolume(): string {
    const { sales } = this.dailySales;
    const { bidTokenPrice } = store.getState().Constants;
    let volume = 0;
    sales.forEach(sale => {
      const saleVolume = Utils.removeRC20Decimal(+sale.volume);
      volume += saleVolume * bidTokenPrice;
    });
    return volume.toFixed(2);
  }

  get monthlyVolume(): string {
    const { sales } = this.monthlySales;
    const { bidTokenPrice } = store.getState().Constants;
    let volume = 0;
    sales.forEach(sale => {
      const saleVolume = Utils.removeRC20Decimal(+sale.volume);
      volume += saleVolume * bidTokenPrice;
    });
    return volume.toFixed(2);
  }

  get weeklyVolume(): string {
    const { sales } = this.weeklySales;
    const { bidTokenPrice } = store.getState().Constants;
    let volume = 0;
    sales.forEach(sale => {
      const saleVolume = Utils.removeRC20Decimal(+sale.volume);
      volume += saleVolume * bidTokenPrice;
    });
    return volume.toFixed(2);
  }
}
