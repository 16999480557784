import * as React from 'react';
import {
  CardContent,
  CardContentProps,
  CardMedia,
  CardMediaProps,
  CardProps,
  TypographyProps,
} from '@mui/material';
import { CardBody, CardDescription, IAsset } from 'models/@types';
import CMPCardContent from 'components/global/card-content';
import CMPCard from './_styles';

interface IProps extends CardProps {
  asset: IAsset;
  title: string;
  rank?: number;
  body?: CardBody;
  description?: CardDescription;
  contentProps?: CardContentProps;
  mediaProps?: CardMediaProps<'img'>;
  titleProps?: TypographyProps<'div'>;
  action?: React.ReactNode;
}

const Card: React.FunctionComponent<IProps> = ({
  asset,
  title,
  body,
  description,
  contentProps,
  mediaProps,
  titleProps,
  rank,
  action,
  ...props
}) => {
  return (
    <CMPCard {...props}>
      <CardMedia component="img" height="300" image={asset.src} alt={asset.alt} {...mediaProps} />
      <CardContent style={{ height: 'calc(100% - 300px)', padding: '12px' }} {...contentProps}>
        <CMPCardContent
          body={body}
          description={description}
          title={title}
          titleProps={titleProps}
          rank={rank}
          action={action}
        />
      </CardContent>
    </CMPCard>
  );
};

export default Card;
