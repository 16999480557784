import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import Description from 'components/global/description';
import Div from 'components/global/div';
import GDivider from 'components/global/divider';
import ProgressBar from 'components/global/progress-bar';
import AuctionTimerIcon from 'components/icons/main/AuctionTimer';

interface IProps {
  description: {
    icon: React.ReactNode;
    value: string;
    price: string;
  };
  progressBar?: {
    highest: string;
    lowest: string;
    progressPercentage: number;
  };
}

const InformationDescription: React.FunctionComponent<IProps> = ({ description, progressBar }) => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'), {
    noSsr: true,
  });
  return (
    <Div
      className={`d-flex ${
        matches ? 'flex-column align-items-start justify-content-start' : 'align-items-center'
      }`}
      sx={{ mt: matches ? 2 : 4, width: '100%', maxWidth: 444 }}
    >
      <Description
        icon={description.icon}
        value={description.value}
        price={description.price}
        valueProps={{ variant: 'h5', fontSize: '30px', sx: { mx: 1 } }}
        priceProps={{ sx: { pt: 1 } }}
        hasPrice
      />
      {progressBar ? (
        <>
          <GDivider sx={{ mx: 4 }} variant="fullWidth" />
          <ProgressBar
            highest={progressBar.highest}
            lowest={progressBar.lowest}
            durationIcon={<AuctionTimerIcon />}
            barProps={{
              sx: { height: 7, width: matches ? '160px' : '220px' },
              value: progressBar.progressPercentage,
            }}
          />
        </>
      ) : null}
    </Div>
  );
};

export default InformationDescription;
