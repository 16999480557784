import React from 'react';

const ListingIcon: React.FunctionComponent = () => {
  return (
    <svg
      id="receipt-add"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <path id="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0" />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M12,8v4.84A4.81,4.81,0,0,1,6.84,18H0V4.02A4.037,4.037,0,0,1,4.04,0,7.989,7.989,0,0,1,12,8Z"
        transform="translate(32 4)"
        fill="#e8ad37"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,10V38a1.994,1.994,0,0,0,3.2,1.6l3.42-2.56a2.015,2.015,0,0,1,2.64.2l3.32,3.34a2.016,2.016,0,0,0,2.84,0l3.36-3.36a1.983,1.983,0,0,1,2.6-.18L24.8,39.6A2,2,0,0,0,28,38V4a4.012,4.012,0,0,1,4-4H8C2,0,0,3.58,0,8Z"
        transform="translate(4 4)"
        fill="#e8ad37"
        opacity="0.4"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M12.5,5.5h-4v-4a1.5,1.5,0,0,0-3,0v4h-4a1.5,1.5,0,0,0,0,3h4v4a1.5,1.5,0,0,0,3,0v-4h4a1.5,1.5,0,0,0,0-3Z"
        transform="translate(11 13)"
        fill="#e8ad37"
      />
    </svg>
  );
};
export default ListingIcon;
