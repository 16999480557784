import React, { useState } from 'react';
import store from 'store';
import { setAppLoading } from 'store/app/actions';
import Strings from 'utils/Strings';
import { makeERC721Offer } from 'services/BuyNFT';
import { NFTType } from 'models/@types';
import LabeledInput from 'components/common/labeled-input';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import { showSnackbar } from 'components/global/snackbar';

interface IProps {
  open: boolean;
  onClose: () => void;
  nftType: NFTType | null;
  tokenId: number;
  address: string;
}

interface IState {
  duration: string;
  minPrice: string;
}

const OfferNFT: React.FunctionComponent<IProps> = ({
  open,
  onClose,
  nftType,
  tokenId,
  address,
}) => {
  const [state, setState] = useState<IState>({ duration: '', minPrice: '' });

  const onOffer = async () => {
    if (nftType === 'ERC721') {
      const success = await makeERC721Offer({
        tokenId,
        address,
        duration: Number(state.duration) * 24 * 3600,
        minPrice: Number(state.minPrice),
      });
      if (success) {
        const timout = setTimeout(() => {
          showSnackbar({
            message: Strings.successOffer,
            severity: 'success',
          });
          store.dispatch(setAppLoading(false));
          onClose();
          clearTimeout(timout);
        }, 5000);
      }
    }
  };

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title={Strings.offer}
      contentProps={{ className: 'small-scrollbar pt-4' }}
    >
      <LabeledInput
        value={state.minPrice}
        onChange={value => setState(prevState => ({ ...prevState, minPrice: value }))}
        type="number"
        label="Price"
      />
      <LabeledInput
        value={state.duration}
        onChange={value => setState(prevState => ({ ...prevState, duration: value }))}
        beforeInputHelperElement="Days"
        type="number"
        label="Duration"
      />
      <CMPButton
        disabled={state.minPrice === '' || state.duration === ''}
        sx={{ height: 56, width: '100%', mt: 3, fontSize: '18px' }}
        onClick={onOffer}
      >
        {Strings.confirmOffer}
      </CMPButton>
    </DialogModal>
  );
};
export default OfferNFT;
