import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ApplicationRoutes from 'routes';
import { userIsLoggedIn } from 'services/User';

const RouterProvider: React.FunctionComponent = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <Routes>
      {ApplicationRoutes.map(({ element: Element, ...item }) => (
        <React.Fragment key={item.path}>
          {item.isPrivate && !userIsLoggedIn() ? (
            <Route path="*" element={<Navigate to="/character" />} />
          ) : (
            <Route element={<Element />} {...item} />
          )}
        </React.Fragment>
      ))}
      <Route path="*" element={<Navigate to="/character" />} />
    </Routes>
  );
};
export default RouterProvider;
