import React from 'react';

const HomeIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="home" transform="translate(-684 -700)">
        <g id="home-2" data-name="home" transform="translate(684 700)">
          <path
            id="Vector"
            d="M0,0H36V36H0Z"
            transform="translate(36 36) rotate(180)"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M12.11.954,1.715,9.279A5.06,5.06,0,0,0,.05,13.644l2,11.94A4.785,4.785,0,0,0,6.6,29.439H23.4a4.813,4.813,0,0,0,4.56-3.855l2-11.94a5.144,5.144,0,0,0-1.665-4.365L17.9.969A4.838,4.838,0,0,0,12.11.954Z"
            transform="translate(2.995 3.276)"
            fill="#e9e9e9"
            opacity="0.4"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M7.5,3.75A3.75,3.75,0,1,1,3.75,0,3.75,3.75,0,0,1,7.5,3.75Z"
            transform="translate(14.25 15.75)"
            fill="#e9e9e9"
          />
        </g>
      </g>
    </svg>
  );
};
export default HomeIcon;
