import React, { useState } from 'react';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Divider, Theme, Typography, useMediaQuery } from '@mui/material';
import { RootState } from 'store';
import { clearFilters, setQueries } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import { createFilterQuery } from 'utils/ObjectsUtils';
import Strings from 'utils/Strings';
import { getAttributesService } from 'services/MarketPlace';
import { CategoryAttributes, NFTCategory } from 'models/@types';
import Categories from 'components/common/categories';
import CMPButton from 'components/global/button';
import Div from 'components/global/div';
import CMPDrawer from 'components/global/drawer';
import FilterIcon from 'components/icons/main/FilterIcon';
import Sort from '../sort';
import Filters from './components';

interface IFiltersSectionProps {
  mobileView?: boolean;
  showCategories?: boolean;
  buttonSize?: 'medium' | 'small';
}

const FiltersBody: React.FunctionComponent = () => {
  const filters = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);
  const { category } = filters;
  const dispatch = useDispatch();

  const { data: attributes } = useQuery<CategoryAttributes[] | undefined>(
    ['attributes'],
    () => getAttributesService(),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );

  const showClearFilter =
    !filters.forSale ||
    filters.races.length > 0 ||
    filters.genders.length > 0 ||
    filters.attributes.length > 0 ||
    filters.types.length > 0 ||
    filters.rarities.length > 0 ||
    filters.structureTypes.length > 0 ||
    filters.resourceTypes.length > 0 ||
    filters.regions.length > 0 ||
    filters.characterId !== '';

  const onClear = () => {
    dispatch(clearFilters());
  };

  const handleApplyFilter = () => {
    const queryParams = createFilterQuery(filters);
    dispatch(setQueries(queryParams));
  };

  return (
    <>
      <div className="d-flex align-items-center px-1" style={{ height: 60 }}>
        <FilterIcon />
        <Typography className="ms-2" fontWeight="bold">
          {Strings.filter}
        </Typography>
        {showClearFilter && (
          <CMPButton variant="text" className="ms-3" color="primary" onClick={onClear}>
            {Strings.clear}
          </CMPButton>
        )}
      </div>
      {category !== 'resource' && <Filters.ForSale />}
      {filters.forSale && <Filters.SaleTypeFilter />}
      {category === 'character' ? (
        <>
          <Filters.CharacterID />
          <Filters.Races />
          <Filters.CharacterAttributes
            attrs={attributes?.find(item => item.category === 'character')?.attributes || []}
          />
          {/* <Filters.Gender /> */}
        </>
      ) : category === 'companion' ? (
        <>
          <Filters.PetID />
          <Filters.PetAttributes
            attrs={attributes?.find(item => item.category === 'pet')?.attributes || []}
          />
        </>
      ) : category === 'land' ? (
        <>
          <Filters.CharacterID name={Strings.landId} />
          <Filters.Regions />
        </>
      ) : category === 'item' ? (
        <>
          <Filters.ItemTypes />
          <Filters.Rarity />
        </>
      ) : category === 'resource' ? (
        <>
          <Filters.ResourcesTypes />
          <Filters.Rarity />
        </>
      ) : category === 'structure' ? (
        <>
          <Filters.StructuresTypes />
        </>
      ) : (
        <>{null}</>
      )}
      <CMPButton
        sx={{
          width: '100%',
          mt: 2,
          height: 48,
        }}
        onClick={handleApplyFilter}
      >
        {Strings.applyFilters}
      </CMPButton>
    </>
  );
};

const FiltersSection: React.FunctionComponent<IFiltersSectionProps> = ({
  mobileView,
  showCategories = true,
  buttonSize = 'small',
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { category } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const onChangeCategory = (cat: NFTCategory) => {
    navigate(`/${cat}`);
  };

  if (matches && !mobileView) {
    return <FiltersBody />;
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-2">
        <CMPButton
          variant="text"
          color="inherit"
          startIcon={<FilterIcon />}
          style={{ borderRadius: buttonSize === 'small' ? '18px' : '24px' }}
          sx={{
            width: 100,
            height: buttonSize === 'small' ? 52 : 68,
            borderColor: 'divider',
            backgroundColor: 'filterBg',
            fontWeight: 'bold',
            border: theme =>
              theme.palette.mode === 'light' ? `1px solid ${theme.palette.divider}` : undefined,
          }}
          onClick={() => setOpen(true)}
        >
          {Strings.filter}
        </CMPButton>
        {showCategories && <Categories activeItem={category} onChangeCategory={onChangeCategory} />}
      </div>
      <CMPDrawer open={open} onOpenChange={setOpen} onClose={() => setOpen(false)}>
        <Div sx={{ width: '82vw', maxWidth: '360px', padding: '16px' }}>
          <div className={clsx('d-flex align-items-center', { 'mt-2': !matches })}>
            <Sort
              minWidth="calc(82vw - 32px)"
              maxWidth="calc(360px - 32px)"
              withPaddingEnd={false}
            />
          </div>
          <Divider className="mt-3" />
          <FiltersBody />
        </Div>
      </CMPDrawer>
    </>
  );
};
export default FiltersSection;
