import { Card, styled } from '@mui/material';

export const classes = {
  imgContainer: 'img-container',
  iconButton: 'icon-button',
};

const CMPClassicCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  borderRadius: '24px',
  padding: '16px',
  width: '100%',
  background: theme.palette.filterBg,
  borderColor: theme.palette.divider,
  // maxWidth: 600,
  height: 184,
  transitionDuration: '150ms',
  cursor: 'pointer',
  ['&:hover']: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  },
  [`& .${classes.imgContainer}`]: {
    margin: 'auto 0',
    borderRadius: '18px',
    width: '142px',
    height: '142px',
    overflow: 'hidden',
    background:
      theme.palette.mode === 'light'
        ? 'linear-gradient(to bottom, #EEE7F6 , #B4A5C8)'
        : 'linear-gradient(to bottom, #292037 , #534B5F)',
  },
  [`& .${classes.iconButton}`]: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '12px',
    width: 40,
    height: 40,
    mr: '-8px',
  },
}));

export default CMPClassicCard;
