import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import store from 'store';
import { setHRC20Decimal } from 'store/constants/actions';
import { ContractAddress } from 'constants/ContractAddress';
import { CreateHRC20Contract, CreateMarketContract } from 'services/CreateContract';

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export const getHRC20Decimals = async (): Promise<number> => {
  const hrc20Contract = await CreateHRC20Contract();
  if (!hrc20Contract) {
    return 0;
  }
  const decimal = await hrc20Contract.decimals();
  store.dispatch(setHRC20Decimal(new BigNumber(decimal)));
  return parseInt(decimal);
};

export const getAllowance = async (): Promise<BigNumber | null> => {
  const { MARKET } = ContractAddress;
  const { user } = store.getState().User;
  const hrc20Contract = await CreateHRC20Contract();
  if (!hrc20Contract || !user) {
    return null;
  }
  const { wallet } = user;
  return hrc20Contract.allowance(wallet, MARKET);
};

export const approveToken = async (price: string): Promise<boolean> => {
  const hrc20Contract = await CreateHRC20Contract();
  const { MARKET } = ContractAddress;
  if (!hrc20Contract) {
    return false;
  }
  const approved = await hrc20Contract.approve(MARKET, price);
  await approved.wait();
  return approved as boolean;
};

export const disableRoyalty = async (contract: string, tokenId: number): Promise<boolean> => {
  const marketContract = CreateMarketContract();
  if (!marketContract) {
    return false;
  }
  try {
    const loyalties = await marketContract.royalties(contract, tokenId);
    return loyalties[0] !== ethers.constants.AddressZero;
  } catch (err) {
    return false;
  }
};
