import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { RootState } from 'store';
import theme from 'theme';
import { AppTheme } from 'models/@types';

const ThemeProvider: React.FunctionComponent = ({ children }) => {
  const appTheme = useSelector<RootState, AppTheme>(state => state.App.appTheme);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);
  return (
    <MuiThemeProvider theme={theme({ appTheme })}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
export default ThemeProvider;
