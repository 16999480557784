import React from 'react';

const VolumeIcon: React.FunctionComponent = () => {
  return (
    <svg id="coin" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
      <path
        id="Vector"
        d="M37.287,18.643A18.643,18.643,0,1,1,18.643,0,18.643,18.643,0,0,1,37.287,18.643Z"
        transform="translate(4.667 4.667)"
        fill="#0bb4e7"
        opacity="0.4"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M25.41,11.457A13.95,13.95,0,0,1,0,19.413,22.069,22.069,0,0,0,19.413,0,13.928,13.928,0,0,1,25.41,11.457Z"
        transform="translate(25.853 25.853)"
        fill="#0bb4e7"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M9.8,9.45,4.2,7.49c-.56-.187-.677-.233-.677-1.05a1.028,1.028,0,0,1,.957-1.1h3.5A1.441,1.441,0,0,1,9.333,6.86a1.75,1.75,0,1,0,3.5,0A4.955,4.955,0,0,0,8.167,1.867V1.75a1.75,1.75,0,0,0-3.5,0v.117h-.21A4.533,4.533,0,0,0,0,6.463,4.146,4.146,0,0,0,3.01,10.8l5.623,1.96c.56.187.677.233.677,1.05a1.028,1.028,0,0,1-.957,1.1h-3.5A1.441,1.441,0,0,1,3.5,13.393a1.763,1.763,0,0,0-1.75-1.75A1.763,1.763,0,0,0,0,13.393a4.955,4.955,0,0,0,4.667,4.993v.14a1.75,1.75,0,0,0,3.5,0V18.41h.21a4.533,4.533,0,0,0,4.457-4.6A4.209,4.209,0,0,0,9.8,9.45Z"
        transform="translate(16.917 13.207)"
        fill="#0bb4e7"
      />
      <path id="Vector-4" data-name="Vector" d="M0,0H56V56H0Z" fill="none" opacity="0" />
    </svg>
  );
};
export default VolumeIcon;
