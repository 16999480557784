import { ComponentType } from 'react';
import { CustomIconProps } from 'components/icons';
import AboutIcon from 'components/icons/side-bar/AboutIcon';
import HomeIcon from 'components/icons/side-bar/HomeIcon';
import MapIcon from 'components/icons/side-bar/MapIcon';
import MarketIcon from 'components/icons/side-bar/MarketIcon';
// import DexIcon from 'components/icons/side-bar/DexIcon';

interface ISideBarItem {
  icon: ComponentType<CustomIconProps>;
  text: string;
  path: string;
  type: 'INTERNAL' | 'EXTERNAL';
}

const sidebarItems: ISideBarItem[] = [
  {
    path: 'https://cosmicuniverse.one/',
    icon: HomeIcon,
    text: 'Home',
    type: 'EXTERNAL',
  },
  {
    path: '/',
    icon: MarketIcon,
    text: 'Market',
    type: 'INTERNAL',
  },
  // {
  //   path: '/dex',
  //   icon: DexIcon,
  //   text: 'DEX',
  //   type: 'INTERNAL',
  // },
  {
    path: 'https://cosmicuniverse.one/map',
    icon: MapIcon,
    text: 'Map',
    type: 'EXTERNAL',
  },
  {
    path: 'https://info.cosmicuniverse.one/',
    icon: AboutIcon,
    text: 'About',
    type: 'EXTERNAL',
  },
];
export default sidebarItems;
