import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSort } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import { SortType } from 'models/@types';
import CMPDropdown from 'components/common/dropdown';

interface ISortItem {
  key: SortType;
  text: string;
}

const items: ISortItem[] = [
  { key: '+sale.price', text: 'Lowest Price' },
  { key: '-sale.price', text: 'Highest Price' },
  { key: '+tokenId', text: 'Lowest ID' },
  { key: '-tokenId', text: 'Highest ID' },
  { key: '+rarity', text: 'Lowest Rank' },
  { key: '-rarity', text: 'Highest Rank' },
  { key: '-createdAt', text: 'Latest' },
];

interface IProps {
  minWidth?: string | number;
  maxWidth?: string | number;
  withPaddingEnd?: boolean;
}

const Sort: React.FunctionComponent<IProps> = ({ minWidth, maxWidth, withPaddingEnd = true }) => {
  const dispatch = useDispatch();
  const { sort } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);

  const onChangeSort = (sortItem: SortType) => {
    dispatch(setSort(sortItem));
  };

  return (
    <CMPDropdown<SortType>
      items={items}
      selected={items.find(item => item.key === sort)}
      onSelect={item => onChangeSort(item.key)}
      minWidth={minWidth}
      maxWidth={maxWidth}
      className={withPaddingEnd ? 'me-3' : undefined}
    />
  );
};

export default Sort;
