import store from 'store';
import { MarketplaceAPI } from 'apis/marketplace';
import { ISingleNFTReq } from 'apis/marketplace/_';
import { setCategories } from 'store/app/actions';
import { CategoryAttributes } from 'models/@types';
import { Category, ICategory } from 'models/Category';
import { IMapLand, MapLand } from 'models/MapLand';
import { NFT, NFTInterface } from 'models/NFT';
import { INFTDetails, NFTDetails } from 'models/NFTDetails';
import { PAGINATION_SIZE } from 'models/request/Pagination';
import { IQuery } from 'models/request/Query';

export interface IGetNFTRes {
  list: NFT[];
  pageCount: number;
}

interface NFTsServiceArgs {
  user?: string;
  customQuery?: IQuery[];
}

export const getNFTsService = async ({
  user,
  customQuery,
}: NFTsServiceArgs): Promise<IGetNFTRes> => {
  try {
    const { page, queries, sort, forSale, category, saleTypes } = store.getState().MarketPlace;
    const queryParams: IQuery[] = [{ key: 'sort', value: sort }, ...queries];
    queryParams.push({
      key: 'category',
      value: category,
    });
    if (user) {
      queryParams.push({
        key: 'owner',
        value: `string(${user})`,
      });
    }
    if (forSale) {
      queryParams.push({
        key: 'sale',
        value: '',
      });
      queryParams.push({
        key: 'sale.type',
        value: saleTypes.join(','),
      });
    }
    if (customQuery) {
      queryParams.concat(customQuery);
    }
    const response = await MarketplaceAPI.getNFTs(
      {
        pageNumber: page,
        query: queryParams,
      },
      !!user,
    );
    if (response.success && response.data) {
      let pageCount = 0;
      if (response.metadata) {
        const { totalRecords } = response.metadata.pagination;
        pageCount = Number(Math.ceil(totalRecords / PAGINATION_SIZE));
      }
      const serverList = response.data as NFTInterface[];
      return {
        pageCount,
        list: serverList.map(item => new NFT(item)),
      };
    }
    return {
      list: [],
      pageCount: 0,
    };
  } catch (e) {
    console.log(e);
    return {
      list: [],
      pageCount: 0,
    };
  }
};

export const getSingleNFTService = async (data: ISingleNFTReq): Promise<NFTDetails | null> => {
  const response = await MarketplaceAPI.getSingleNFT(data);
  if (response.success) {
    return new NFTDetails(response.data as INFTDetails);
  }
  return null;
};

export const getCategoriesService = async (): Promise<void> => {
  const response = await MarketplaceAPI.getCategories();
  if (response.success) {
    const list = response.data as ICategory[];
    const categories = list.map(item => new Category(item));
    store.dispatch(setCategories(categories));
  }
};

export const getAttributesService = async (): Promise<CategoryAttributes[] | undefined> => {
  const response = await MarketplaceAPI.getAttributes();
  if (response.success) {
    return response.data;
  }
  return;
};

export const getAllLands = async (): Promise<NFT[]> => {
  try {
    const response = await MarketplaceAPI.getNFTs({
      pageSize: 12000,
      pageNumber: 1,
      query: [{ key: 'category', value: 'land' }],
    });
    if (response.success) {
      const list = response.data as NFTInterface[];
      return list.map(item => new NFT(item));
    }
    return [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getMapData = async (): Promise<MapLand[]> => {
  try {
    const response = await MarketplaceAPI.getMapData();
    if (response.success) {
      const list = response.data as IMapLand[];
      return list.map(item => new MapLand(item));
    }
    return [];
  } catch (e) {
    console.log(e);
    return [];
  }
};
