import { AppTheme, ISnackbar } from 'models/@types';
import { Category } from 'models/Category';
import * as Types from './types';

export function setAppLoading(newState: boolean): Types.AppActionTypes {
  return {
    type: Types.SET_APP_LOADING,
    payload: newState,
  };
}

export function setAppTheme(newState: AppTheme): Types.AppActionTypes {
  return {
    type: Types.SET_APP_THEME,
    payload: newState,
  };
}

export function setSnackbar(newState: ISnackbar | null): Types.AppActionTypes {
  return {
    type: Types.SET_SNACKBAR,
    payload: newState,
  };
}

export function setCategories(newState: Category[]): Types.AppActionTypes {
  return {
    type: Types.SET_CATEGORIES,
    payload: newState,
  };
}
