import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setResourceTypes } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import Strings from 'utils/Strings';
import { resourcesTypes } from 'constants/filters/Resources';
import FilterContainer from 'components/common/filter-container';
import CMPSideMenu from 'components/common/side-menu';
import Div from 'components/global/div';

const ResourcesAttributes: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { resourceTypes } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);
  let selectedCount = 0;
  resourceTypes.map(item => {
    selectedCount += item.checkedItems.length;
  });

  const onSelectItem = (attr: string, selectedItem: string) => {
    const currentAttr = resourceTypes.find(item => item.text === attr);
    if (currentAttr) {
      const currentAttrItems = currentAttr.checkedItems;
      const index = currentAttrItems.indexOf(selectedItem);
      if (index > -1) {
        currentAttrItems.splice(index, 1);
      } else {
        currentAttrItems.push(selectedItem);
      }
      const currentAttrIndex = resourceTypes.findIndex(item => item.text === attr);
      resourceTypes.splice(currentAttrIndex, 1);
      dispatch(
        setResourceTypes([...resourceTypes, { text: attr, checkedItems: currentAttrItems }]),
      );
      return;
    }
    dispatch(setResourceTypes([...resourceTypes, { text: attr, checkedItems: [selectedItem] }]));
  };

  return (
    <FilterContainer
      className="mt-3"
      count={selectedCount > 0 ? selectedCount : undefined}
      title={Strings.types}
      body={
        <Div
          className="d-flex flex-wrap align-items-center justify-content-center mb-3"
          sx={{ position: 'relative' }}
        >
          {Object.entries(resourcesTypes).map(([key, value]) => {
            const checked = resourceTypes.find(attr => attr.text === key);
            return (
              <CMPSideMenu
                key={key}
                title={key}
                items={value}
                onCheckItem={selectedItem => onSelectItem(key, selectedItem)}
                checkedItems={checked?.checkedItems || []}
              />
            );
          })}
        </Div>
      }
    />
  );
};
export default ResourcesAttributes;
