import React from 'react';
import { Divider, Typography } from '@mui/material';
import useTitle from 'hooks/useTitle';
import Strings from 'utils/Strings';
import MetamaskLogin from 'components/common/metamask-login';
import AuthContainer from 'components/layouts/auth-layout/_styles';
import LoginForm from './components/form';
import Links from './components/Links';

const LoginPage: React.FunctionComponent = () => {
  useTitle({ title: 'Login' });
  return (
    <AuthContainer>
      <MetamaskLogin />
      <div className="d-flex align-items-center justify-content-between w-100 my-3">
        <Divider sx={{ width: 'calc(50% - 16px)' }} orientation="horizontal" />
        <Typography component="span" color="textSecondary">
          OR
        </Typography>
        <Divider sx={{ width: 'calc(50% - 16px)' }} orientation="horizontal" />
      </div>
      <LoginForm />
      <Typography fontWeight="bold" align="center" className="my-4">
        {Strings.acceptTerms}
      </Typography>
      <Links />
    </AuthContainer>
  );
};
export default LoginPage;
