import * as Types from './types';

export const initialState: Types.IMarketPlaceState = {
  forSale: true,
  saleTypes: ['fixed-price', 'auction'],
  races: [],
  view: 'grid',
  category: 'character',
  genders: [],
  attributes: [],
  resourceTypes: [],
  regions: [],
  rarities: [],
  types: [],
  structureTypes: [],
  page: 1,
  queries: [],
  sort: '+sale.price',
  characterId: '',
};

export default function marketplaceReducer(
  state = initialState,
  action: Types.MarketplaceActionTypes,
): Types.IMarketPlaceState {
  switch (action.type) {
    case Types.CLEAR_FILTERS:
      return action.payload;
    case Types.SET_RACES:
      return { ...state, races: action.payload };
    case Types.SET_SALE_TYPES:
      return { ...state, saleTypes: action.payload };
    case Types.SET_ATTRIBUTES:
      return { ...state, attributes: action.payload };
    case Types.SET_RESOURCE_TYPES:
      return { ...state, resourceTypes: action.payload };
    case Types.SET_FOR_SALE:
      return {
        ...state,
        forSale: action.payload,
        saleTypes: action.payload ? ['fixed-price', 'auction'] : [],
        page: 1,
      };
    case Types.SET_VIEW:
      return { ...state, view: action.payload };
    case Types.SET_STRUCTURE_TYPES:
      return { ...state, structureTypes: action.payload };
    case Types.SET_QUERIES:
      return { ...state, queries: action.payload, page: 1 };
    case Types.SET_PAGE:
      return { ...state, page: action.payload };
    case Types.SET_SORT:
      return { ...state, sort: action.payload, page: 1 };
    case Types.SET_CHARACTER_ID:
      return { ...state, characterId: action.payload, page: 1 };
    case Types.SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
        types: [],
        regions: [],
        attributes: [],
        rarities: [],
        races: [],
        genders: [],
        // forSale: false,
        page: 1,
        resourceTypes: [],
        structureTypes: [],
        queries: [],
      };
    case Types.SET_GENDERS:
      return { ...state, genders: action.payload };
    case Types.SET_REGIONS:
      return { ...state, regions: action.payload };
    case Types.SET_RARITIES:
      return { ...state, rarities: action.payload };
    case Types.SET_TYPES:
      return { ...state, types: action.payload };
    default:
      return state;
  }
}
