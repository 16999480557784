import React from 'react';

const MapIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="map" transform="translate(-684 -252)">
        <g id="map-2" data-name="map" transform="translate(684 252)">
          <path
            id="Vector"
            d="M9.405,0V21.495a2.958,2.958,0,0,0-1.4.36l-3.525,2.01C2.025,25.275,0,24.1,0,21.255V6.66a3.315,3.315,0,0,1,1.515-2.6L8.01.345A3.14,3.14,0,0,1,9.405,0Z"
            transform="translate(3.435 5.01)"
            fill="#e9e9e9"
            opacity="0.4"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M10.755,4.487v21.5a3.169,3.169,0,0,1-1.44-.27L1.44,21.767A3.169,3.169,0,0,0,0,21.5V0a3.169,3.169,0,0,1,1.44.27L9.315,4.217A3.169,3.169,0,0,0,10.755,4.487Z"
            transform="translate(12.84 5.008)"
            fill="#e9e9e9"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M9.405,3.208V17.8A3.315,3.315,0,0,1,7.89,20.4L1.4,24.118a3.14,3.14,0,0,1-1.4.345V2.968a2.958,2.958,0,0,0,1.4-.36L4.92.6C7.38-.812,9.405.358,9.405,3.208Z"
            transform="translate(23.595 6.527)"
            fill="#e9e9e9"
            opacity="0.4"
          />
          <path id="Vector-4" data-name="Vector" d="M0,0H36V36H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};
export default MapIcon;
