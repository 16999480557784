import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { setAppLoading } from 'store/app/actions';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import { verifyEmailService } from 'services/User';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import CMPInput from 'components/global/input';
import VerificationIcon from 'components/icons/login/VerificationIcon';

interface IProps {
  open: boolean;
  onClose: () => void;
  email: string;
  onVerified?: (event: any) => Promise<void>;
}

const EmailVerification: React.FunctionComponent<IProps> = ({
  open,
  onClose,
  email,
  onVerified,
}) => {
  const [verifyCode, setVerifyCode] = useState('');
  const dispatch = useDispatch();

  const handleEmailVerification = async (event: any) => {
    if (verifyCode.length > 0 && Utils.emailValidation(email)) {
      dispatch(setAppLoading(true));
      const response = await verifyEmailService(email, verifyCode);
      dispatch(setAppLoading(false));
      if (response && onVerified) {
        if (onClose) {
          onClose();
        }
        await onVerified(event);
      }
    }
  };

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="xs"
      contentProps={{ className: 'd-flex flex-column align-items-center small-scrollbar' }}
    >
      <VerificationIcon />
      <Typography variant="body1" fontWeight={700} className="mt-3">
        {Strings.verify}
      </Typography>
      <Typography
        fontWeight={600}
        textAlign="center"
        className="mt-4"
        sx={{ maxWidth: 450, lineHeight: '24px' }}
      >
        {Strings.verifyDesc(email)}
      </Typography>
      <CMPInput
        placeholder={Strings.vCode}
        style={{ maxWidth: 450 }}
        value={verifyCode}
        onChanged={setVerifyCode}
        className="mt-4"
        type="number"
        filled
        fullWidth
        defaultVariant
      />
      <CMPButton
        fullWidth
        className="mt-5"
        sx={{ minHeight: 56, maxWidth: 450 }}
        onClick={handleEmailVerification}
        disabled={verifyCode.length === 0}
      >
        {Strings.confirm}
      </CMPButton>
    </DialogModal>
  );
};
export default EmailVerification;
