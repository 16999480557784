import React from 'react';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import Switch, { classes } from './_styles';

interface IProps {
  label?: string;
  checked?: boolean;
  onChange?: () => void;
}

const CMPSwitch: React.FunctionComponent<IProps> = ({ label, checked = false, onChange }) => {
  return (
    <Switch onClick={onChange}>
      {label && <Typography fontWeight="bold">{label}</Typography>}
      <div className={clsx(classes.switch, { [classes.switchActive]: checked })} />
    </Switch>
  );
};
export default CMPSwitch;
