import { createURL, sendRequest } from 'apis/index';
import { INFTListReq, ISingleNFTReq, NFTListReq } from 'apis/marketplace/_';
import Response from 'models/request/Response';

const baseUrl = 'marketplace';

export const MarketplaceAPI = {
  getNFTs: async (req: INFTListReq, user = false): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_NFT_LIST',
      method: 'GET',
      url: createURL({
        baseUrl,
        args: [user ? 'owners' : 'nfts'],
        queries: new NFTListReq(req).queries(),
      }),
    });
  },
  getSingleNFT: async (req: ISingleNFTReq): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_NFT',
      auth: true,
      method: 'GET',
      url: createURL({
        baseUrl,
        args: ['nfts', req.contract, req.tokenId],
      }),
    });
  },
  getCategories: async (): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_CATEGORIES',
      method: 'GET',
      url: createURL({
        baseUrl,
        args: ['nfts', 'category'],
      }),
    });
  },
  getAttributes: async (): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_ATTRIBUTES',
      method: 'GET',
      url: createURL({
        baseUrl,
        args: ['attributes'],
      }),
    });
  },
  getMapData: async (): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_MAP_DATA',
      method: 'GET',
      url: createURL({
        baseUrl,
        args: ['nfts', 'map'],
      }),
    });
  },
};
