import React, { useState } from 'react';
import { Grid } from '@mui/material';
import store from 'store';
import { setAppLoading } from 'store/app/actions';
import Strings from 'utils/Strings';
import { CancelOffer } from 'services/BuyNFT';
import { CancelSale, finalizeNFT } from 'services/SellNFT';
import { NFTDetails } from 'models/NFTDetails';
import CMPButton from 'components/global/button';
import { showSnackbar } from 'components/global/snackbar';
import BuyNFT from '../buy-nft';
import OfferNFT from '../offer-nft';
import SellNFT from '../sell-nft';

interface IActionButtonProps {
  matches?: boolean;
  data: NFTDetails;
  onRefresh?: () => void;
  withTopMargin?: boolean;
  roundedButton?: boolean;
}

const ActionButton: React.FunctionComponent<IActionButtonProps> = ({
  data,
  matches,
  onRefresh,
  withTopMargin = true,
  roundedButton = true,
}) => {
  const [sellOpen, setSellOpen] = useState(false);
  const [buyOpen, setBuyOpen] = useState(false);
  const [offerOpen, setOfferOpen] = useState(false);

  const onCancel = () => {
    CancelSale(data.sale?.saleId || 0).then(res => {
      if (res && onRefresh) {
        const timout = setTimeout(() => {
          showSnackbar({
            message: Strings.saleCancel,
            severity: 'success',
          });
          onRefresh();
          clearTimeout(timout);
          store.dispatch(setAppLoading(false));
        }, 5000);
      }
    });
  };
  const onFinalize = async () => {
    if (data.sale) {
      const response = await finalizeNFT(data.sale.saleId);
      if (response && onRefresh) {
        const timout = setTimeout(() => {
          showSnackbar({
            message: Strings.finalize,
            severity: 'success',
          });
          onRefresh();
          store.dispatch(setAppLoading(false));
          clearTimeout(timout);
        }, 5000);
      }
    }
  };

  const onSubmit = async () => {
    switch (data.actionType) {
      case 'BUY':
        setBuyOpen(true);
        break;
      case 'SALE':
        setSellOpen(true);
        break;
      case 'OFFER':
        setOfferOpen(true);
        break;
      case 'FINALIZE':
        await onFinalize();
        break;
      case 'CANCEL_SALE':
        onCancel();
        break;
      case 'CANCEL_OFFER':
        CancelOffer(data.userOffer?.saleId || 0).then(res => {
          if (res && onRefresh) {
            const timout = setTimeout(() => {
              showSnackbar({
                message: Strings.offerCancel,
                severity: 'success',
              });
              onRefresh();
              clearTimeout(timout);
            }, 5000);
          }
        });
        break;
    }
  };

  const disabled =
    (data.actionType === 'BUY' && data.sale?.status !== 'live') ||
    (data.nftType === 'ERC1155' && data.actionType === 'OFFER') ||
    data.actionType === 'LOGIN_TO_BUY' ||
    data.actionType === 'BIDDER' ||
    data.actionType === 'HAS_BIDDER';

  if (data.actionType === 'FINALIZE_AND_CANCEL') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <CMPButton
            rounded={roundedButton}
            color="primary"
            sx={{
              height: matches ? 58 : 68,
              width: '100%',
              mt: withTopMargin ? 4 : 0,
              fontSize: '18px',
            }}
            onClick={onFinalize}
          >
            {Strings.finalizeButton}
          </CMPButton>
        </Grid>
        <Grid item xs={6}>
          <CMPButton
            rounded={roundedButton}
            color="error"
            sx={{
              height: matches ? 58 : 68,
              width: '100%',
              mt: withTopMargin ? 4 : 0,
              fontSize: '18px',
            }}
            onClick={onCancel}
          >
            {Strings.cancelButton}
          </CMPButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <CMPButton
        disabled={disabled}
        rounded={roundedButton}
        color={
          data.actionType === 'CANCEL_OFFER' || data.actionType === 'CANCEL_SALE'
            ? 'error'
            : 'primary'
        }
        sx={{
          height: matches ? 58 : 68,
          width: matches ? '100%' : 300,
          mt: withTopMargin ? 4 : 0,
          fontSize: '18px',
        }}
        onClick={onSubmit}
      >
        {data.actionText}
      </CMPButton>
      <SellNFT
        open={sellOpen}
        onClose={() => {
          setSellOpen(false);
          if (onRefresh) {
            onRefresh();
          }
        }}
        address={data.contract}
        tokenId={data.tokenId}
      />
      {data.sale && (
        <BuyNFT
          open={buyOpen}
          onClose={() => {
            setBuyOpen(false);
            if (onRefresh) {
              onRefresh();
            }
          }}
          data={data}
        />
      )}
      <OfferNFT
        open={offerOpen}
        onClose={() => {
          setOfferOpen(false);
          if (onRefresh) {
            onRefresh();
          }
        }}
        nftType={data.nftType}
        tokenId={data.tokenId}
        address={data.contract}
      />
    </>
  );
};
export default ActionButton;
