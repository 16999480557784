import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Strings from 'utils/Strings';
import { NFT } from 'models/NFT';
import AuctionBuy from 'pages/nft/components/buy-nft/_Auction';
import FixedPriceBuy from 'pages/nft/components/buy-nft/_Fixed';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';

interface IActionButtonProps {
  nft: NFT;
  onRefresh?: () => void;
}

const ActionButton: React.FunctionComponent<IActionButtonProps> = ({ nft, onRefresh }) => {
  const [buyOpen, setBuyOpen] = useState(false);
  const { sale, numeralPrice } = nft;

  const onClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (nft.actionType === 'BID' || nft.actionType === 'BUY') {
      setBuyOpen(true);
    }
  };

  const onClose = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setBuyOpen(false);
  };

  const onSuccess = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setBuyOpen(false);
    if (onRefresh) {
      onRefresh();
    }
  };
  if (sale && (nft.actionType === 'BID' || nft.actionType === 'BUY')) {
    const { saleId, bidToken, type, buy } = sale;
    return (
      <>
        <Grid container spacing={1} className="mt-3">
          <Grid item xs={6}>
            <CMPButton fullWidth onClick={onClick}>
              {nft.actionType}
            </CMPButton>
          </Grid>
          <Grid item xs={6}>
            <CMPButton fullWidth variant="outlined">
              {Strings.details}
            </CMPButton>
          </Grid>
        </Grid>
        <DialogModal
          open={buyOpen}
          onClose={onClose}
          title={type === 'fixed-price' ? 'Fixed Price Method' : 'Auction Method'}
          contentProps={{ className: 'small-scrollbar' }}
          maxWidth={type === 'fixed-price' ? 'xs' : 'sm'}
        >
          {type === 'fixed-price' ? (
            <FixedPriceBuy
              saleId={saleId}
              currentValue={numeralPrice}
              onSuccess={onSuccess}
              bidToken={bidToken.symbol}
            />
          ) : (
            <AuctionBuy
              currentValue={numeralPrice}
              saleId={saleId}
              onSuccess={onSuccess}
              notAddTenPercent={!buy?.wallet}
            />
          )}
        </DialogModal>
      </>
    );
  }

  return (
    <CMPButton fullWidth variant="outlined" className="mt-4">
      {Strings.details}
    </CMPButton>
  );
};
export default ActionButton;
