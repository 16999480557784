import React from 'react';
import CMPImage from 'components/global/image';

const LogoIcon: React.FunctionComponent = () => {
  return (
    <CMPImage
      asset={{
        src: `${process.env.PUBLIC_URL}/images/cosmic.png`,
        alt: 'Cosmic Market Place',
      }}
      width={20}
      height={20}
    />
  );
};
export default LogoIcon;
