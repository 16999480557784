import BigNumber from 'bignumber.js';
import store from 'store';
import { setAppLoading } from 'store/app/actions';
import Utils from 'libs/Utils';
import { ContractAddress } from 'constants/ContractAddress';
import { CreateHRC721Contract, CreateMarketContract } from 'services/CreateContract';
import { showSnackbar } from 'components/global/snackbar';

export type SellModelInputType = {
  minPrice: string;
  startTime: Date | null;
  duration: string;
  royaltyBps: string;
};

interface ERC721SellArgs {
  address: string;
  minPrice: number;
  duration: number;
  royaltyBps: number;
  tokenId: number;
  type: 'fixed-price' | 'auction';
}

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export const ERC721Approved = async (tokenId: number, address: string): Promise<boolean> => {
  const HRC721Contract = CreateHRC721Contract(address);
  if (!HRC721Contract) return false;

  const isApproved = await HRC721Contract.getApproved(tokenId);
  if (isApproved.toLowerCase() === ContractAddress.MARKET.toLowerCase()) return true;

  try {
    const approve = await HRC721Contract.approve(ContractAddress.MARKET, tokenId);
    await approve.wait();
    return !!approve;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    return false;
  }
};

export const ERC721SellService = async (data: ERC721SellArgs): Promise<boolean> => {
  const marketContract = CreateMarketContract();
  if (!marketContract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const approved = await ERC721Approved(data.tokenId, data.address);
    if (!approved) {
      store.dispatch(setAppLoading(false));
      return false;
    }
    const decimalPrice = Utils.addHRC20Decimal(data.minPrice);
    if (data.type === 'fixed-price') {
      const result = await marketContract.createERC721FixedPrice(
        data.address,
        data.tokenId,
        ContractAddress.HRC20,
        Math.floor(new Date().getTime() / 1000) + 60,
        data.duration,
        decimalPrice.toString(),
        data.royaltyBps,
      );
      await result.wait();
      return !!result;
    }
    if (data.type === 'auction') {
      const result = await marketContract.createERC721Auction(
        data.address,
        data.tokenId,
        ContractAddress.HRC20,
        Math.floor(new Date().getTime() / 1000) + 60,
        data.duration,
        decimalPrice.toString(),
        data.royaltyBps,
      );
      await result.wait();
      return !!result;
    }
    return false;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
    return false;
  }
};

export const finalizeNFT = async (saleId: number): Promise<boolean> => {
  try {
    const marketContract = CreateMarketContract();
    if (!marketContract) {
      return false;
    }
    store.dispatch(setAppLoading(true));
    const response = await marketContract.finalizeAuction(saleId);
    console.log(response);
    await response.wait();
    return !!response;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
    return false;
  }
};

export const ERC1155FixedPriceSell = async (
  minPrice: number,
  startTime: number,
  duration: number,
  value: number,
  tokenId: number,
): Promise<void> => {
  const marketContract = CreateMarketContract();
  const { user } = store.getState().User;
  if (!marketContract || !user) {
    return;
  }
  await marketContract.createERC1155FixedPrice(
    user.wallet,
    tokenId,
    value,
    ContractAddress.HRC20,
    startTime,
    duration,
    minPrice,
  );
};

export const ERC1155AuctionSell = async (
  minPrice: number,
  startTime: number,
  duration: number,
  value: number,
  tokenId: number,
): Promise<void> => {
  const marketContract = CreateMarketContract();
  const { user } = store.getState().User;
  if (!marketContract || !user) {
    return;
  }
  await marketContract.createERC1155Auction(
    user.wallet,
    tokenId,
    value,
    ContractAddress.HRC20,
    startTime,
    duration,
    minPrice,
  );
};

export const ERC1155BundleFixedPriceSell = async (
  minPrice: number,
  startTime: number,
  duration: number,
  values: number[],
  tokenIds: number[],
): Promise<void> => {
  const marketContract = CreateMarketContract();
  const { user } = store.getState().User;
  if (!marketContract || !user) {
    return;
  }

  await marketContract.createERC1155BundleFixedPrice(
    user.wallet,
    tokenIds,
    values,
    ContractAddress.HRC20,
    startTime,
    duration,
    minPrice,
  );
};

export const ERC1155BundleAuctionSell = async (
  minPrice: number,
  startTime: number,
  duration: number,
  values: number[],
  tokenIds: number[],
): Promise<void> => {
  const marketContract = CreateMarketContract();
  const { user } = store.getState().User;
  if (!marketContract || !user) {
    return;
  }

  await marketContract.createERC1155BundleAuction(
    user.wallet,
    tokenIds,
    values,
    ContractAddress.HRC20,
    startTime,
    duration,
    minPrice,
  );
};

export const CancelSale = async (saleId: number): Promise<boolean> => {
  const marketContract = CreateMarketContract();
  if (!marketContract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const result = await marketContract.cancelSale(saleId, '0x');
    await result.wait();
    return !!result;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
    return false;
  }
};
