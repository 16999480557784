import React from 'react';
import { CustomIconProps, setColor } from '..';

const DashboardIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="dashboard" transform="translate(-620 -252)">
        <g id="element-3" transform="translate(620 252)">
          <path
            id="Vector"
            d="M5.667,4.347V1.32C5.667.38,5.24,0,4.18,0H1.487C.427,0,0,.38,0,1.32V4.34c0,.947.427,1.32,1.487,1.32H4.18C5.24,5.667,5.667,5.287,5.667,4.347Z"
            transform="translate(9 1.333)"
            fill={iconColor}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M5.667,4.18V1.487C5.667.427,5.24,0,4.18,0H1.487C.427,0,0,.427,0,1.487V4.18C0,5.24.427,5.667,1.487,5.667H4.18C5.24,5.667,5.667,5.24,5.667,4.18Z"
            transform="translate(9 9)"
            fill={iconColor}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M5.667,4.347V1.32C5.667.38,5.24,0,4.18,0H1.487C.427,0,0,.38,0,1.32V4.34c0,.947.427,1.32,1.487,1.32H4.18C5.24,5.667,5.667,5.287,5.667,4.347Z"
            transform="translate(1.333 1.333)"
            fill={iconColor}
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M5.667,4.18V1.487C5.667.427,5.24,0,4.18,0H1.487C.427,0,0,.427,0,1.487V4.18C0,5.24.427,5.667,1.487,5.667H4.18C5.24,5.667,5.667,5.24,5.667,4.18Z"
            transform="translate(1.333 9)"
            fill={iconColor}
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H16V16H0Z"
            transform="translate(16 16) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
export default DashboardIcon;
