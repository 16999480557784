import { SaleType } from 'constants/filters/Sale';
import { Attributes, NFTCategory, SortType, ViewMode } from 'models/@types';
import {
  CharacterGender,
  CharacterRace,
  ItemRarity,
  ItemType,
  LandsRegion,
  StructureType,
} from 'models/Filters';
import { IQuery } from 'models/request/Query';

export interface IMarketPlaceState {
  forSale: boolean;
  saleTypes: SaleType[];
  races: CharacterRace[];
  view: ViewMode;
  category: NFTCategory;
  genders: CharacterGender[];
  attributes: Attributes[];
  resourceTypes: Attributes[];
  regions: LandsRegion[];
  rarities: ItemRarity[];
  types: ItemType[];
  structureTypes: StructureType[];
  queries: IQuery[];
  page: number;
  sort: SortType;
  characterId: string;
}

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_RACES = 'SET_RACES';
export const SET_FOR_SALE = 'SET_FOR_SALE';
export const SET_SALE_TYPES = 'SET_SALE_TYPES';
export const SET_VIEW = 'SET_VIEW';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_GENDERS = 'SET_GENDERS';
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES';
export const SET_RESOURCE_TYPES = 'SET_RESOURCE_TYPES';
export const SET_REGIONS = 'SET_REGIONS';
export const SET_RARITIES = 'SET_RARITIES';
export const SET_TYPES = 'SET_TYPES';
export const SET_STRUCTURE_TYPES = 'SET_STRUCTURE_TYPES';
export const SET_QUERIES = 'SET_QUERIES';
export const SET_PAGE = 'SET_PAGE';
export const SET_SORT = 'SET_SORT';
export const SET_CHARACTER_ID = 'SET_CHARACTER_ID';

interface ClearFilters {
  type: typeof CLEAR_FILTERS;
  payload: IMarketPlaceState;
}

interface SetRaces {
  type: typeof SET_RACES;
  payload: CharacterRace[];
}

interface SetAttributes {
  type: typeof SET_ATTRIBUTES;
  payload: Attributes[];
}

interface SetForSale {
  type: typeof SET_FOR_SALE;
  payload: boolean;
}

interface SetSaleType {
  type: typeof SET_SALE_TYPES;
  payload: SaleType[];
}

interface SetView {
  type: typeof SET_VIEW;
  payload: ViewMode;
}

interface SetCategory {
  type: typeof SET_CATEGORY;
  payload: NFTCategory;
}

interface SetGenders {
  type: typeof SET_GENDERS;
  payload: CharacterGender[];
}

interface SetRegions {
  type: typeof SET_REGIONS;
  payload: LandsRegion[];
}

interface SetRarity {
  type: typeof SET_RARITIES;
  payload: ItemRarity[];
}

interface SetTypes {
  type: typeof SET_TYPES;
  payload: ItemType[];
}

interface SetResourceTypes {
  type: typeof SET_RESOURCE_TYPES;
  payload: Attributes[];
}

interface SetStructureTypes {
  type: typeof SET_STRUCTURE_TYPES;
  payload: StructureType[];
}

interface SetQueries {
  type: typeof SET_QUERIES;
  payload: IQuery[];
}

interface SetPage {
  type: typeof SET_PAGE;
  payload: number;
}

interface SetSort {
  type: typeof SET_SORT;
  payload: SortType;
}

interface SetCharacterId {
  type: typeof SET_CHARACTER_ID;
  payload: string;
}

export type MarketplaceActionTypes =
  | SetRaces
  | SetForSale
  | SetSaleType
  | SetView
  | SetCategory
  | SetGenders
  | ClearFilters
  | SetAttributes
  | SetResourceTypes
  | SetRegions
  | SetRarity
  | SetStructureTypes
  | SetQueries
  | SetPage
  | SetSort
  | SetCharacterId
  | SetTypes;
