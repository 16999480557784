interface IMapLandAttr {
  COORDINATES: string;
  REGION: string;
}
export interface IMapLand {
  _id: string;
  tokenId: number;
  contract: string;
  attributes: IMapLandAttr;
  sale: Record<string, any>;
}

export class MapLand {
  id: string;
  tokenId: number;
  contract: string;
  attributes: IMapLandAttr;
  onSale: boolean;
  constructor(props: IMapLand) {
    this.id = props._id;
    this.tokenId = props.tokenId;
    this.contract = props.contract;
    this.attributes = props.attributes;
    this.onSale = !!props.sale || false;
  }
  get xCoordinate(): number {
    if (this.attributes) {
      const cArray = this.attributes.COORDINATES.split(', ');
      return +cArray[0] || 0;
    }
    return 0;
  }

  get yCoordinate(): number {
    if (this.attributes) {
      const cArray = this.attributes.COORDINATES.split(', ');
      return +cArray[1] || 0;
    }
    return 0;
  }
}
