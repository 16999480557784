import React from 'react';
import { CustomIconProps, setColor } from '..';

const ZoomOutIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 2">
      <path
        id="Vector"
        d="M0,0H10"
        transform="translate(1 1)"
        fill="none"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};
export default ZoomOutIcon;
