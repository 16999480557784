import React from 'react';
import { CustomIconProps, setColor } from '..';

const ArtifactsIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
      <g id="Advanced" transform="translate(-200 -434)">
        <rect
          id="Rectangle_3718"
          data-name="Rectangle 3718"
          width="10"
          height="2"
          rx="1"
          transform="translate(200 442)"
          fill={iconColor}
        />
        <rect
          id="Rectangle_3719"
          data-name="Rectangle 3719"
          width="10"
          height="2"
          rx="1"
          transform="translate(200 438)"
          fill={iconColor}
        />
        <rect
          id="Rectangle_3720"
          data-name="Rectangle 3720"
          width="10"
          height="2"
          rx="1"
          transform="translate(200 434)"
          fill={iconColor}
        />
      </g>
    </svg>
  );
};
export default ArtifactsIcon;
