import React from 'react';
import { CustomIconProps, setColor } from '..';

const ForSaleIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="vuesax_bold_tag" data-name="vuesax/bold/tag" transform="translate(-556 -444)">
        <g id="tag" transform="translate(556 444)">
          <path
            id="Vector"
            d="M11.377,3.951,8.357.931A3.167,3.167,0,0,0,5.957,0L2.623.164A2.576,2.576,0,0,0,.163,2.611L0,5.944a3.192,3.192,0,0,0,.927,2.4l3.02,3.02a3.187,3.187,0,0,0,4.5,0l2.927-2.927A3.159,3.159,0,0,0,11.377,3.951ZM4.49,6.4a1.92,1.92,0,1,1,1.92-1.92A1.92,1.92,0,0,1,4.49,6.4Z"
            transform="translate(1.843 1.849)"
            fill={iconColor}
          />
          <path id="Vector-2" data-name="Vector" d="M0,0H16V16H0Z" fill="none" opacity="0" />
        </g>
      </g>
    </svg>
  );
};
export default ForSaleIcon;
