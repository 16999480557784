import React from 'react';
import { NFTDetails } from 'models/NFTDetails';
import DialogModal from 'components/global/dialog-modal';
import AuctionBuy from './_Auction';
import FixedPriceBuy from './_Fixed';

interface IProps {
  data: NFTDetails;
  open: boolean;
  onClose: () => void;
}

const BuyNFT: React.FunctionComponent<IProps> = ({ open, onClose, data }) => {
  const { sale, numeralPrice } = data;
  if (!sale) {
    return null;
  }
  const { saleId, bidToken, type, buyer } = sale;
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title={type === 'fixed-price' ? 'Fixed Price Method' : 'Auction Method'}
      contentProps={{ className: 'small-scrollbar' }}
      maxWidth={type === 'fixed-price' ? 'xs' : 'sm'}
    >
      {type === 'fixed-price' ? (
        <FixedPriceBuy
          saleId={saleId}
          currentValue={numeralPrice}
          onSuccess={onClose}
          bidToken={bidToken.symbol}
        />
      ) : (
        <AuctionBuy
          currentValue={numeralPrice}
          saleId={saleId}
          onSuccess={onClose}
          notAddTenPercent={!buyer?.wallet}
        />
      )}
    </DialogModal>
  );
};
export default BuyNFT;
