import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { userInformationService } from 'services/User';
import { AppTheme } from 'models/@types';
import { User } from 'models/User';
import FragmentWrapper from 'components/template/fragment-wrapper';
import ProfileSummaryActions from './components/profile-summary-actions';
import ProfileSummaryInformation from './components/profile-summary-information';
import ProfileSummaryInventory from './components/profile-summary-inventory';

interface IProps {
  wallet?: string;
}

const ProfileSummary: React.FunctionComponent<IProps> = ({ wallet }) => {
  const appTheme = useSelector<RootState, AppTheme>(state => state.App.appTheme);

  const { data } = useQuery<User | undefined>(
    ['user', wallet],
    () => userInformationService(wallet),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );
  return (
    <FragmentWrapper key={appTheme}>
      {!wallet && <ProfileSummaryActions />}
      <div className="d-flex align-items-center flex-column w-100">
        <ProfileSummaryInformation userData={data} />
        <ProfileSummaryInventory userData={data} />
      </div>
    </FragmentWrapper>
  );
};

export default ProfileSummary;
