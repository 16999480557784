import { createURL, sendRequest } from 'apis/index';
import Response from 'models/request/Response';
import { IDashboardListReq, DashboardListReq } from './_';

const baseUrl = 'marketplace/sales';

export const DashboardAPI = {
  getStatistics: (): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_STATISTICS',
      method: 'GET',
      url: createURL({
        baseUrl,
        args: ['stats'],
      }),
    });
  },
  getLatestListing: (req: IDashboardListReq): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_LATEST_LISTING',
      method: 'GET',
      url: createURL({
        baseUrl,
        args: ['latest-listing'],
        queries: new DashboardListReq(req).queries(),
      }),
    });
  },
  getLatestSales: (req: IDashboardListReq): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_LATEST_SALES',
      method: 'GET',
      url: createURL({
        baseUrl,
        args: ['latest-sales'],
        queries: new DashboardListReq(req).queries(),
      }),
    });
  },
};
