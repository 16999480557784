import React from 'react';
import { Typography } from '@mui/material';
import Strings from 'utils/Strings';

interface ILink {
  text: string;
  href: string;
}

const Links: React.FunctionComponent = () => {
  const links: ILink[] = [
    { text: Strings.discord, href: '/' },
    { text: Strings.terms, href: '/' },
    { text: Strings.privacy, href: '/' },
  ];
  return (
    <div className="d-flex align-items-center justify-content-around w-100 mt-auto px-2">
      {links.map(item => (
        <Typography
          key={item.text}
          fontWeight="bold"
          color="primary"
          component="a"
          href={item.href}
          target="_blank"
          sx={{ textDecoration: 'none' }}
        >
          {item.text}
        </Typography>
      ))}
    </div>
  );
};
export default Links;
