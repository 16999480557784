import React from 'react';
import { CustomIconProps, setColor } from '..';

const MapViewIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg id="map" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        id="Vector"
        d="M0,4.442v9.73c0,1.9,1.35,2.68,2.99,1.74l2.35-1.34a2.232,2.232,0,0,1,1.89-.05l5.25,2.63a2.268,2.268,0,0,0,1.89-.05l4.33-2.48a2.24,2.24,0,0,0,1.01-1.74V3.152c0-1.9-1.35-2.68-2.99-1.74l-2.35,1.34a2.232,2.232,0,0,1-1.89.05L7.23.182a2.268,2.268,0,0,0-1.89.05L1.01,2.712A2.21,2.21,0,0,0,0,4.442Z"
        transform="translate(2.29 3.338)"
        fill="none"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0V13"
        transform="translate(8.56 4)"
        fill="none"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0V13.38"
        transform="translate(15.73 6.62)"
        fill="none"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M0,0H24V24H0Z"
        transform="translate(24 24) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  );
};
export default MapViewIcon;
