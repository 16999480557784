import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, Theme, useMediaQuery } from '@mui/material';
import { RootState } from 'store';
import { setPage } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import ColorPalette from 'utils/ColorPalette';
import { getNFTsService, IGetNFTRes } from 'services/MarketPlace';
import { LandViewMode } from 'models/@types';
import LandViewSelect from 'pages/market-place/components/land-view-select';
import LandCanvas from 'components/common/land-canvas';
import Div from 'components/global/div';
import FetchWrapper from 'components/layouts/fetch-wrapper';
import Sort from '../sort';
import ViewSelect from '../view-select';
import NFTGridView from './GridView';
import NFTListView from './ListView';

interface INFTListProps {
  user?: string;
  showFilters?: boolean;
  centerGrid?: boolean;
}

const NFTList: React.FunctionComponent<INFTListProps> = ({
  user,
  showFilters = true,
  centerGrid,
}) => {
  const dispatch = useDispatch();
  const { category, view, queries, page, sort, forSale, saleTypes } = useSelector<
    RootState,
    IMarketPlaceState
  >(state => state.MarketPlace);
  const landView = useSelector<RootState, LandViewMode>(state => state.Constants.landView);
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const { data, isLoading, error, refetch } = useQuery<IGetNFTRes>(
    [user ? 'USER_NFT' : 'NFTs', page, category, sort, queries, forSale, saleTypes, user],
    () => getNFTsService({ user }),
    { refetchOnWindowFocus: false, refetchInterval: false },
  );

  const onLoadMore = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(setPage(pageNumber));
  };

  // useUpdateEffect(() => {
  //   refetch().finally(() => {});
  // }, [page, category, sort, queries]);

  return (
    <Div
      className="w-100 d-flex flex-column"
      sx={{
        minHeight: 'calc(100vh - 300px)',
        '@media screen and (min-width: 600px)': { minHeight: 'calc(100vh - 170px)' },
      }}
    >
      {matches && showFilters && (
        <div className="d-flex align-items-center justify-content-between my-3">
          <Sort />
          {category === 'land' ? <LandViewSelect /> : <ViewSelect />}
        </div>
      )}
      {category === 'land' && landView === 'land' ? (
        <Div
          sx={{
            maxWidth: '100%',
            maxHeight: 'calc(100vh - 292px)',
            overflow: 'hidden',
            borderRadius: '4px',
            '@media screen and (min-width: 600px)': {
              maxHeight: 'calc(100vh - 168px)',
            },
            '@media screen and (min-width: 900px)': {
              maxHeight: 'calc(100vh - 270px)',
            },
            '& canvas': {
              width: '100% !important',
              height: '100% !important',
            },
          }}
        >
          <LandCanvas />
        </Div>
      ) : (
        <>
          <FetchWrapper loading={isLoading} error={error} empty={data?.list.length === 0}>
            {view === 'list' ? (
              <NFTListView items={data?.list || []} />
            ) : (
              <NFTGridView items={data?.list || []} centerAlign={centerGrid} onRefresh={refetch} />
            )}
          </FetchWrapper>
          {(data?.pageCount || 0) > 1 && (
            <div className="d-flex align-items-center justify-content-center w-100 mt-auto pt-4">
              <Pagination
                shape="rounded"
                color="primary"
                siblingCount={matches ? 1 : 0}
                size={matches ? 'large' : 'medium'}
                count={data?.pageCount || 0}
                onChange={onLoadMore}
                page={page}
                defaultPage={page}
                sx={{
                  '& .MuiPaginationItem-root': {
                    fontWeight: 'bold',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    color: ColorPalette.white,
                    fontWeight: 'bold',
                  },
                }}
              />
            </div>
          )}
        </>
      )}
    </Div>
  );
};
export default NFTList;
