import React from 'react';
import { PixiComponent, useApp } from '@inlet/react-pixi';
import { Viewport as PixiViewport } from 'pixi-viewport';
import * as PIXI from 'pixi.js';

export interface ViewportProps {
  children?: React.ReactNode;
  onClick?: (x: number, y: number, xLand: number, yLand: number) => void;
}

export interface PixiComponentViewportProps extends ViewportProps {
  app: PIXI.Application;
}

const PixiComponentViewport = PixiComponent('Viewport', {
  create: (props: PixiComponentViewportProps) => {
    const viewport = new PixiViewport({
      passiveWheel: false,
      ticker: props.app.ticker,
      interaction: props.app.renderer.plugins.interaction,
    });
    viewport.drag().pinch().wheel().clampZoom({ maxScale: 15, minScale: 0.4 });

    viewport.on('clicked', ({ world, screen }) => {
      const { x, y } = world;
      const xItem = Math.floor(Math.floor(x) / 10) - 54;
      const yItem = Math.floor(Math.floor(y) / 10) - 54;
      if (props.onClick) {
        props.onClick(screen.x, screen.y, xItem, yItem);
      }
    });

    const zoomInButton = document.getElementById('land-zoom-in-button');
    if (zoomInButton) {
      zoomInButton.addEventListener('click', () => {
        viewport.zoomPercent(0.2);
      });
    }

    const zoomOutButton = document.getElementById('land-zoom-out-button');
    if (zoomOutButton) {
      zoomOutButton.addEventListener('click', () => {
        viewport.zoomPercent(-0.2);
      });
    }

    return viewport;
  },
});

const Viewport: React.FunctionComponent<ViewportProps> = ({ ...props }) => {
  const app = useApp();
  return <PixiComponentViewport app={app} {...props} />;
};

export default Viewport;
