import { NFTCategory } from 'models/@types';
import QueryRequest, { IQuery } from 'models/request/Query';

export interface IDashboardListReq {
  category: NFTCategory;
}

export class DashboardListReq implements QueryRequest {
  category: NFTCategory;
  constructor(props: IDashboardListReq) {
    this.category = props.category;
  }
  queries(): Array<IQuery> {
    return [{ key: 'category', value: this.category }];
  }
}
