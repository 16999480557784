import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import Utils from 'libs/Utils';
import { NFT } from 'models/NFT';
import ClassicCard from 'components/common/classic-card';
import CMPDropdown from 'components/common/dropdown';
import Circle from 'components/global/circle';
import LogoIcon from 'components/icons/LogoIcon';
import LocationIcon from 'components/icons/main/LocationIcon';

interface INFTListViewProps {
  items: NFT[];
}

const NFTListView: React.FunctionComponent<INFTListViewProps> = ({ items }) => {
  return (
    <Grid container spacing={2}>
      {items.map(nft => (
        <Grid key={`${nft.contract}-${nft.tokenId}`} item xs={12} xl={6}>
          <ClassicCard
            // @ts-ignore
            component={Link}
            to={`/${nft.category}/${nft.contract}/${nft.tokenId}`}
            asset={nft.asset}
            title={nft.metadata.name}
            rank={nft.rarity?.rank}
            body={
              nft.category === 'character'
                ? nft.race
                  ? [{ icon: <Circle color={nft.race.color} />, title: nft.race.type }]
                  : undefined
                : nft.coordinate
                ? [
                    {
                      icon: <LocationIcon color={nft.region?.color ?? undefined} />,
                      title: nft.coordinate,
                    },
                  ]
                : undefined
            }
            description={
              nft.sale
                ? { icon: <LogoIcon />, value: nft.priceStr, price: nft.usdtPrice }
                : undefined
            }
            time={nft.startDate}
            endIcon={
              nft.metadata.attributes.length > 0 ? (
                <CMPDropdown
                  iconMode
                  items={nft.metadata.attributes.map(item => ({
                    key: item.trait_type,
                    text: item.value,
                    extra: Utils.getAttributeRarity(
                      //@ts-ignore
                      nft.attributes.gender,
                      item.trait_type,
                      item.value,
                    ),
                  }))}
                  smallItems
                  textItems
                />
              ) : undefined
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default NFTListView;
