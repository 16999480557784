import React from 'react';

const VerificationIcon: React.FunctionComponent = () => {
  return (
    <svg
      id="sms-notification"
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 128 128"
    >
      <path id="Vector" d="M0,0H128V128H0Z" fill="none" opacity="0" />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M26.667,13.333A13.333,13.333,0,1,1,13.333,0,13.333,13.333,0,0,1,26.667,13.333Z"
        transform="translate(90.667 16)"
        fill="#611caf"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M26.667,13.333A13.333,13.333,0,1,1,13.333,0,13.333,13.333,0,0,1,26.667,13.333Z"
        transform="translate(90.667 16)"
        fill="#611caf"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M99.84,30.933a5.311,5.311,0,0,1,6.827,5.12V64C106.667,82.667,96,90.667,80,90.667H26.667C10.667,90.667,0,82.667,0,64V26.667C0,8,10.667,0,26.667,0H67.253a5.38,5.38,0,0,1,5.173,6.56,21.335,21.335,0,0,0,15.36,24.693A21.928,21.928,0,0,0,99.84,30.933Z"
        transform="translate(10.667 18.72)"
        fill="#611caf"
        opacity="0.4"
      />
      <g id="Group" transform="translate(33.319 43.986)">
        <path
          id="Vector-5"
          data-name="Vector"
          d="M30.681,24.654A19.826,19.826,0,0,1,18.2,20.441L1.508,7.108A3.986,3.986,0,0,1,6.468.868L23.161,14.2a12.726,12.726,0,0,0,14.987,0l6.293-5.013a3.986,3.986,0,1,1,4.96,6.24l-6.293,5.013A19.572,19.572,0,0,1,30.681,24.654Z"
          fill="#611caf"
        />
      </g>
    </svg>
  );
};

export default VerificationIcon;
