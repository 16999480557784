import { AppTheme, ISnackbar } from 'models/@types';
import { Category } from 'models/Category';

export interface AppStoreState {
  appTheme: AppTheme;
  appLoading: boolean;
  snackbar: ISnackbar | null;
  categories: Category[];
}

export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_APP_THEME = 'SET_APP_THEME';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_CATEGORIES = 'SET_CATEGORIES';

interface SetAppLoading {
  type: typeof SET_APP_LOADING;
  payload: boolean;
}

interface SetAppTheme {
  type: typeof SET_APP_THEME;
  payload: AppTheme;
}

interface SetSnackbar {
  type: typeof SET_SNACKBAR;
  payload: ISnackbar | null;
}

interface SetCategories {
  type: typeof SET_CATEGORIES;
  payload: Category[];
}

export type AppActionTypes = SetAppLoading | SetAppTheme | SetSnackbar | SetCategories;
