import React, { useState } from 'react';
import { Divider, Theme, useMediaQuery } from '@mui/material';
import { NFTCategory } from 'models/@types';
import Categories from 'components/common/categories';
import TabView from 'components/common/tab-view';
import { DashboardTab, dashboardTabs } from '../../data';
import DashboardListing from '../listing';
import DashboardSales from '../sales';

interface IProps {
  category: NFTCategory;
  onSelectCat: (cat: NFTCategory) => void;
}

const DashboardMobileView: React.FunctionComponent<IProps> = ({ category, onSelectCat }) => {
  const matches = useMediaQuery<Theme>('(max-width:650px)', {
    noSsr: true,
  });
  const desktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const [activeTab, setActiveTab] = useState<DashboardTab>('LISTING');
  return (
    <div className="mt-2">
      {!desktop && (
        <Categories activeItem={category} onChangeCategory={onSelectCat} className="mx-auto" />
      )}
      <TabView
        items={dashboardTabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        w100={false}
        className="mt-2"
        buttonStyle={{ height: 52 }}
      />
      <Divider className="mb-3" />
      {activeTab === 'SALES' ? (
        <DashboardSales gridCard={matches} category={category} />
      ) : (
        <DashboardListing gridCard={matches} category={category} />
      )}
    </div>
  );
};
export default DashboardMobileView;
