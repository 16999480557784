export interface IPagination {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
}

export interface IMetaData {
  pagination: IPagination;
}

export interface IServerResponse {
  data?: any;
  errors?: any;
  message?: string;
  success?: boolean;
  metaData?: IMetaData;
}

export default class Response {
  data?: any;
  errors?: any;
  message?: string;
  success?: boolean;
  metadata?: IMetaData;
  constructor(props: IServerResponse) {
    this.data = props.data;
    this.errors = props.errors;
    this.message = props.message;
    this.success = props.success;
    this.metadata = props.metaData;
  }
}
