import { styled } from '@mui/material';

export const classes = {
  listCol: 'list-col',
  statisticsCol: 'statistics-col',
  statisticsContainer: 'statistics-container',
  statisticsList: 'statistics-list',
  statisticsRow: 'statistics-row',
  statisticsHeader: 'statistics-header',
  activeTab: 'active-tab',
};

const ProfileContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  minHeight: '100vh',
  margin: '8px auto',
  flexDirection: 'column',
  maxWidth: 1376,

  // [theme.breakpoints.up('md')]: {
  //   flexDirection: 'row',
  // },
  // [`& .${classes.statisticsCol}`]: {
  //   width: '100%',
  //   order: 1,
  //   [theme.breakpoints.up('md')]: {
  //     width: '35%',
  //     paddingInlineStart: 8,
  //     order: 2,
  //   },
  //   [theme.breakpoints.up('xl')]: {
  //     width: '22%',
  //   },
  // },
  // [`& .${classes.listCol}`]: {
  //   width: '100%',
  //   order: 2,
  //   marginTop: 16,
  //   [theme.breakpoints.up('md')]: {
  //     width: '65%',
  //     marginTop: 0,
  //     order: 1,
  //     paddingInlineEnd: 8,
  //   },
  //   [theme.breakpoints.up('xl')]: {
  //     width: '78%',
  //   },
  // },
  // [`& .${classes.statisticsContainer}`]: {
  //   borderRadius: 18,
  //   border: Utils.getAppTheme() === 'LIGHT' ? `1px solid ${theme.palette.divider}` : undefined,
  //   // overflow: 'hidden',
  //   boxSizing: 'border-box',
  //   backgroundColor: theme.palette.filterBg,
  //   maxWidth: 320,
  //   margin: '0 auto',
  //   overflow: 'hidden',
  //   [theme.breakpoints.up('md')]: {
  //     borderRadius: 24,
  //   },
  // },
  // [`& .${classes.statisticsList}`]: {
  //   marginTop: 16,
  //   width: '100%',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  // },
  // [`& .${classes.statisticsRow}`]: {
  //   width: '100%',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   marginBottom: 32,
  // },
  // [`& .${classes.statisticsHeader}`]: {
  //   height: 68,
  //   width: '99%',
  //   display: 'flex',
  //   alignItems: 'center',
  //   borderBottom: `1px solid ${theme.palette.divider}`,
  //   margin: '0 auto',
  // },
}));
export default ProfileContainer;
