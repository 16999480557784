import store from 'store';

interface IColorPalletMode {
  light: string;
  dark: string;
}
export interface IColorPalette {
  inputBg: IColorPalletMode;
  divider: IColorPalletMode;
  background: IColorPalletMode;
  textPrimary: IColorPalletMode;
  gray: IColorPalletMode;
  filterBg: IColorPalletMode;
  filterCard: IColorPalletMode;
  purple: IColorPalletMode;
  iconBg: IColorPalletMode;
  sideMenu: IColorPalletMode;
}

export const ColorPalette: IColorPalette = {
  background: {
    dark: '#140034',
    light: '#FFFFFF',
  },
  divider: {
    dark: 'rgba(255, 255, 255, 0.12)',
    light: 'rgba(0, 0, 0, 0.12)',
  },
  textPrimary: {
    dark: '#E7E5EA',
    light: '#140034',
  },
  inputBg: {
    dark: '#030025',
    light: '#F9F9FA',
  },
  gray: {
    dark: '#707070',
    light: '#cfcfcf',
  },
  filterBg: {
    dark: '#030025',
    light: '#FFFFFF',
  },
  sideMenu: {
    dark: '#140034',
    light: '#F9F9FA',
  },
  filterCard: {
    dark: '#030025',
    light: '#F9F9FA',
  },
  purple: {
    dark: '#A199AD',
    light: '#611CAF',
  },
  iconBg: {
    dark: 'rgba(231, 229, 234, 0.2)',
    light: 'rgba(231, 229, 234, 0.5)',
  },
};

export const setColor = (key: keyof IColorPalette): string => {
  const { appTheme } = store.getState().App;
  return appTheme === 'DARK' ? ColorPalette[key].dark : ColorPalette[key].light;
};
