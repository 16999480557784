import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Typography, useTheme } from '@mui/material';
import Strings from 'utils/Strings';
import Div from 'components/global/div';
import NothingFoundIcon from 'components/icons/main/NothingFoundIcon';

interface IFetchWrapperProps {
  loading?: boolean;
  error?: unknown;
  empty?: boolean;
}

const FetchWrapper: React.FunctionComponent<IFetchWrapperProps> = ({
  loading,
  error,
  empty,
  children,
}) => {
  const { palette } = useTheme();
  if (loading) {
    return (
      <Div className="d-flex align-items-center justify-content-center w-100" sx={{ height: 320 }}>
        <ThreeDots color={palette.text.secondary} />
      </Div>
    );
  }
  if (empty) {
    return (
      <Div
        className="d-flex flex-column align-items-center justify-content-center w-100"
        sx={{ height: 320 }}
      >
        <NothingFoundIcon color={palette.text.secondary} />
        <Typography variant="body1" color="textSecondary" className="mt-3">
          {Strings.nothingFound}
        </Typography>
      </Div>
    );
  }
  if (error) {
    return (
      <Div
        className="d-flex flex-column align-items-center justify-content-center w-100"
        sx={{ height: 320 }}
      >
        <Typography variant="body1" color="textSecondary" className="mt-3">
          {Strings.error}
        </Typography>
      </Div>
    );
  }
  return <>{children}</>;
};
export default FetchWrapper;
