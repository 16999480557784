import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import { getLatestListingService } from 'services/Dashboard';
import { NFTCategory } from 'models/@types';
import { DashboardItem } from 'models/Dashboard';
import Card from 'components/common/card';
import ClassicCard from 'components/common/classic-card';
import CMPDropdown from 'components/common/dropdown';
import Circle from 'components/global/circle';
import LogoIcon from 'components/icons/LogoIcon';
import LocationIcon from 'components/icons/main/LocationIcon';
import FetchWrapper from 'components/layouts/fetch-wrapper';

interface IProps {
  gridCard?: boolean;
  category: NFTCategory;
}

const DashboardListing: React.FunctionComponent<IProps> = ({ gridCard, category }) => {
  const { data, isLoading, error, refetch } = useQuery<DashboardItem[]>(
    ['latest-listing', category],
    () => getLatestListingService({ category }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );

  useEffect(() => {
    refetch().then(() => {});
  }, [category]);

  return (
    <div className={clsx('mt-4', { 'mx-auto': gridCard })}>
      <Typography className="mb-4 ms-2" sx={{ fontSize: '18px', fontWeight: 500 }}>
        {Strings.listing}
      </Typography>
      <FetchWrapper loading={isLoading} error={error} empty={data?.length === 0}>
        {gridCard ? (
          <>
            {data?.map(item => (
              <Card
                key={item.id}
                className={clsx('mt-3', { 'mx-auto': gridCard })}
                asset={item.nfts.asset}
                title={item.nfts.metadata.name}
                rank={item.nfts.rarity?.rank || undefined}
                body={
                  item.nfts.category === 'character'
                    ? item.nfts.race
                      ? [
                          {
                            icon: <Circle color={item.nfts.race.color} />,
                            title: item.nfts.race.type,
                          },
                        ]
                      : undefined
                    : item.nfts.coordinate
                    ? [
                        {
                          icon: <LocationIcon color={item.nfts.region?.color ?? undefined} />,
                          title: item.nfts.coordinate,
                        },
                      ]
                    : undefined
                }
                description={{
                  icon: <LogoIcon />,
                  value: item.DecimalsPrice,
                  price: item.usdtPrice,
                }}
                // @ts-ignore
                component={Link}
                to={`/${category}/${item.contractAddress}/${item.nfts.tokenId}`}
              />
            ))}
          </>
        ) : (
          <>
            {data?.map(item => (
              <ClassicCard
                key={item.id}
                className="mt-3"
                asset={item.nfts.asset}
                title={item.nfts.metadata.name}
                rank={item.nfts.rarity?.rank || undefined}
                body={
                  item.nfts.category === 'character'
                    ? item.nfts.race
                      ? [
                          {
                            icon: <Circle color={item.nfts.race.color} />,
                            title: item.nfts.race.type,
                          },
                        ]
                      : undefined
                    : item.nfts.coordinate
                    ? [
                        {
                          icon: <LocationIcon color={item.nfts.region?.color ?? undefined} />,
                          title: item.nfts.coordinate,
                        },
                      ]
                    : undefined
                }
                description={{
                  icon: <LogoIcon />,
                  value: item.DecimalsPrice,
                  price: item.usdtPrice,
                }}
                time={item.startDate}
                endIcon={
                  item.nfts.metadata.attributes.length > 0 ? (
                    <CMPDropdown
                      iconMode
                      items={item.nfts.metadata.attributes.map(attr => ({
                        key: attr.trait_type,
                        text: attr.value,
                        extra: Utils.getAttributeRarity(
                          //@ts-ignore
                          item.nfts.attributes.gender,
                          attr.trait_type,
                          attr.value,
                        ),
                      }))}
                      smallItems
                      textItems
                    />
                  ) : undefined
                }
                // @ts-ignore
                component={Link}
                to={`/${category}/${item.contractAddress}/${item.nfts.tokenId}`}
              />
            ))}
          </>
        )}
      </FetchWrapper>
    </div>
  );
};
export default DashboardListing;
