import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { RootState } from 'store';
import LoadingContainer from './_styles';

const AppLoading: React.FunctionComponent = () => {
  const loading = useSelector<RootState, boolean>(state => state.App.appLoading);

  if (!loading) {
    return null;
  }

  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};
export default AppLoading;
