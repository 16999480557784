import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setCharacterId } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import Strings from 'utils/Strings';
import FilterContainer from 'components/common/filter-container';
import CMPInput from 'components/global/input';

interface IProps {
  name?: string;
}

const CharacterID: React.FunctionComponent<IProps> = ({ name = Strings.characterId }) => {
  const dispatch = useDispatch();
  const { characterId } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);

  const onFilter = (value: string) => {
    dispatch(setCharacterId(value));
  };

  return (
    <FilterContainer
      className="mt-3"
      title={name}
      body={
        <CMPInput
          filled
          fullWidth
          placeholder={name}
          value={characterId}
          onChanged={onFilter}
          className="my-3"
        />
      }
    />
  );
};
export default CharacterID;
