import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { RootState } from 'store';
import { setStructureTypes } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import Strings from 'utils/Strings';
import { structureTypes as items } from 'constants/filters/Structure';
import { StructureType } from 'models/Filters';
import FilterCard from 'components/common/filter-card';
import FilterContainer from 'components/common/filter-container';

const ItemTypes: React.FunctionComponent = () => {
  const theme = useTheme();
  const { structureTypes } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);
  const dispatch = useDispatch();

  const onSelectHandler = (key: StructureType) => {
    const index = structureTypes.findIndex(item => item === key);
    if (index === -1) {
      structureTypes.push(key);
      dispatch(setStructureTypes(structureTypes));
    } else {
      structureTypes.splice(index, 1);
      dispatch(setStructureTypes(structureTypes));
    }
  };

  return (
    <FilterContainer
      className="mt-3"
      title={Strings.types}
      count={structureTypes.length > 0 ? structureTypes.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
          {items.map(({ icon: Icon, type }) => (
            <FilterCard
              key={type}
              icon={<Icon color={theme.palette.purple} />}
              text={type}
              onClick={() => onSelectHandler(type)}
              active={structureTypes.includes(type)}
              purpleMode
            />
          ))}
        </div>
      }
    />
  );
};
export default ItemTypes;
