import { ComponentType } from 'react';
import { NFTCategory } from 'models/@types';
import { Nfts } from 'models/User';
import CharacterIcon from 'components/icons/categories/CharacterIcon';
import ItemIcon from 'components/icons/categories/ItemIcon';
import LandIcon from 'components/icons/categories/LandIcon';
import ResourceIcon from 'components/icons/categories/ResourceIcon';
import StructureIcon from 'components/icons/categories/StructureIcon';

interface Inventory {
  type: NFTCategory;
  amount: number;
  icon: ComponentType<any>;
}

export const userInventories = (nft: Nfts | undefined): Inventory[] => {
  return [
    { amount: nft?.character || 0, type: 'character', icon: CharacterIcon },
    { amount: nft?.companion || 0, type: 'companion', icon: CharacterIcon },
    { amount: nft?.land || 0, type: 'land', icon: LandIcon },
    { amount: nft?.item || 0, type: 'item', icon: ItemIcon },
    { amount: nft?.resource || 0, type: 'resource', icon: ResourceIcon },
    { amount: nft?.structure || 0, type: 'structure', icon: StructureIcon },
  ];
};
