import React from 'react';

const CheckedIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.536"
      height="11.018"
      viewBox="0 0 15.536 11.018"
    >
      <path
        id="Vector"
        d="M0,4,4,8l8-8"
        transform="translate(1.768 1.768)"
        fill="none"
        stroke="#f4b63a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};
export default CheckedIcon;
