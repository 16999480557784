import React from 'react';
import { Fade, ListItemButton, ListItemIcon, ListItemText, Menu, Typography } from '@mui/material';
import Utils from 'libs/Utils';
import CMPButton from 'components/global/button';
import Circle from 'components/global/circle';
import ArrowRightIcon from 'components/icons/main/ArrowRightIcon';
import CheckedIcon from 'components/icons/main/CheckedItem';
import NotCheckedIcon from 'components/icons/main/NotCheckedIcon';
import CMPMenu, { classes } from './_styles';

interface IProps {
  title: string;
  items: string[];
  checkedItems: string[];
  onCheckItem: (item: string) => void;
}

const CMPSideMenu: React.FunctionComponent<IProps> = ({
  title,
  items,
  checkedItems,
  onCheckItem,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CMPMenu>
      <CMPButton variant="text" color="inherit" className={classes.button} onClick={handleClick}>
        <div className="d-flex align-items-center">
          {checkedItems.length > 0 && <Circle color="#F4B63A" size={8} />}
          <Typography
            sx={{ marginInlineStart: checkedItems.length > 0 ? '6px' : 0, fontWeight: 600 }}
          >
            {title}
          </Typography>
        </div>
        <ArrowRightIcon />
      </CMPButton>
      <Menu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
        BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } }}
        MenuListProps={{ disablePadding: true, 'aria-labelledby': 'fade-button' }}
        PaperProps={{
          sx: {
            boxShadow: 0,
            width: 220,
            padding: 0,
            background: theme => theme.palette.filterBg,
            border: theme =>
              Utils.getAppTheme() === 'LIGHT' ? `1px solid ${theme.palette.divider}` : undefined,
            borderRadius: '24px',
            marginInlineStart: 1,
            maxHeight: 500,
            overflow: 'auto',
          },
          className: 'small-scrollbar',
        }}
      >
        {items.map(item => (
          <ListItemButton key={item} disableRipple onClick={() => onCheckItem(item)}>
            <ListItemIcon sx={{ minWidth: 28 }}>
              {checkedItems.includes(item) ? <CheckedIcon /> : <NotCheckedIcon />}
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItemButton>
        ))}
      </Menu>
    </CMPMenu>
  );
};
export default CMPSideMenu;
