import React from 'react';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { RootState } from 'store';
import Utils from 'libs/Utils';
import { AppTheme } from 'models/@types';
import DarkThemeIcon from 'components/icons/header/DarkThemeIcon';
import LightThemeIcon from 'components/icons/header/LightThemeIcon';

const ChangeTheme: React.FunctionComponent = () => {
  const appTheme = useSelector<RootState, AppTheme>(state => state.App.appTheme);

  const onChangeTheme = () => {
    Utils.changeTheme(appTheme === 'LIGHT' ? 'DARK' : 'LIGHT');
  };

  return (
    <IconButton
      sx={{ border: theme => `1px solid ${theme.palette.divider}` }}
      onClick={onChangeTheme}
    >
      {appTheme === 'LIGHT' ? <DarkThemeIcon /> : <LightThemeIcon />}
    </IconButton>
  );
};
export default ChangeTheme;
