import store from 'store';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import LocalStorageKey from 'constants/LocalStorageKey';
import { NFTCategory } from 'models/@types';

export interface IUserToken {
  token: string;
}

export class UserToken {
  token: string;
  constructor(props?: IUserToken) {
    this.token = props ? props.token : store.getState().User.userToken?.token || '';
  }
  deleteFromLocalStorage(): void {
    window.localStorage.removeItem(LocalStorageKey.token);
  }

  async readFromLocalStorage(): Promise<void> {
    const tokenInfo = window.localStorage.getItem(LocalStorageKey.token);
    if (tokenInfo) {
      try {
        const decryptedToken = await Utils.decryptAES(tokenInfo);
        const { token } = JSON.parse(decryptedToken) as IUserToken;
        this.token = token;
      } catch (error) {
        console.error(error);
      }
    }
  }

  isAllSet(): boolean {
    return this.token !== '';
  }

  setToLocalStorage(token: string): void {
    const tokenStr = JSON.stringify({ token });
    window.localStorage.setItem(LocalStorageKey.token, Utils.encryptAES(tokenStr));
  }
}

export type Nfts = Record<NFTCategory, number>;
export interface IUser {
  name: string;
  wallet: string;
  _id: string;
  email: string;
  nfts: Nfts;
}

export class User {
  name: string;
  wallet: string;
  userId: string;
  email: string;
  nfts: Nfts;
  constructor(props: IUser) {
    this.name = props.name || Strings.noName;
    this.wallet = props.wallet;
    this.userId = props._id;
    this.email = props.email;
    this.nfts = props.nfts;
  }

  get minimizedWallet(): string {
    return Utils.minimizeAddress(this.wallet);
  }
}
