import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

interface IProps extends IconButtonProps {}

const GIconButton: React.FunctionComponent<IProps> = ({ children, sx: IconButtonSx, ...props }) => {
  return (
    <IconButton
      sx={{
        border: theme => `1px solid ${theme.palette.divider}`,
        ...IconButtonSx,
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};

export default GIconButton;
