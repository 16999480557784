import React from 'react';
import { SxProps, Theme } from '@mui/material';
import TraitRarity from 'constants/trait-rarity.json';
import { AttributeType } from 'models/@types';
import { CharacterGender } from 'models/Filters';
import Div from 'components/global/div';
import KeyValueOutput from 'components/global/key-value-output';

interface IProps extends AttributeType {
  rootSx?: SxProps<Theme>;
  gender?: CharacterGender;
}

const NftAttribute: React.FunctionComponent<IProps> = ({
  text: { title, value },
  rootSx,
  gender,
}) => {
  const attrRarity =
    // @ts-ignore
    gender === 'Male' ? TraitRarity.male[value.text] : TraitRarity.female[value.text];
  const rarity = attrRarity ? (attrRarity[title] ? `${attrRarity[title]}%` : undefined) : undefined;

  return (
    <Div sx={rootSx} className="d-flex align-items-center">
      <KeyValueOutput
        title={title}
        subTitle={rarity}
        value={value}
        titleProps={{
          sx: { color: theme => theme.palette.text.primary, textTransform: 'capitalize' },
          fontWeight: 'bold',
        }}
        valueTextProps={{
          sx: { color: theme => theme.palette.text.secondary, textTransform: 'capitalize' },
          variant: 'subtitle2',
        }}
      />
    </Div>
  );
};

export default NftAttribute;
