const LandRoadData: string[] = [
  '-29, -39',
  '-30, -38',
  '-29, -38',
  '-30, -37',
  '-29, -37',
  '-30, -36',
  '-29, -36',
  '-29, -35',
  '-28, -35',
  '-29, -34',
  '-28, -34',
  '-27, -34',
  '-28, -33',
  '-27, -33',
  '-26, -33',
  '-27, -32',
  '-26, -32',
  '-25, -32',
  '-26, -31',
  '-25, -31',
  '-24, -31',
  '-23, -31',
  '-25, -30',
  '-24, -30',
  '-23, -30',
  '-22, -30',
  '-23, -29',
  '-22, -29',
  '-21, -29',
  '-20, -29',
  '-24, -28',
  '-23, -28',
  '-21, -28',
  '-20, -28',
  '-19, -28',
  '-24, -27',
  '-23, -27',
  '-20, -27',
  '-19, -27',
  '-18, -27',
  '-17, -27',
  '-24, -26',
  '-23, -26',
  '-19, -26',
  '-18, -26',
  '-17, -26',
  '-16, -26',
  '-15, -26',
  '-14, -26',
  '-24, -25',
  '-23, -25',
  '-16, -25',
  '-15, -25',
  '-14, -25',
  '-13, -25',
  '-24, -24',
  '-23, -24',
  '-15, -24',
  '-14, -24',
  '-13, -24',
  '-24, -23',
  '-23, -23',
  '-13, -23',
  '-12, -23',
  '-11, -23',
  '-10, -23',
  '-9, -23',
  '-24, -22',
  '-23, -22',
  '-11, -22',
  '-10, -22',
  '-9, -22',
  '-8, -22',
  '-7, -22',
  '-6, -22',
  '-24, -21',
  '-23, -21',
  '-7, -21',
  '-6, -21',
  '-5, -21',
  '-24, -20',
  '-23, -20',
  '-6, -20',
  '-5, -20',
  '-4, -20',
  '-24, -19',
  '-23, -19',
  '-5, -19',
  '-4, -19',
  '-3, -19',
  '-2, -19',
  '-1, -19',
  '-24, -18',
  '-23, -18',
  '-3, -18',
  '-2, -18',
  '-1, -18',
  '1, -18',
  '2, -18',
  '-24, -17',
  '-23, -17',
  '1, -17',
  '2, -17',
  '-24, -16',
  '-23, -16',
  '2, -16',
  '3, -16',
  '-24, -15',
  '-23, -15',
  '2, -15',
  '3, -15',
  '-24, -14',
  '-23, -14',
  '3, -14',
  '4, -14',
  '-24, -13',
  '-23, -13',
  '3, -13',
  '4, -13',
  '-23, -12',
  '3, -12',
  '4, -12',
  '-23, -11',
  '4, -11',
  '5, -11',
  '-23, -10',
  '4, -10',
  '5, -10',
  '6, -10',
  '7, -10',
  '8, -10',
  '-23, -9',
  '7, -9',
  '8, -9',
  '9, -9',
  '10, -9',
  '-23, -8',
  '11, -8',
  '-44, -7',
  '-43, -7',
  '-42, -7',
  '-41, -7',
  '-40, -7',
  '-24, -7',
  '-23, -7',
  '11, -7',
  '12, -7',
  '13, -7',
  '-41, -6',
  '-40, -6',
  '-39, -6',
  '-24, -6',
  '-23, -6',
  '12, -6',
  '13, -6',
  '14, -6',
  '-39, -5',
  '-38, -5',
  '-37, -5',
  '-27, -5',
  '-26, -5',
  '-25, -5',
  '-24, -5',
  '13, -5',
  '14, -5',
  '15, -5',
  '-39, -4',
  '-38, -4',
  '-37, -4',
  '-36, -4',
  '-35, -4',
  '-34, -4',
  '-33, -4',
  '-32, -4',
  '-31, -4',
  '-30, -4',
  '-29, -4',
  '-28, -4',
  '-27, -4',
  '-26, -4',
  '-25, -4',
  '-24, -4',
  '14, -4',
  '15, -4',
  '14, -3',
  '15, -3',
  '15, -2',
  '15, -1',
  '15, 1',
  '16, 1',
  '15, 2',
  '16, 2',
  '17, 2',
  '15, 3',
  '16, 3',
  '17, 3',
  '18, 3',
  '19, 3',
  '16, 4',
  '17, 4',
  '18, 4',
  '19, 4',
  '20, 4',
  '15, 5',
  '16, 5',
  '17, 5',
  '19, 5',
  '20, 5',
  '21, 5',
  '14, 6',
  '15, 6',
  '16, 6',
  '20, 6',
  '21, 6',
  '22, 6',
  '23, 6',
  '11, 7',
  '12, 7',
  '13, 7',
  '14, 7',
  '15, 7',
  '22, 7',
  '23, 7',
  '24, 7',
  '25, 7',
  '26, 7',
  '27, 7',
  '28, 7',
  '29, 7',
  '11, 8',
  '12, 8',
  '13, 8',
  '29, 8',
  '30, 8',
  '31, 8',
  '32, 8',
  '33, 8',
  '34, 8',
  '35, 8',
  '44, 8',
  '45, 8',
  '46, 8',
  '47, 8',
  '35, 9',
  '36, 9',
  '37, 9',
  '38, 9',
  '39, 9',
  '40, 9',
  '41, 9',
  '42, 9',
  '43, 9',
  '44, 9',
  '1, 13',
  '8, 13',
  '9, 13',
  '1, 14',
  '2, 14',
  '8, 14',
  '9, 14',
  '1, 15',
  '2, 15',
  '3, 15',
  '8, 15',
  '9, 15',
  '2, 16',
  '3, 16',
  '8, 16',
  '9, 16',
  '2, 17',
  '3, 17',
  '7, 17',
  '8, 17',
  '9, 17',
  '2, 18',
  '3, 18',
  '5, 18',
  '6, 18',
  '7, 18',
  '8, 18',
  '2, 19',
  '3, 19',
  '4, 19',
  '5, 19',
  '6, 19',
  '7, 19',
  '2, 20',
  '3, 20',
  '4, 20',
  '1, 21',
  '2, 21',
  '3, 21',
  '-1, 22',
  '1, 22',
  '2, 22',
  '-1, 23',
  '1, 23',
  '-1, 24',
  '1, 24',
  '-1, 25',
  '1, 25',
  '-2, 26',
  '-1, 26',
  '-2, 27',
  '-1, 27',
  '-3, 28',
  '-2, 28',
  '-1, 28',
  '-3, 29',
  '-2, 29',
  '-3, 30',
  '-2, 30',
  '-3, 31',
  '-2, 31',
  '-3, 32',
  '-2, 32',
  '-3, 33',
  '-17, 34',
  '-16, 34',
  '-15, 34',
  '-3, 34',
  '-18, 35',
  '-17, 35',
  '-16, 35',
  '-15, 35',
  '-14, 35',
  '-4, 35',
  '-3, 35',
  '-19, 36',
  '-18, 36',
  '-17, 36',
  '-15, 36',
  '-14, 36',
  '-13, 36',
  '-4, 36',
  '-3, 36',
  '4, 36',
  '5, 36',
  '6, 36',
  '7, 36',
  '8, 36',
  '9, 36',
  '-19, 37',
  '-18, 37',
  '-14, 37',
  '-13, 37',
  '-12, 37',
  '-11, 37',
  '-10, 37',
  '-9, 37',
  '-8, 37',
  '-7, 37',
  '-6, 37',
  '-5, 37',
  '-4, 37',
  '-3, 37',
  '-2, 37',
  '-1, 37',
  '1, 37',
  '2, 37',
  '3, 37',
  '4, 37',
  '5, 37',
  '6, 37',
  '7, 37',
  '8, 37',
  '9, 37',
  '10, 37',
  '-19, 38',
  '-18, 38',
  '9, 38',
  '10, 38',
  '-20, 39',
  '-19, 39',
  '10, 39',
  '11, 39',
  '-21, 40',
  '-20, 40',
  '-19, 40',
  '10, 40',
  '11, 40',
  '12, 40',
  '13, 40',
  '14, 40',
  '15, 40',
  '16, 40',
  '17, 40',
  '-21, 41',
  '-20, 41',
  '-23, 42',
  '-22, 42',
  '-21, 42',
  '-24, 43',
  '-23, 43',
  '-22, 43',
  '-24, 44',
  '-23, 44',
];

export default LandRoadData;
