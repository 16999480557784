import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Checkbox, FormControlLabel } from '@mui/material';
import { setAppLoading } from 'store/app/actions';
import useAuth from 'hooks/useAuth';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import { emailLoginService, resendVerificationService } from 'services/User';
import EmailVerification from 'pages/signup/components/email-verification';
import PasswordInput from 'components/common/password-input';
import CMPButton from 'components/global/button';
import CMPInput from 'components/global/input';
import { AuthLayoutClasses } from 'components/layouts/auth-layout';
import ForgotPassword from '../forgot-password';

interface IFormState {
  email: string;
  emailError: boolean;
  password: string;
  passwordError: boolean;
  remember: boolean;
  disable: boolean;
  type: 'text' | 'password';
}

const LoginForm: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [forgotPassword, setForgotPassword] = useState(false);
  const { setToken, authoredSuccessfully } = useAuth();
  const [emailVerification, setEmailVerification] = useState(false);

  const [state, setState] = useState<IFormState>({
    disable: true,
    email: '',
    emailError: false,
    password: '',
    passwordError: false,
    remember: true,
    type: 'password',
  });

  const onEmailChange = (val: string) => {
    setState(prevState => ({
      ...prevState,
      email: val,
      disable: prevState.password === '' || val === '',
    }));
  };

  const onPasswordChange = (val: string) => {
    setState(prevState => ({
      ...prevState,
      password: val,
      disable: prevState.email === '' || val === '',
    }));
  };

  const onCheckRemember = (event: React.SyntheticEvent, checked: boolean) => {
    setState(prevState => ({
      ...prevState,
      remember: checked,
    }));
  };

  const handleModalClose = () => setEmailVerification(false);

  const submitLogin = async (event: any) => {
    event.preventDefault();
    const { email, password } = state;
    const invalidEmail = email === '' || !Utils.emailValidation(email);
    const invalidPassword = password.length < 8;

    if (invalidEmail || invalidPassword) {
      setState(prevState => ({
        ...prevState,
        passwordError: invalidPassword,
        emailError: invalidEmail,
      }));
      return;
    }

    dispatch(setAppLoading(true));
    const response = await emailLoginService({ email: state.email, password: state.password });
    if (response && typeof response === 'string') {
      setToken(response);
      await authoredSuccessfully('LOGIN');
      dispatch(setAppLoading(false));
      return;
    } else if (response === 403) {
      const resend = await resendVerificationService(state.email);
      if (resend) {
        setEmailVerification(true);
      }
    }
    dispatch(setAppLoading(false));
  };

  return (
    <>
      <form className={AuthLayoutClasses.form} onSubmit={submitLogin}>
        <CMPInput
          filled
          fullWidth
          autoComplete="off"
          placeholder={Strings.email}
          value={state.email}
          onChanged={onEmailChange}
          defaultVariant={false}
          hasError={state.emailError}
        />
        <PasswordInput
          password={state.password}
          onChanged={onPasswordChange}
          defaultVariant={false}
          error={state.passwordError}
        />
        <div className="d-flex align-items-center justify-content-between w-100 mt-2 px-1">
          <FormControlLabel
            onChange={onCheckRemember}
            control={<Checkbox checked={state.remember} sx={{ borderRadius: 8 }} />}
            label={Strings.rememberMe}
            componentsProps={{ typography: { fontWeight: 'bold' } }}
          />
          <CMPButton variant="text" onClick={() => setForgotPassword(true)}>
            {Strings.forgotPass}
          </CMPButton>
        </div>
        <CMPButton
          className="w-100 mt-5"
          type="submit"
          sx={{ height: 48 }}
          disabled={state.disable}
        >
          {Strings.login}
        </CMPButton>
        <CMPButton
          variant="text"
          className="mt-3 px-4"
          sx={{ textTransform: 'none' }}
          component={Link}
          to="/signup"
        >
          {Strings.signupText}
        </CMPButton>
      </form>
      <ForgotPassword open={forgotPassword} onClose={() => setForgotPassword(false)} />
      <EmailVerification
        open={emailVerification}
        onClose={handleModalClose}
        email={state.email}
        onVerified={submitLogin}
      />
    </>
  );
};
export default LoginForm;
