import BigNumber from 'bignumber.js';
import store from 'store';
import { setAppLoading } from 'store/app/actions';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import { ContractAddress } from 'constants/ContractAddress';
import { approveToken, getAllowance, getHRC20Decimals } from 'services/ContractBasics';
import { CreateHRC20Contract, CreateMarketContract } from 'services/CreateContract';
import { ERC721Approved } from 'services/SellNFT';
import { showSnackbar } from 'components/global/snackbar';

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

const approveFlow = async (price: number): Promise<boolean> => {
  const decimal = await getHRC20Decimals();
  const decimalPrice = new BigNumber(price).times(new BigNumber(10).pow(decimal));
  const userAllowance = await getAllowance();
  if (userAllowance?.lt(decimalPrice.toString())) {
    return approveToken(decimalPrice.toString());
  }
  return true;
};

export type NFTBuyType = 'fixed-price' | 'auction';

export const NFTBuyService = async (
  type: NFTBuyType,
  price: number,
  saleId: number,
): Promise<boolean> => {
  const hrc20Contract = await CreateHRC20Contract();
  const marketContract = CreateMarketContract();
  if (!hrc20Contract || !marketContract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const canBuy = await approveFlow(price);
    if (canBuy) {
      const decimalPrice = Utils.addHRC20Decimal(price);
      if (type === 'auction') {
        const response = await marketContract.bidAuction(saleId, decimalPrice.toString());
        await response.wait();
        return !!response;
      }
      if (type === 'fixed-price') {
        const response = await marketContract.buyFixedPrice(saleId, decimalPrice.toString());
        await response.wait();
        return !!response;
      }
      store.dispatch(setAppLoading(false));
      return false;
    }
    store.dispatch(setAppLoading(false));
    return false;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
  }
  return false;
};

interface IMakeOfferArgs {
  address: string;
  tokenId: number;
  duration: number;
  minPrice: number;
}

export const makeERC721Offer = async (data: IMakeOfferArgs): Promise<boolean> => {
  const marketContract = CreateMarketContract();
  if (!marketContract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const canBuy = await approveFlow(data.minPrice);
    if (canBuy) {
      const decimalPrice = Utils.addHRC20Decimal(data.minPrice);
      const response = await marketContract.createERC721Offer(
        data.address,
        data.tokenId,
        ContractAddress.HRC20,
        data.duration,
        decimalPrice.toString(),
      );
      await response.wait();
      if (response) {
        return true;
      }
      store.dispatch(setAppLoading(false));
      showSnackbar({
        message: Strings.error,
        severity: 'error',
      });
      return false;
    }
    return false;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
    return false;
  }
};

export const CancelOffer = async (saleId: number): Promise<boolean> => {
  const marketContract = CreateMarketContract();
  if (!marketContract) {
    return false;
  }
  try {
    store.dispatch(setAppLoading(true));
    const result = await marketContract.cancelOffer(saleId);
    await result.wait();
    store.dispatch(setAppLoading(false));
    return !!result;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
    return false;
  }
};

export const acceptOffer = async (
  tokenId: number,
  saleId: number,
  address: string,
): Promise<boolean> => {
  const marketContract = CreateMarketContract();
  if (!marketContract) {
    return false;
  }
  try {
    const approved = await ERC721Approved(tokenId, address);
    if (!approved) {
      return false;
    }
    const response = await marketContract.acceptOffer(saleId);
    await response.wait();
    return !!response;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    store.dispatch(setAppLoading(false));
    return false;
  }
};
