import React from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
} from '@mui/material';
import ColorPalette from 'utils/ColorPalette';
import Logo from 'components/icons/Logo';
import CMPSidebar, { classes } from './_styles';
import sidebarItems from './data';

const SideBar: React.FunctionComponent = () => {
  const location = useLocation();
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  return (
    <CMPSidebar>
      {matches && (
        <div className={classes.logo}>
          <Logo />
        </div>
      )}
      <List disablePadding className={clsx({ 'd-flex justify-content-center w-100': !matches })}>
        {sidebarItems.map(({ icon: Icon, ...item }) => (
          <ListItemButton
            key={item.path}
            className="d-flex flex-column align-items-center justify-content-center"
            component={item.type === 'INTERNAL' ? Link : 'a'}
            to={item.type === 'INTERNAL' ? item.path : undefined}
            href={item.type === 'EXTERNAL' ? item.path : undefined}
            target={item.type === 'EXTERNAL' ? '_blank' : undefined}
            sx={{
              width: matches ? 84 : `${100 / sidebarItems.length}%`,
              maxWidth: 84,
              height: 76,
              backgroundColor:
                location.pathname === item.path ? 'rgba(255, 255, 255, 0.2)' : undefined,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0 }}>
              <Icon />
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              classes={{ root: 'mb-0' }}
              primaryTypographyProps={{
                variant: 'body2',
                sx: { color: ColorPalette.white },
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </CMPSidebar>
  );
};
export default SideBar;
