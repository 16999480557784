import React from 'react';

const NotCheckedIcon: React.FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g
        id="Rectangle_3656"
        data-name="Rectangle 3656"
        fill="none"
        stroke="#a199ad"
        strokeWidth="1.5"
      >
        <rect width="12" height="12" rx="4" stroke="none" />
        <rect x="0.75" y="0.75" width="10.5" height="10.5" rx="3.25" fill="none" />
      </g>
    </svg>
  );
};
export default NotCheckedIcon;
