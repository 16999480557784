import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setRarities } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import Strings from 'utils/Strings';
import { rarityItems } from 'constants/filters/Items';
import { ItemRarity } from 'models/Filters';
import FilterCard from 'components/common/filter-card';
import FilterContainer from 'components/common/filter-container';
import StarIcon from 'components/icons/main/StarIcon';

const Rarity: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { rarities } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);

  const onSelectHandler = (key: ItemRarity) => {
    const index = rarities.findIndex(item => item === key);
    if (index === -1) {
      rarities.push(key);
      dispatch(setRarities(rarities));
    } else {
      rarities.splice(index, 1);
      dispatch(setRarities(rarities));
    }
  };

  return (
    <FilterContainer
      className="mt-3"
      title={Strings.rarity}
      count={rarities.length > 0 ? rarities.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
          {rarityItems.map(region => (
            <FilterCard
              w100
              key={region.type}
              text={region.type}
              onClick={() => onSelectHandler(region.type)}
              active={rarities.includes(region.type)}
              endIcon={
                <div className="d-flex align-items-center">
                  {Array.from(Array(region.count).keys()).map(item => (
                    <StarIcon key={item} color={region.color} />
                  ))}
                </div>
              }
            />
          ))}
        </div>
      }
    />
  );
};
export default Rarity;
