import { NFTType } from 'models/@types';

export interface ICategory {
  address: string;
  category: string;
  name: string;
  type: NFTType;
}

export class Category {
  address: string;
  category: string;
  name: string;
  type: NFTType;
  constructor(props: ICategory) {
    this.type = props.type;
    this.category = props.category;
    this.name = props.name;
    this.address = props.address;
  }
}
