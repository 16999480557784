import React from 'react';
import { CustomIconProps, setColor } from '..';

const StatisticsIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16">
      <g transform="translate(-364 -252)">
        <g transform="translate(364 252)">
          <path id="Vector" d="M0,0H16V16H0Z" fill="none" opacity="0" />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M13.833,1H.5A.5.5,0,0,1,0,.5.5.5,0,0,1,.5,0H13.833a.5.5,0,0,1,.5.5A.5.5,0,0,1,13.833,1Z"
            transform="translate(0.833 0.833)"
            fill={iconColor}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M6.281,3.56a.5.5,0,0,1-.447.273.509.509,0,0,1-.22-.053L3.168,2.56.721,3.78a.509.509,0,0,1-.22.053A.5.5,0,0,1,.055,3.56a.493.493,0,0,1,.227-.673L2.668,1.693V0h1V1.693L6.055,2.887A.493.493,0,0,1,6.281,3.56Z"
            transform="translate(4.832 11.333)"
            fill={iconColor}
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0V8.067A1.727,1.727,0,0,0,1.933,10h8.133A1.727,1.727,0,0,0,12,8.067V0ZM9.32,4.387,7.22,6.14a.838.838,0,0,1-.673.187.826.826,0,0,1-.573-.4l-.7-1.167L3.32,6.387A.483.483,0,0,1,3,6.5a.506.506,0,0,1-.387-.18.5.5,0,0,1,.067-.707L4.78,3.86a.838.838,0,0,1,.673-.187.826.826,0,0,1,.573.4l.7,1.167L8.68,3.613a.5.5,0,0,1,.707.067A.506.506,0,0,1,9.32,4.387Z"
            transform="translate(2 1.333)"
            fill={iconColor}
          />
        </g>
      </g>
    </svg>
  );
};

export default StatisticsIcon;
