import {
  AppActionTypes,
  AppStoreState,
  SET_APP_LOADING,
  SET_APP_THEME,
  SET_CATEGORIES,
  SET_SNACKBAR,
} from './types';

const initialState: AppStoreState = {
  appLoading: false,
  appTheme: 'DARK',
  snackbar: null,
  categories: [],
};

function appReducer(state = initialState, action: AppActionTypes): AppStoreState {
  switch (action.type) {
    case SET_APP_LOADING:
      return { ...state, appLoading: action.payload };
    case SET_APP_THEME:
      return { ...state, appTheme: action.payload };
    case SET_SNACKBAR:
      return { ...state, snackbar: action.payload };
    case SET_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
}
export default appReducer;
