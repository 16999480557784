import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IUserPageParams } from 'routes/params';
import { clearFilters } from 'store/marketplace/actions';
import useTitle from 'hooks/useTitle';
import ProfileContainer from './_style';
import ProfileItems from './components/profile-items';
import ProfileSummary from './components/profile-summary';

interface IProps {}

const ProfilePage: React.FunctionComponent<IProps> = () => {
  const { wallet } = useParams<IUserPageParams>();
  useTitle({ title: wallet ? 'User' : 'Profile' });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearFilters(true, false));
    return () => {
      dispatch(clearFilters(true, true));
    };
  }, []);

  return (
    <ProfileContainer>
      <ProfileSummary wallet={wallet} />
      <ProfileItems wallet={wallet} />
    </ProfileContainer>
  );
};

export default ProfilePage;
