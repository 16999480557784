import moment from 'moment';
import store from 'store';
import Utils from 'libs/Utils';
import { characterRaces, ICharacterRace } from 'constants/filters/Character';
import { IRegionFilter, regionItems } from 'constants/filters/Lands';
import { IAsset, NFTType } from 'models/@types';
import { NFTMetaData } from 'models/NFT';

export type INFTDetailsAttr = Record<string, string>;

export type NFTAction =
  | 'SALE'
  | 'CANCEL_SALE'
  | 'HAS_BIDDER'
  | 'BUY'
  | 'BID'
  | 'OFFER'
  | 'CANCEL_OFFER'
  | 'LOGIN_TO_BUY'
  | 'FINALIZE'
  | 'FINALIZE_AND_CANCEL'
  | 'BIDDER';

export interface IBuyerSeller {
  _id: string;
  name: string;
  wallet: string;
}

export interface IBidToken {
  address: string;
  decimals: number;
  name: string;
  symbol: string;
}

export interface INFTDetailsOwner {
  amount: number;
  wallet: string;
  name: string;
}

export interface INFDetailRarity {
  rank: number;
  categoryRank: number;
}

export interface INFTDetailSale {
  bidToken: IBidToken;
  saleId: number;
  isBundle: boolean;
  minPrice: string;
  price: string;
  status: string;
  startTime: string;
  endTime: string;
  type: string;
  buyer: IBuyerSeller;
  seller: IBuyerSeller;
}

export interface INFTDetails {
  _id: string;
  category: string;
  contract: string;
  subCategory: string;
  tokenId: number;
  type: string;
  attributes: INFTDetailsAttr;
  sale: INFTDetailSale | null;
  saleHistory: INFTDetailSale[];
  offers: INFTDetailSale[];
  metadata: NFTMetaData;
  owner: INFTDetailsOwner;
  userOffer?: INFTDetailSale;
  rarity: INFDetailRarity | null;
}

export class NFTDetails {
  id: string;
  category: string;
  contract: string;
  subCategory: string;
  tokenId: number;
  type: string;
  attributes: INFTDetailsAttr;
  sale: INFTDetailSale | null;
  saleHistory: INFTDetailSale[];
  offers: INFTDetailSale[];
  metadata: NFTMetaData;
  owner: INFTDetailsOwner;
  userOffer?: INFTDetailSale;
  rarity: INFDetailRarity | null;
  constructor(props: INFTDetails) {
    this.id = props._id || '';
    this.category = props.category || '';
    this.contract = props.contract || '';
    this.subCategory = props.subCategory || '';
    this.tokenId = props.tokenId || 0;
    this.type = props.type || '';
    this.attributes = props.attributes || {};
    this.sale = props.sale || null;
    this.saleHistory = props.saleHistory || [];
    this.offers = props.offers || [];
    this.metadata = props.metadata || { attributes: [], image: '', name: '' };
    this.userOffer = props.userOffer;
    this.rarity = props.rarity;
    this.owner = {
      amount: props.owner.amount,
      wallet: props.owner.wallet,
      name: props.owner.name || '--',
    } || {
      name: '',
      wallet: '',
      amount: 0,
    };
  }

  get isForSale(): boolean {
    return this.sale !== null;
  }

  get numeralPrice(): string {
    if (this.sale) {
      return Utils.removeRC20Decimal(Number(this.sale.price || this.sale.minPrice)).toString();
    }
    return '0';
  }
  get priceStr(): string {
    if (!this.sale) {
      return '0';
    }
    if (this.sale.price) {
      return Utils.addComma(Utils.removeRC20Decimal(+this.sale.price));
    }
    if (this.sale.minPrice) {
      return Utils.addComma(Utils.removeRC20Decimal(+this.sale.minPrice));
    }
    return '0.00';
  }

  get usdtPrice(): string {
    if (this.sale) {
      const { bidTokenPrice } = store.getState().Constants;
      const price = Utils.removeRC20Decimal(Number(this.sale.price || this.sale.minPrice));
      return Utils.addComma(price * bidTokenPrice);
    }
    return '0.00';
  }

  get asset(): IAsset {
    if (this.metadata.image.startsWith('')) {
      const URL = this.metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
      return { src: URL, alt: this.metadata.name };
    }
    return { src: this.metadata.image, alt: this.metadata.name };
  }

  get race(): ICharacterRace | undefined {
    return characterRaces.find(item => item.type.toLowerCase().includes(this.subCategory));
  }

  get region(): IRegionFilter | undefined {
    return regionItems.find(
      item => item.text.toLowerCase() === this.attributes.REGION.toLowerCase(),
    );
  }

  get ownerAddress(): string {
    return Utils.minimizeAddress(this.owner.wallet);
  }

  get startDate(): string {
    if (this.sale) {
      return moment(this.sale.startTime).startOf('seconds').fromNow();
    }
    return '';
  }

  get endDate(): string {
    if (this.sale) {
      return moment(this.sale.endTime).startOf('seconds').fromNow();
    }
    return '';
  }

  get durationPercent(): number {
    if (!this.sale) {
      return 0;
    }
    const now = new Date().getTime();
    const start = new Date(this.sale.startTime).getTime();
    const end = new Date(this.sale.endTime).getTime();
    const duration = end - start;
    const progress = start < now ? now - start : 0;
    if (progress > duration) {
      return 100;
    }
    return Math.floor((progress / duration) * 100);
  }

  get nftType(): NFTType | null {
    const { categories } = store.getState().App;
    const current = categories.find(
      item => item.address.toLowerCase() === this.contract.toLowerCase(),
    );
    if (current) {
      return current.type;
    }
    return null;
  }

  get actionType(): NFTAction {
    const { user } = store.getState().User;
    if (!user) {
      return 'LOGIN_TO_BUY';
    }
    if (this.sale === null) {
      if (user.wallet === this.owner.wallet) {
        return 'SALE';
      }
      if (this.userOffer) {
        return 'CANCEL_OFFER';
      }
      return 'OFFER';
    }
    if (this.sale.status === 'completed' && this.sale.type === 'auction') {
      if (this.sale.seller.wallet === user.wallet && !this.sale.buyer.wallet) {
        return 'CANCEL_SALE';
      } else {
        return 'FINALIZE';
      }
      // else if (this.sale.seller.wallet === user.wallet) {
      //   return 'FINALIZE_AND_CANCEL';
      // } else if (this.sale.buyer.wallet === user.wallet) {
      //   return 'FINALIZE';
      // }
    }
    if (this.sale.buyer.wallet === user.wallet) {
      return 'BIDDER';
    }
    if (this.sale.seller.wallet === user.wallet) {
      if (this.sale.type === 'fixed-price') {
        return 'CANCEL_SALE';
      } else if (this.sale.type === 'auction' && !this.sale.buyer.wallet) {
        return 'CANCEL_SALE';
      } else {
        return 'HAS_BIDDER';
      }
    }
    return 'BUY';
  }
  get actionText(): string {
    switch (this.actionType) {
      case 'BUY':
        return this.sale?.type === 'auction' ? 'Place a Bid' : 'Buy Now';
      case 'OFFER':
        return 'Make an Offer';
      case 'SALE':
        return 'Sell Now';
      case 'CANCEL_OFFER':
        return 'Cancel Offer';
      case 'CANCEL_SALE':
        return 'Cancel Sale';
      case 'LOGIN_TO_BUY':
        return 'Login to Buy';
      case 'BIDDER':
        return 'You are the bidder';
      case 'FINALIZE':
        return 'Finalize';
      case 'HAS_BIDDER':
        return `Highest bid is $${this.numeralPrice}`;
      default:
        return 'Login to Buy';
    }
  }
}
