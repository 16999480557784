import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setSaleTypes } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import Strings from 'utils/Strings';
import { saleTypesItems, SaleType } from 'constants/filters/Sale';
import FilterCard from 'components/common/filter-card';
import FilterContainer from 'components/common/filter-container';

const SaleTypeFilter: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { saleTypes } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);

  const onSelectHandler = (key: SaleType) => {
    const index = saleTypes.findIndex(item => item === key);
    if (index === -1) {
      saleTypes.push(key);
      dispatch(setSaleTypes(saleTypes));
    } else {
      saleTypes.splice(index, 1);
      dispatch(setSaleTypes(saleTypes));
    }
  };

  return (
    <FilterContainer
      className="mt-3"
      title={Strings.saleTypes}
      count={saleTypes.length > 0 ? saleTypes.length : undefined}
      body={
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
          {saleTypesItems.map(sale => (
            <FilterCard
              key={sale.type}
              text={sale.text}
              onClick={() => onSelectHandler(sale.type)}
              active={saleTypes.includes(sale.type)}
            />
          ))}
        </div>
      }
    />
  );
};
export default SaleTypeFilter;
