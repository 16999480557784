import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { loadUserDataAction, setUserToken } from 'store/user/actions';
import Strings from 'utils/Strings';
import { UserToken } from 'models/User';
import { showSnackbar } from 'components/global/snackbar';

type AuthType = 'LOGIN' | 'SIGN_UP';

export interface IUseAuth {
  isLoggedIn: () => boolean;
  setToken: (tokenData: string) => void;
  successLoggedIn: () => void;
  successSignedUp: () => void;
  authoredSuccessfully: (authType?: AuthType) => Promise<void>;
}

function useAuth(): IUseAuth {
  const userToken = useSelector<RootState, UserToken | null>(s => s.User.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setToken = async (token: string): Promise<void> => {
    const tokenModel = new UserToken({ token });
    dispatch(setUserToken(tokenModel));
    tokenModel.setToLocalStorage(token);
  };

  const successLoggedIn = () => {
    showSnackbar({
      message: Strings.successLogin,
      severity: 'success',
    });
  };

  const successSignedUp = () => {
    showSnackbar({
      message: Strings.successSignup,
      severity: 'success',
    });
  };

  const authoredSuccessfully = async (authType?: AuthType): Promise<void> => {
    if (authType === 'LOGIN') {
      successLoggedIn();
      await dispatch(loadUserDataAction());
      navigate('/profile');
    } else if (authType === 'SIGN_UP') {
      successSignedUp();
      navigate('/login');
    }
  };

  const isLoggedIn = () => {
    return !!userToken && !!userToken.token;
  };

  return {
    authoredSuccessfully,
    isLoggedIn,
    setToken,
    successLoggedIn,
    successSignedUp,
  };
}

export default useAuth;
