import { createTheme, Theme } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
// @ts-ignore
import poppins from 'assets/fonts/poppins.ttf';
import { AppTheme } from 'models/@types';
import { setColor } from './variables';

const Poppins = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${poppins}) format('truetype')`,
};

interface IThemeArguments {
  appTheme?: AppTheme;
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    divider: string;
    inputBg: string;
    gray: string;
    filterBg: string;
    filterCard: string;
    purple: string;
    iconBg: string;
    sideMenu: string;
  }
  interface PaletteOptions {
    divider?: string;
    inputBg?: string;
    gray: string;
    filterBg: string;
    filterCard: string;
    purple: string;
    iconBg: string;
    sideMenu: string;
  }
}

const theme = ({ appTheme = 'LIGHT' }: IThemeArguments): Theme => {
  const themeMode = appTheme === 'DARK' ? 'dark' : 'light';
  return createTheme({
    direction: 'ltr',
    typography: {
      fontFamily: 'Poppins',
    },
    palette: {
      mode: themeMode,
      divider: setColor('divider'),
      inputBg: setColor('inputBg'),
      gray: setColor('gray'),
      filterBg: setColor('filterBg'),
      filterCard: setColor('filterCard'),
      purple: setColor('purple'),
      iconBg: setColor('iconBg'),
      sideMenu: setColor('sideMenu'),
      background: {
        default: setColor('background'),
      },
      primary: {
        main: '#0BB4E7',
        light: '#0BB4E7',
        dark: '#0BB4E7',
      },
      error: {
        main: '#FF6B6B',
        contrastText: 'white',
      },
      text: {
        secondary: '#A199AD',
        primary: setColor('textPrimary'),
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@font-face': Poppins,
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
          },
          containedPrimary: {
            color: 'white',
            ':disabled': {
              color: 'rgba(255, 255, 255, 0.3)',
              backgroundColor: 'rgba(11, 180, 231, 0.3)',
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variant: 'body2',
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            border: `1px solid ${setColor('divider')}`,
            boxShadow: 'none',
          },
        },
      },
    },
  });
};
export default theme;
