import React from 'react';
import { Typography } from '@mui/material';
import store from 'store';
import { setAppLoading } from 'store/app/actions';
import Strings from 'utils/Strings';
import { NFTBuyService } from 'services/BuyNFT';
import CMPButton from 'components/global/button';
import { showSnackbar } from 'components/global/snackbar';

interface IFixedPriceBuyProps {
  saleId: number;
  onSuccess?: (e?: any) => void;
  currentValue?: string;
  bidToken?: string;
}

const FixedPriceBuy: React.FunctionComponent<IFixedPriceBuyProps> = ({
  saleId,
  onSuccess,
  currentValue,
  bidToken,
}) => {
  const onAuctionBuy = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (currentValue) {
      const response = await NFTBuyService('fixed-price', Number(currentValue), saleId);
      if (response && onSuccess) {
        const timout = setTimeout(() => {
          onSuccess(event);
          showSnackbar({
            message: Strings.buySuccess,
            severity: 'success',
          });
          store.dispatch(setAppLoading(false));
          clearTimeout(timout);
        }, 5000);
      }
    }
  };

  return (
    <div className="mt-3">
      <Typography variant="body1" fontWeight="bold" textAlign="center" component="p">
        Are you sure you want to buy <br /> this item for{' '}
        <Typography fontWeight="bold" variant="h6" component="span">
          {currentValue} {bidToken}
        </Typography>
        ?
      </Typography>
      {/*<LabeledInput value={value} onChange={setValue} type="number" label="Buy Amount" />*/}
      <CMPButton
        disabled={!currentValue || currentValue === ''}
        sx={{ height: 56, width: '100%', mt: 4, fontSize: '18px' }}
        onClick={onAuctionBuy}
      >
        {Strings.confirmBuy}
      </CMPButton>
    </div>
  );
};
export default FixedPriceBuy;
