import React from 'react';

interface IMetaMaskIconProps {
  size?: string;
}

const MetaMaskIcon: React.FunctionComponent<IMetaMaskIconProps> = ({ size = '37' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 37 35">
      <g id="MetaMask" transform="translate(-31.3 -35)">
        <path
          id="Path_42"
          data-name="Path 42"
          d="M188.647,35.5,174.6,46.129l2.6-6.271Z"
          transform="translate(-122.64 0)"
          fill="#e2761b"
          stroke="#e2761b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g id="Group_21" data-name="Group 21" transform="translate(32.096 35.5)">
          <path
            id="Path_43"
            data-name="Path 43"
            d="M44.4,35.5,58.334,46.229l-2.471-6.371Z"
            transform="translate(-42.918 -35.5)"
            fill="#e4761b"
            stroke="#e4761b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_44"
            data-name="Path 44"
            d="M215.541,206.8l-3.741,5.839,8,2.244,2.3-7.953Z"
            transform="translate(-186.685 -182.163)"
            fill="#e4761b"
            stroke="#e4761b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_45"
            data-name="Path 45"
            d="M33.9,206.929l2.287,7.953,8-2.244L40.451,206.8Z"
            transform="translate(-33.9 -182.163)"
            fill="#e4761b"
            stroke="#e4761b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_46"
            data-name="Path 46"
            d="M90.031,109,87.8,112.442l7.948.36-.282-8.7Z"
            transform="translate(-80.191 -94.234)"
            fill="#e4761b"
            stroke="#e4761b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_47"
            data-name="Path 47"
            d="M180.289,108.405l-5.506-5.005-.184,8.8,7.934-.36Z"
            transform="translate(-154.736 -93.634)"
            fill="#e4761b"
            stroke="#e4761b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_48"
            data-name="Path 48"
            d="M106.8,213.752l4.772-2.373-4.122-3.279Z"
            transform="translate(-96.508 -183.276)"
            fill="#e4761b"
            stroke="#e4761b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M177.9,211.379l4.786,2.373-.664-5.652Z"
            transform="translate(-157.571 -183.276)"
            fill="#e4761b"
            stroke="#e4761b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="Group_22" data-name="Group 22" transform="translate(42.388 63.603)">
          <path
            id="Path_50"
            data-name="Path 50"
            d="M182.686,233.273,177.9,230.9l.381,3.179-.042,1.338Z"
            transform="translate(-167.862 -230.9)"
            fill="#d7c1b3"
            stroke="#d7c1b3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M106.8,233.273l4.447,2.143-.028-1.338.353-3.179Z"
            transform="translate(-106.8 -230.9)"
            fill="#d7c1b3"
            stroke="#d7c1b3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          id="Path_52"
          data-name="Path 52"
          d="M114.581,178.6l-3.981-1.194,2.809-1.309Z"
          transform="translate(-67.675 -120.378)"
          fill="#233447"
          stroke="#233447"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_53"
          data-name="Path 53"
          d="M179.7,178.6l1.172-2.5,2.824,1.309Z"
          transform="translate(-127.02 -120.378)"
          fill="#233447"
          stroke="#233447"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g id="Group_23" data-name="Group 23" transform="translate(38.647 53.708)">
          <path
            id="Path_54"
            data-name="Path 54"
            d="M84.041,212.639l.678-5.839-4.419.129Z"
            transform="translate(-80.3 -200.371)"
            fill="#cd6116"
            stroke="#cd6116"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_55"
            data-name="Path 55"
            d="M207,206.8l.678,5.839,3.741-5.71Z"
            transform="translate(-189.113 -200.371)"
            fill="#cd6116"
            stroke="#cd6116"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_56"
            data-name="Path 56"
            d="M182.534,162.1l-7.934.36.734,4.157,1.172-2.5,2.824,1.309Z"
            transform="translate(-161.287 -162.1)"
            fill="#cd6116"
            stroke="#cd6116"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_57"
            data-name="Path 57"
            d="M91.019,165.422l2.824-1.309,1.158,2.5.748-4.157L87.8,162.1Z"
            transform="translate(-86.741 -162.1)"
            fill="#cd6116"
            stroke="#cd6116"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="Group_24" data-name="Group 24" transform="translate(39.706 53.708)">
          <path
            id="Path_58"
            data-name="Path 58"
            d="M87.8,162.1l3.332,6.616-.113-3.294Z"
            transform="translate(-87.8 -162.1)"
            fill="#e4751f"
            stroke="#e4751f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_59"
            data-name="Path 59"
            d="M207.241,165.422l-.141,3.294,3.346-6.616Z"
            transform="translate(-190.258 -162.1)"
            fill="#e4751f"
            stroke="#e4751f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_60"
            data-name="Path 60"
            d="M139.548,164.6l-.748,4.157.932,4.9.212-6.458Z"
            transform="translate(-131.6 -164.24)"
            fill="#e4751f"
            stroke="#e4751f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M172.281,164.6l-.381,2.589.169,6.472.946-4.9Z"
            transform="translate(-160.027 -164.24)"
            fill="#e4751f"
            stroke="#e4751f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          id="Path_62"
          data-name="Path 62"
          d="M174.046,186.394l-.946,4.9.678.475,4.122-3.279.141-3.294Z"
          transform="translate(-121.352 -128.17)"
          fill="#f6851b"
          stroke="#f6851b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_63"
          data-name="Path 63"
          d="M110.6,185.2l.113,3.294,4.122,3.279.678-.475-.932-4.9Z"
          transform="translate(-67.675 -128.17)"
          fill="#f6851b"
          stroke="#f6851b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_64"
          data-name="Path 64"
          d="M117.176,249.543l.042-1.338-.353-.316h-5.322l-.325.316.028,1.338L106.8,247.4l1.553,1.294,3.148,2.229h5.407l3.162-2.229,1.553-1.294Z"
          transform="translate(-64.412 -181.424)"
          fill="#c0ad9e"
          stroke="#c0ad9e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_65"
          data-name="Path 65"
          d="M143.719,228.075l-.678-.475h-3.911l-.678.475-.353,3.179.325-.316h5.322l.353.316Z"
          transform="translate(-91.293 -164.471)"
          fill="#161616"
          stroke="#161616"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <g id="Group_25" data-name="Group 25" transform="translate(31.8 35.5)">
          <path
            id="Path_66"
            data-name="Path 66"
            d="M192.074,46.819l1.2-5.868L191.481,35.5,177.9,45.769l5.224,4.5,7.383,2.2,1.638-1.942-.706-.518,1.129-1.05-.875-.69,1.129-.877Z"
            transform="translate(-157.274 -35.5)"
            fill="#763d16"
            stroke="#763d16"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_67"
            data-name="Path 67"
            d="M31.8,40.951,33,46.819l-.762.575,1.129.877-.861.69,1.129,1.05-.706.518,1.624,1.942,7.384-2.2,5.224-4.5L33.579,35.5Z"
            transform="translate(-31.8 -35.5)"
            fill="#763d16"
            stroke="#763d16"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <path
          id="Path_68"
          data-name="Path 68"
          d="M215.585,140.4l-7.384-2.2,2.245,3.437-3.346,6.616,4.4-.058h6.565Z"
          transform="translate(-150.552 -87.929)"
          fill="#f6851b"
          stroke="#f6851b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_69"
          data-name="Path 69"
          d="M43.74,138.2l-7.384,2.2L33.9,148.2h6.551l4.391.058-3.332-6.616Z"
          transform="translate(-1.804 -87.929)"
          fill="#f6851b"
          stroke="#f6851b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_70"
          data-name="Path 70"
          d="M132.518,80.01l.466-8.3,2.146-5.911H125.6l2.118,5.911.494,8.3.169,2.618.014,6.443h3.911l.028-6.443Z"
          transform="translate(-80.558 -25.942)"
          fill="#f6851b"
          stroke="#f6851b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
};
export default MetaMaskIcon;
