import { LandsRegion } from 'models/Filters';

export interface IRegionFilter {
  color: string;
  text: LandsRegion;
}
// export const regionItems: IRegionFilter[] = [
//   { color: '#F4B63A', text: 'Elysian Fields' },
//   { color: '#AAB3BB', text: 'Mystic Alpines' },
//   { color: '#C2DF5C', text: 'Enchanted Forest' },
//   { color: '#2ECC71', text: 'Forest of Whimsy' },
// ];

export const regionItems: IRegionFilter[] = [
  { color: '#8BCD33', text: 'Elysian Fields' },
  { color: '#FFCE38', text: 'Mystic Alpines' },
  { color: '#EA5568', text: 'Enchanted Forest' },
  { color: '#BA7DFF', text: 'Forest of Whimsy' },
];
