import axios from 'axios';
import BigNumber from 'bignumber.js';
import { ThunkCustomAction, ThunkCustomDispatch } from 'store/index';
import { CreateMarketContract } from 'services/CreateContract';
import { LandViewMode } from 'models/@types';
import * as Types from './types';

export function setHRC20Decimal(newState: BigNumber): Types.ConstantsActionTypes {
  return {
    type: Types.SET_HRC20_DECIMAL,
    payload: newState,
  };
}

export function setLandView(newState: LandViewMode): Types.ConstantsActionTypes {
  return {
    type: Types.SET_LAND_VIEW,
    payload: newState,
  };
}

export function setBidTokenPrice(newState: number): Types.ConstantsActionTypes {
  return {
    type: Types.SET_BID_TOKEN_PRICE,
    payload: newState,
  };
}

export function setMarketFeePrice(newState: string): Types.ConstantsActionTypes {
  return {
    type: Types.SET_MARKET_FEE,
    payload: newState,
  };
}

export const getBidTokenAction = (): ThunkCustomAction<void> => {
  return async (dispatch: ThunkCustomDispatch): Promise<void> => {
    const contractAddress = '0x892d81221484f690c0a97d3dd18b9144a3ecdfb7';
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/simple/token_price/harmony-shard-0?contract_addresses=${contractAddress}&vs_currencies=usd`,
    );
    if (response.status === 200) {
      const price = response.data[contractAddress];
      dispatch(setBidTokenPrice(price.usd));
    }
  };
};

export const getMarketFeeAction = (): ThunkCustomAction<void> => {
  return async (dispatch: ThunkCustomDispatch): Promise<void> => {
    const marketContract = CreateMarketContract();
    if (!marketContract) return;
    try {
      const response = await marketContract.getMarketFeeConfig();
      if (response) {
        const fee = new BigNumber(response.toString()).toNumber();
        const finalFee = (fee / 100).toFixed(2);
        dispatch(setMarketFeePrice(finalFee));
      }
    } catch (err) {
      console.error(err);
    }
  };
};
