import { styled } from '@mui/material';
import Utils from 'libs/Utils';
import ColorPalette from 'utils/ColorPalette';

export const classes = {
  switch: 'switch',
  switchActive: 'switch-active',
  checker: 'checker',
  checkerActive: 'checker-active',
};

const Switch = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: 52,
  border: Utils.getAppTheme() === 'LIGHT' ? `1px solid ${theme.palette.divider}` : undefined,
  borderRadius: 18,
  padding: '0 16px',
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',
  backgroundColor: theme.palette.filterBg,
  [theme.breakpoints.up('md')]: {
    height: 68,
    borderRadius: 24,
    padding: '0 16px',
  },
  [`& .${classes.switch}`]: {
    outline: 'none',
    background: theme.palette.sideMenu,
    width: 56,
    height: 32,
    borderRadius: 16,
    position: 'relative',
    '&:before': {
      position: 'absolute',
      content: '""',
      height: 20,
      width: 20,
      background: theme.palette.gray,
      borderRadius: '50%',
      left: 6,
      bottom: 6,
      WebkitTransition: '0.2s',
      transition: '0.2s',
    },
  },
  [`& .${classes.switchActive}`]: {
    background: ColorPalette.activeBg,
    '&:before': {
      background: ColorPalette.active,
      WebkitTransform: 'translateX(24px)',
      MozTransform: 'translateX(24px)',
      transform: 'translateX(24px)',
    },
  },
}));
export default Switch;
