import { ComponentType } from 'react';
import moment from 'moment';
import store from 'store';
import { categories } from 'components/common/categories/_data';
import CharacterIcon from 'components/icons/categories/CharacterIcon';

export type ActivityType =
  | 'buy'
  | 'sell'
  | 'offer'
  | 'offer-received'
  | 'accept-offer'
  | 'cancel'
  | 'outbid'
  | 'complete'
  | 'cancel-offer';

export interface ICategoryActivity {
  catName: string;
  name: string;
  icon: ComponentType<any>;
}

export interface IActivity {
  contract: string;
  createdAt: string;
  saleId: number;
  tokenIds: number[];
  type: ActivityType;
  updatedAt: string;
  user: string;
  values: number[];
}

export class Activity {
  contract: string;
  createdAt: string;
  saleId: number;
  tokenIds: number[];
  type: ActivityType;
  updatedAt: string;
  user: string;
  values: number[];
  constructor(props: IActivity) {
    this.contract = props.contract;
    this.createdAt = props.createdAt;
    this.saleId = props.saleId;
    this.tokenIds = props.tokenIds;
    this.type = props.type;
    this.updatedAt = props.updatedAt;
    this.user = props.user;
    this.values = props.values;
  }

  get typeText(): string {
    switch (this.type) {
      case 'buy':
        return 'You have bought';
      case 'sell':
        return 'You have sold';
      case 'cancel':
        return 'You have cancelled sale of';
      case 'accept-offer':
        return 'You have accepted offer of';
      case 'cancel-offer':
        return 'You have cancelled offer of';
      case 'offer':
        return 'You have made an offer for';
      case 'offer-received':
        return 'You have received an offer for';
      case 'outbid':
        return 'You have been outbid on';
      case 'complete':
        return 'Your sale status was changed to completed for';
      default:
        return '--';
    }
  }

  get category(): ICategoryActivity {
    const { categories: all } = store.getState().App;
    const category = all.find(item => item.address.toLowerCase() === this.contract.toLowerCase());
    if (category) {
      const catItem = categories.find(item => item.key.toString() === category.category);
      if (catItem) {
        return {
          catName: catItem.key,
          name: category.name,
          icon: catItem.icon,
        };
      }
      return {
        catName: 'character',
        name: '--',
        icon: CharacterIcon,
      };
    }
    return {
      catName: 'character',
      name: '--',
      icon: CharacterIcon,
    };
  }

  get IDs(): string {
    return this.tokenIds.join(', ');
  }

  get time(): string {
    return moment(this.createdAt).format('MM/DD - HH:mm');
  }
}
