import React from 'react';
import { CustomIconProps, setColor } from '..';

const ArtifactsIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.188" height="16.5" viewBox="0 0 11.188 16.5">
      <g id="artifact" transform="translate(-172.564 -13.749)">
        <path
          id="Path_71"
          data-name="Path 71"
          d="M180.892,16.7l.608-1.871a.433.433,0,0,0-.051-.4.467.467,0,0,0-.338-.219,21.663,21.663,0,0,0-5.907,0,.466.466,0,0,0-.338.219.56.56,0,0,0-.051.4l.608,1.871c-1.063.337-3.055,1.349-2.515,5.345a19.893,19.893,0,0,0,2.329,7.267.561.561,0,0,0,.236.2,7.609,7.609,0,0,0,5.367,0,.4.4,0,0,0,.236-.2,20.05,20.05,0,0,0,2.329-7.267c.54-4-1.451-5.007-2.515-5.345Zm1.553,5.227c-.051.32-.1.624-.152.927l-1.232.927-1.181-.894a.482.482,0,0,0-.591,0l-1.181.894-1.181-.894a.482.482,0,0,0-.591,0l-1.181.894-1.131-.86c-.067-.32-.118-.658-.169-.995a9.134,9.134,0,0,1-.084-1.1l.895.674a.482.482,0,0,0,.591,0l1.266-.961,1.3.961a.482.482,0,0,0,.591,0l1.266-.961,1.266.961a.457.457,0,0,0,.574,0l1-.742A10.073,10.073,0,0,1,182.444,21.923Zm-4.287-6.946a22.306,22.306,0,0,1,2.228.118l-.489,1.5h-3.477l-.489-1.5c.506-.051,1.3-.118,2.228-.118Zm-2.5,2.681a1.885,1.885,0,0,1,.523-.084h3.932a1.742,1.742,0,0,1,.591.1,2.516,2.516,0,0,1,1.688,1.939l-1.165.877-1.266-.961a.482.482,0,0,0-.591,0l-1.266.961-1.266-.961a.482.482,0,0,0-.591,0l-1.266.961-1.08-.826a2.5,2.5,0,0,1,1.755-2.006Zm4.675,11.01a7.092,7.092,0,0,1-2.177.371,7.2,7.2,0,0,1-2.177-.371,19.05,19.05,0,0,1-1.637-4.3l.523.388a.482.482,0,0,0,.591,0l1.181-.894,1.181.894a.457.457,0,0,0,.574,0l1.181-.894,1.181.894a.482.482,0,0,0,.591,0l.624-.472a18.829,18.829,0,0,1-1.637,4.384Z"
          fill={iconColor}
          stroke={iconColor}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};
export default ArtifactsIcon;
