import React from 'react';
import { useSelector } from 'react-redux';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { RootState } from 'store';
import Strings from 'utils/Strings';
import { KeyValueOutputType } from 'models/@types';
import { NFTDetails } from 'models/NFTDetails';
import NFTOffers from 'pages/nft/components/nft-offers';
import Circle from 'components/global/circle';
import Div from 'components/global/div';
import GDivider from 'components/global/divider';
import CMPImage from 'components/global/image';
import KeyValueOutput from 'components/global/key-value-output';
import Square from 'components/global/square';
import LogoIcon from 'components/icons/LogoIcon';
import LocationIcon from 'components/icons/main/LocationIcon';
import FragmentWrapper from 'components/template/fragment-wrapper';
import ActionButton from '../action-button';
import InformationContainer, { classes } from './_styles';
import InformationDescription from './components/information-description';
import InformationOwner from './components/information-owner';

interface IProp {
  data?: NFTDetails | null;
  onRefresh?: () => void;
}

const Information: React.FunctionComponent<IProp> = ({ data, onRefresh }) => {
  const userWallet = useSelector<RootState, string | undefined>(state => state.User.user?.wallet);
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'), {
    noSsr: true,
  });

  if (!data) {
    return null;
  }

  const informationBody: KeyValueOutputType[] = [];
  switch (data.category) {
    case 'character':
      informationBody.push(
        { title: 'id', value: { text: `#${data.tokenId.toString()}` ?? '' } },
        {
          title: 'race',
          value: {
            text: data.race?.type ?? '',
            icon: <Circle color={data.race?.color ?? ''} />,
          },
        },
      );
      break;
    case 'land':
      informationBody.push(
        {
          title: 'location',
          value: {
            icon: <LocationIcon color={data.region?.color ?? undefined} />,
            text: data.attributes.COORDINATES ?? '',
          },
        },
        {
          title: 'region',
          value: {
            text: data.region?.text ?? '',
            icon: <Square color={data.region?.color ?? ''} />,
          },
        },
      );
      break;
    default:
    // nothing
  }

  return (
    <InformationContainer>
      <CMPImage asset={data.asset} className={classes.image} />
      <Div
        sx={{
          ml: matches ? 0 : 4,
          display: matches ? 'flex' : 'block',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: 450,
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h6"
          marginTop={matches ? 2 : 0}
          width="100%"
          sx={{ '@media screen and (min-width: 600px)': { fontSize: '28px' } }}
        >
          {data.metadata?.name ?? ''}
        </Typography>
        <Div
          className={classes.information}
          sx={{ my: matches ? 1 : 4, maxWidth: 444, width: '100%' }}
        >
          {informationBody.map((item, index, arr) => (
            <FragmentWrapper key={item.title}>
              <KeyValueOutput {...item} />
              {arr.length - 1 !== index ? <GDivider sx={{ mx: 4 }} /> : null}
            </FragmentWrapper>
          ))}
        </Div>
        {data && data.rarity && (
          <Div
            className={classes.information}
            sx={{ my: matches ? 1 : 4, maxWidth: 444, width: '100%' }}
          >
            <FragmentWrapper>
              <KeyValueOutput
                rootSx={{ minWidth: '51px' }}
                title={Strings.rank}
                value={{ text: data.rarity.rank.toString(), icon: undefined }}
              />
              <GDivider sx={{ mx: 4 }} />
              <KeyValueOutput
                rootSx={{ minWidth: '51px' }}
                title={Strings.genderRank}
                value={{ text: data.rarity.categoryRank.toString(), icon: undefined }}
              />
            </FragmentWrapper>
          </Div>
        )}
        <InformationOwner contract={data.owner.wallet} name={data.owner.name} />
        {data.sale && (
          <InformationDescription
            description={{
              icon: <LogoIcon />,
              value: data.priceStr,
              price: data.usdtPrice,
            }}
            progressBar={{
              highest: data.startDate,
              lowest: data.endDate,
              progressPercentage: data.durationPercent,
            }}
          />
        )}
        <div className="d-flex align-items-center w-100">
          <ActionButton data={data} matches={matches} onRefresh={onRefresh} />
          {data.offers.length > 0 && data.owner.wallet === userWallet && (
            <NFTOffers
              offers={data.offers}
              tokenId={data.tokenId}
              address={data.contract}
              matches={matches}
              onRefresh={onRefresh}
            />
          )}
        </div>
      </Div>
    </InformationContainer>
  );
};

export default Information;
