import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setForSale } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import Strings from 'utils/Strings';
import CMPSwitch from 'components/global/switch';

const ForSale: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { forSale } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);

  const onChange = () => {
    dispatch(setForSale(!forSale));
  };

  return <CMPSwitch label={Strings.forSale} checked={forSale} onChange={onChange} />;
};
export default ForSale;
