import { ethers } from 'ethers';
import store from 'store';
import UserApi from 'apis/user';
import { IVerifyWalletReq } from 'apis/user/_';
import { loadUserDataAction } from 'store/user/actions';
import Strings from 'utils/Strings';
import { harmonyChainID } from 'constants/Statics';
import { showSnackbar } from 'components/global/snackbar';

export const nonceWalletService = async (wallet: string): Promise<string | null> => {
  const response = await UserApi.nonce({ wallet });
  if (response.success && response.data) {
    return response.data.nonce.toString();
  }
  return null;
};

export const verifyWalletService = async (data: IVerifyWalletReq): Promise<boolean> => {
  const response = await UserApi.verifyWallet(data);
  if (response.success) {
    return true;
  } else if (response.message) {
    showSnackbar({
      message: response.message,
      severity: 'error',
    });
    return false;
  }
  return false;
};

export const getMetamaskWallet = async (): Promise<string | undefined> => {
  // @ts-ignore
  const { ethereum } = window;
  if (!ethereum) {
    showSnackbar({
      message: Strings.installMetamask,
      severity: 'warning',
    });
    return;
  }
  if (parseInt(ethereum.chainId) !== harmonyChainID) {
    showSnackbar({
      message: Strings.harmony,
      severity: 'warning',
    });
    return;
  }
  const accounts = (await ethereum.request({ method: 'eth_requestAccounts' })) as string[];
  return accounts[0];
};

export const createSign = async (): Promise<IVerifyWalletReq | undefined> => {
  try {
    // @ts-ignore
    const { ethereum } = window;
    const wallet = await getMetamaskWallet();
    if (!wallet) return;

    const message = await nonceWalletService(wallet);
    if (!message) {
      showSnackbar({
        message: Strings.error,
        severity: 'error',
      });
      return;
    }

    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);

    return {
      wallet,
      message,
      signature,
    };
  } catch (e) {
    console.log(e);
    return;
  }
};

export const connectMetamaskService = async (): Promise<void> => {
  const sign = await createSign();
  if (!sign) {
    return;
  }
  try {
    const response = await verifyWalletService(sign);
    if (response) {
      showSnackbar({
        message: Strings.walletVerified,
        severity: 'success',
      });
      store.dispatch(loadUserDataAction() as any);
    }
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    return;
  }
};

export const loginWithMetamask = async (): Promise<string | undefined> => {
  try {
    const sign = await createSign();
    if (!sign) {
      return;
    }

    const response = await UserApi.loginWithMetamask(sign);

    if (response.success) {
      return response.data.token as string;
    }

    return;
  } catch (err) {
    // @ts-ignore
    const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;
    showSnackbar({
      message: errorMessage,
      severity: 'error',
    });
    return;
  }
};
