import AES from 'aes-js';
import BigNumber from 'bignumber.js';
import numeral from 'numeral';
import store, { RootState } from 'store';
import { setColor } from 'theme/variables';
import { setAppTheme } from 'store/app/actions';
import Strings from 'utils/Strings';
import TraitRarity from 'constants/trait-rarity.json';
import { AJVError, AppTheme } from 'models/@types';
import AppConfig, { IAppConfig } from 'models/env/AppConfig';
import { CharacterGender } from 'models/Filters';
import { showSnackbar } from 'components/global/snackbar';
// import { ethers } from 'ethers';

const setAppConfig = ({ theme }: IAppConfig) => {
  new AppConfig({ theme }).writeToLocalStorage();
};

const Utils = {
  getAppTheme: (): AppTheme => {
    return store.getState().App.appTheme;
  },
  getAttributeRarity: (gender: CharacterGender, key: string, value: string): string | undefined => {
    // @ts-ignore
    const attrRarity = gender === 'Male' ? TraitRarity.male[key] : TraitRarity.female[key];

    const rarity = attrRarity
      ? attrRarity[value]
        ? `${attrRarity[value]}%`
        : undefined
      : undefined;

    return rarity;
  },
  changeTheme: (theme: AppTheme): void => {
    if ((store.getState() as RootState).App.appTheme === theme) {
      return;
    }
    store.dispatch(setAppTheme(theme));
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute('content', setColor('background'));
    setAppConfig({ theme });
  },
  emailValidation: (val: string): boolean => {
    if (val) {
      const email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!!val && email.test(val)) {
        return true;
      }
    }
    return false;
  },
  parseAJV: (error: AJVError): string => {
    const field = error.field.substring(1, error.field.length);
    return `${error.errorCode} Error. Field ${field} ${error.message}`;
  },
  encryptAES: (text: string): string => {
    const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    const textBytes = AES.utils.utf8.toBytes(text);
    const AESCounter = new AES.ModeOfOperation.ctr(key, new AES.Counter(5));
    const encryptedBytes = AESCounter.encrypt(textBytes);
    return AES.utils.hex.fromBytes(encryptedBytes);
  },
  decryptAES: (encryptedHexText: string): Promise<string> => {
    const key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    return new Promise((resolve, reject) => {
      try {
        const encryptedBytes = AES.utils.hex.toBytes(encryptedHexText);
        const AESCounter = new AES.ModeOfOperation.ctr(key, new AES.Counter(5));
        const decryptedBytes = AESCounter.decrypt(encryptedBytes);
        const decrypted = AES.utils.utf8.fromBytes(decryptedBytes);
        resolve(decrypted);
      } catch (err) {
        reject();
      }
    });
  },
  minimizeAddress: (address: string): string => {
    return `${address.substring(0, 10)}...${address.substring(
      address.length - 10,
      address.length,
    )}`;
  },
  copyToClipboard: (code: string): void => {
    navigator.clipboard.writeText(code).then(() => {
      showSnackbar({
        message: Strings.copied,
        severity: 'success',
      });
    });
  },
  addHRC20Decimal: (price: number): BigNumber => {
    const { hrc20Decimal } = store.getState().Constants;
    return new BigNumber(price).times(new BigNumber(10).pow(hrc20Decimal));
  },
  removeRC20Decimal: (price: number): number => {
    const { hrc20Decimal } = store.getState().Constants;
    return new BigNumber(price).div(new BigNumber(10).pow(hrc20Decimal)).toNumber();
  },
  addComma: (value: number, noPrecision = false): string => {
    if (noPrecision) {
      return numeral(value).format('0,0');
    }
    return numeral(value).format('0,0.00');
  },
};
export default Utils;
