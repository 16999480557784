import React from 'react';

const SalesIcon: React.FunctionComponent = () => {
  return (
    <svg
      id="wallet-check"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <path id="Vector" d="M0,0H48V48H0Z" fill="none" opacity="0" />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M8,0A8,8,0,0,0,0,8a7.842,7.842,0,0,0,1.16,4.12,7.969,7.969,0,0,0,13.68,0A7.842,7.842,0,0,0,16,8,8,8,0,0,0,8,0Zm3.94,7.34L7.68,11.28a1.526,1.526,0,0,1-1.02.4,1.484,1.484,0,0,1-1.06-.44L3.62,9.26A1.5,1.5,0,0,1,5.74,7.14l.96.96L9.9,5.14a1.5,1.5,0,1,1,2.04,2.2Z"
        transform="translate(2 30)"
        fill="#2ecc71"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M23.12,10.1a9.146,9.146,0,0,0-1.5-.1h-20A10.644,10.644,0,0,0,0,10.12,6.086,6.086,0,0,1,1.16,8.56l6.5-6.52a7.05,7.05,0,0,1,9.92,0l3.5,3.54A6.748,6.748,0,0,1,23.12,10.1Z"
        transform="translate(12.38 4)"
        fill="#2ecc71"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M40,10V20c0,6-4,10-10,10H11.26a7.186,7.186,0,0,0,1.58-1.88A7.842,7.842,0,0,0,14,24,7.993,7.993,0,0,0,0,18.72V10C0,4.56,3.28.76,8.38.12A10.644,10.644,0,0,1,10,0H30a9.146,9.146,0,0,1,1.5.1C36.66.7,40,4.52,40,10Z"
        transform="translate(4 14)"
        fill="#2ecc71"
        opacity="0.4"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M10,0H4A4,4,0,0,0,4,8h6"
        transform="translate(34 25)"
        fill="#2ecc71"
      />
    </svg>
  );
};
export default SalesIcon;
