import { ITabItem } from 'components/common/tab-view';
import DashboardIcon from 'components/icons/header/DashboardIcon';
import MarketPlaceIcon from 'components/icons/header/MarketPlace';

export type INavbarLink = 'dashboard' | 'marketplace';

export const navbarLinks: ITabItem<INavbarLink>[] = [
  {
    key: 'dashboard',
    icon: DashboardIcon,
  },
  {
    key: 'marketplace',
    icon: MarketPlaceIcon,
  },
];
