import Pagination, { IPagination, PAGINATION_SIZE } from 'models/request/Pagination';
import { IQuery } from 'models/request/Query';

export interface INFTListReq extends IPagination {
  query?: IQuery[];
}

export class NFTListReq extends Pagination {
  query: IQuery[];
  constructor({
    pageSize = PAGINATION_SIZE,
    pageNumber,
    query = [{ key: 'category', value: 'character' }],
  }: INFTListReq) {
    super({ pageNumber, pageSize });
    this.query = query;
  }
  queries(): IQuery[] {
    return [
      { key: 'pageSize', value: this.pageSize.toString() },
      { key: 'pageNumber', value: this.pageNumber.toString() },
      ...this.query,
    ];
  }
}

export interface ISingleNFTReq {
  tokenId: string;
  contract: string;
}
