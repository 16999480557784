import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { NFT } from 'models/NFT';
import Card from 'components/common/card';
import Circle from 'components/global/circle';
import LogoIcon from 'components/icons/LogoIcon';
import LocationIcon from 'components/icons/main/LocationIcon';
import { classes } from '../../_styles';
import ActionButton from './ActionButton';

interface INFTGridViewProps {
  items: NFT[];
  centerAlign?: boolean;
  onRefresh?: () => void;
}

const NFTGridView: React.FunctionComponent<INFTGridViewProps> = ({
  items,
  centerAlign,
  onRefresh,
}) => {
  return (
    <Grid container spacing={2}>
      {items.map((nft, index) => (
        <Grid
          key={`${nft.contract}-${nft.tokenId}`}
          item
          xs={12}
          sm={6}
          lg={4}
          xl={3}
          className={
            centerAlign
              ? 'd-flex justify-content-center'
              : index % 2 === 0
              ? classes.odd
              : classes.even
          }
        >
          <Card
            // @ts-ignore
            component={Link}
            to={`/${nft.category}/${nft.contract}/${nft.tokenId}`}
            asset={nft.asset}
            title={nft.metadata.name}
            rank={nft.rarity?.rank}
            body={
              nft.category === 'character'
                ? nft.race
                  ? [{ icon: <Circle color={nft.race.color} />, title: nft.race.type }]
                  : undefined
                : nft.coordinate
                ? [
                    {
                      icon: <LocationIcon color={nft.region?.color ?? undefined} />,
                      title: nft.coordinate,
                    },
                  ]
                : undefined
            }
            description={
              nft.sale
                ? { icon: <LogoIcon />, value: nft.priceStr, price: nft.usdtPrice }
                : undefined
            }
            action={<ActionButton nft={nft} onRefresh={onRefresh} />}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export default NFTGridView;
