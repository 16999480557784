import React from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import TabView from 'components/common/tab-view';
import { INavbarLink, navbarLinks } from '../data';

interface IProps {
  desktopView?: boolean;
}

const Links: React.FunctionComponent<IProps> = ({ desktopView }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onNavigate = (path: string) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const activeTab =
    location.pathname === '/dashboard'
      ? 'dashboard'
      : location.pathname === '/'
      ? 'marketplace'
      : undefined;

  return (
    <TabView<INavbarLink>
      w100={false}
      buttonStyle={{ minHeight: 48 }}
      className={clsx({ 'mb-4 mt-0': !desktopView })}
      items={navbarLinks}
      activeTab={activeTab}
      onTabChange={tab => onNavigate(tab === 'dashboard' ? `/${tab}` : '/')}
    />
  );
};
export default Links;
