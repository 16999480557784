import { ComponentType } from 'react';
import { NFTCategory } from 'models/@types';
import { CustomIconProps } from 'components/icons';
import CharacterIcon from 'components/icons/categories/CharacterIcon';
import ItemIcon from 'components/icons/categories/ItemIcon';
import LandIcon from 'components/icons/categories/LandIcon';
import PetIcon from 'components/icons/categories/PetIcon';
import ResourceIcon from 'components/icons/categories/ResourceIcon';
import StructureIcon from 'components/icons/categories/StructureIcon';

interface ICategoryItem {
  key: NFTCategory;
  icon: ComponentType<CustomIconProps>;
  text: string;
  width: number;
}

export const categories: ICategoryItem[] = [
  { key: 'character', icon: CharacterIcon, text: 'Characters', width: 134 },
  { key: 'companion', icon: PetIcon, text: 'Companions', width: 100 },
  { key: 'land', icon: LandIcon, text: 'Lands', width: 100 },
  { key: 'item', icon: ItemIcon, text: 'Items', width: 100 },
  { key: 'resource', icon: ResourceIcon, text: 'Resources', width: 134 },
  { key: 'structure', icon: StructureIcon, text: 'Structures', width: 134 },
];
