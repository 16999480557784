import React from 'react';
import PasswordInput from 'components/common/password-input';
import CMPInput from 'components/global/input';
// import { Theme, Typography, useMediaQuery } from '@mui/material';
// import clsx from 'clsx';

export interface IInputRowProps {
  title: string;
  inputValue: string;
  onInputChange: (value: string) => void;
  passwordType?: boolean;
  error?: boolean;
  defaultVariant?: boolean;
}

const InputRow: React.FunctionComponent<IInputRowProps> = ({
  title,
  inputValue,
  onInputChange,
  passwordType,
  error,
  defaultVariant,
}) => {
  // const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
  //   noSsr: true,
  // });

  return (
    <>
      {passwordType ? (
        <PasswordInput
          password={inputValue}
          onChanged={onInputChange}
          withMargin
          defaultVariant={defaultVariant}
          error={error}
        />
      ) : (
        <CMPInput
          filled
          fullWidth
          value={inputValue}
          defaultVariant={defaultVariant}
          onChanged={onInputChange}
          hasError={error}
          placeholder={title}
          className="mt-3"
        />
      )}
    </>
  );

  // return (
  //   <div
  //     className={clsx('d-flex mt-3 w-100', {
  //       'flex-column': !matches,
  //       'align-items-center': matches,
  //     })}
  //   >
  //     {/*<Typography*/}
  //     {/*  fontWeight={600}*/}
  //     {/*  className={clsx({ 'mb-2 ms-2': !matches })}*/}
  //     {/*  sx={{ minWidth: 80 }}*/}
  //     {/*>*/}
  //     {/*  {title}*/}
  //     {/*</Typography>*/}
  //   </div>
  // );
};
export default InputRow;
