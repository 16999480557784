import moment from 'moment';
import store from 'store';
import Utils from 'libs/Utils';
import { characterRaces, ICharacterRace } from 'constants/filters/Character';
import { NFT, NFTInterface } from 'models/NFT';

type BidToken = {
  address: string;
  decimals: number;
  name: string;
  symbol: string;
};

export interface IDashboardItem {
  bidToken: BidToken;
  contractAddress: string;
  endTime: string;
  startTime: string;
  isBundle: boolean;
  nfts: NFTInterface;
  price: string;
  saleId: number;
  seller: string;
  statue: string;
  type: string;
  _id: string;
}

export class DashboardItem {
  bidToken: BidToken;
  contractAddress: string;
  endTime: string;
  startTime: string;
  isBundle: boolean;
  nfts: NFT;
  price: string;
  saleId: number;
  seller: string;
  statue: string;
  type: string;
  id: string;
  constructor(props: IDashboardItem) {
    this.bidToken = props.bidToken || null;
    this.contractAddress = props.contractAddress || '';
    this.endTime = props.endTime || '';
    this.startTime = props.startTime || '';
    this.isBundle = props.isBundle;
    this.nfts = new NFT(props.nfts) || null;
    this.price = props.price || '';
    this.saleId = props.saleId || 0;
    this.seller = props.seller || '';
    this.statue = props.statue || '';
    this.type = props.type || '';
    this.id = props._id || '';
  }

  get race(): ICharacterRace | undefined {
    return characterRaces.find(item => item.type.toLowerCase().includes(this.nfts.subCategory));
  }

  get startTimeNum(): number {
    if (this.startTime) {
      return new Date(this.startTime).getTime();
    }
    return 0;
  }

  get endTimeNum(): number {
    if (this.endTime) {
      return new Date(this.endTime).getTime();
    }
    return 0;
  }

  get startDate(): string {
    if (this.startTime) {
      return moment(this.startTime).startOf('seconds').fromNow();
    }
    return '';
  }

  get endDate(): string {
    if (this.endTime) {
      return moment(this.endTime).startOf('seconds').fromNow();
    }
    return '';
  }

  get DecimalsPrice(): string {
    if (this.price) {
      return Utils.removeRC20Decimal(+this.price).toFixed(2);
    }
    return '0.00';
  }
  get usdtPrice(): string {
    if (this.price) {
      const { bidTokenPrice } = store.getState().Constants;
      const price = Utils.removeRC20Decimal(Number(this.price));
      return (price * bidTokenPrice).toFixed(2);
    }
    return '0.00';
  }
}
