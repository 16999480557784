import moment from 'moment';
import store from 'store';
import Utils from 'libs/Utils';
import { characterRaces, ICharacterRace } from 'constants/filters/Character';
import { IRegionFilter, regionItems } from 'constants/filters/Lands';
import { IAsset } from 'models/@types';
import { INFDetailRarity, NFTAction, IBidToken } from 'models/NFTDetails';

type AttributeType = Record<string, string>;

export interface NFTMetaDataAttr {
  trait_type: string;
  value: string;
}

export interface IBuySale {
  amount: string;
  time: string;
  wallet: string;
}

export interface NFTMetaData {
  name: string;
  image: string;
  attributes: NFTMetaDataAttr[];
}

export interface INFTSale {
  bidToken: IBidToken;
  saleId: number;
  contractAddress: string;
  duration: number;
  extensionDuration: number;
  isBundle: boolean;
  minPrice: string;
  price: string;
  sortPrice: number;
  status: string;
  startTime: string;
  endTime: string;
  type: string;
  buy: IBuySale;
  seller: string;
  tokenIds: number[];
}

export interface NFTInterface {
  category: string;
  contract: string;
  subCategory: string;
  owner: string;
  sale: INFTSale;
  rarity: INFDetailRarity | null;
  type: string;
  _id: string;
  tokenId: number;
  attributes: AttributeType;
  metadata: NFTMetaData;
}

export class NFT {
  category: string;
  contract: string;
  subCategory: string;
  owner: string;
  sale: INFTSale;
  rarity: INFDetailRarity | null;
  type: string;
  id: string;
  tokenId: number;
  attributes: AttributeType | null;
  metadata: NFTMetaData;
  constructor(props: NFTInterface) {
    this.category = props.category || '';
    this.contract = props.contract || '';
    this.subCategory = props.subCategory || '';
    this.owner = props.owner || '';
    this.type = props.type || '';
    this.id = props._id || '';
    this.tokenId = props.tokenId || 0;
    this.attributes = props.attributes || null;
    this.sale = props.sale || null;
    this.rarity = props.rarity || null;
    this.metadata = {
      name: props.metadata?.name || '',
      attributes: props.metadata?.attributes || [],
      image: props.metadata?.image || '',
    } || {
      attributes: [],
      image: '',
      name: '',
    };
  }

  get asset(): IAsset {
    if (this.metadata.image.startsWith('')) {
      const URL = this.metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
      return { src: URL, alt: this.metadata.name };
    }
    return { src: this.metadata.image, alt: this.metadata.name };
  }

  get race(): ICharacterRace | undefined {
    return characterRaces.find(item => item.type.toLowerCase().includes(this.subCategory));
  }

  get numeralPrice(): string {
    if (this.sale) {
      return Utils.removeRC20Decimal(Number(this.sale.price || this.sale.minPrice)).toString();
    }
    return '0';
  }

  get priceStr(): string {
    if (this.sale.price) {
      return Utils.addComma(Utils.removeRC20Decimal(+this.sale.price));
    }
    if (this.sale.minPrice) {
      return Utils.addComma(Utils.removeRC20Decimal(+this.sale.minPrice));
    }
    return '0.00';
  }

  get startDate(): string {
    if (this.sale && this.sale.startTime) {
      return moment(this.sale.startTime).startOf('seconds').fromNow();
    }
    return '';
  }

  get region(): IRegionFilter | undefined {
    if (this.attributes) {
      return regionItems.find(
        item => item.text.toLowerCase() === this.attributes?.REGION.toLowerCase(),
      );
    }
  }

  get coordinate(): string | undefined {
    if (this.attributes) {
      return this.attributes.COORDINATES || undefined;
    }
    return undefined;
  }

  get xCoordinate(): number {
    if (this.attributes) {
      const cArray = this.attributes.COORDINATES.split(', ');
      return +cArray[0] || 0;
    }
    return 0;
  }

  get yCoordinate(): number {
    if (this.attributes) {
      const cArray = this.attributes.COORDINATES.split(', ');
      return +cArray[1] || 0;
    }
    return 0;
  }

  get usdtPrice(): string {
    const { bidTokenPrice } = store.getState().Constants;
    const price = Utils.removeRC20Decimal(Number(this.sale.price || this.sale.minPrice));
    return Utils.addComma(price * bidTokenPrice);
  }

  get actionType(): NFTAction | 'OWNER' | 'ENDED' {
    const { user } = store.getState().User;
    if (!user) {
      return 'LOGIN_TO_BUY';
    }
    if (this.sale === null) {
      return 'OFFER';
    }
    if (new Date(this.sale.endTime).getTime() - new Date().getTime() < 0) {
      return 'ENDED';
    }
    if (this.owner === user.wallet) {
      return 'OWNER';
    }
    if (this.sale.buy && this.sale.buy.wallet === user.wallet) {
      return 'BIDDER';
    }
    if (this.sale.seller && this.sale.seller === user.wallet) {
      if (this.sale.type === 'fixed-price') {
        return 'CANCEL_SALE';
      } else if (this.sale.type === 'auction' && this.sale.buy && !this.sale.buy.wallet) {
        return 'CANCEL_SALE';
      } else {
        return 'HAS_BIDDER';
      }
    }
    if (this.sale.type === 'auction') {
      return 'BID';
    }
    return 'BUY';
  }
}
