import BigNumber from 'bignumber.js';
import { LandViewMode } from 'models/@types';

export interface ConstantsStoreState {
  hrc20Decimal: BigNumber;
  landView: LandViewMode;
  bidTokenPrice: number;
  marketFee: string;
}

export const SET_HRC20_DECIMAL = 'SET_HRC20_DECIMAL';
export const SET_LAND_VIEW = 'SET_LAND_VIEW';
export const SET_BID_TOKEN_PRICE = 'SET_BID_TOKEN_PRICE';
export const SET_MARKET_FEE = 'SET_MARKET_FEE';

interface SetHrc20Decimal {
  type: typeof SET_HRC20_DECIMAL;
  payload: BigNumber;
}

interface SetLandView {
  type: typeof SET_LAND_VIEW;
  payload: LandViewMode;
}

interface SetBidTokenPrice {
  type: typeof SET_BID_TOKEN_PRICE;
  payload: number;
}

interface SetMarketFee {
  type: typeof SET_MARKET_FEE;
  payload: string;
}

export type ConstantsActionTypes = SetHrc20Decimal | SetLandView | SetBidTokenPrice | SetMarketFee;
