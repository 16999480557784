import React, { useState } from 'react';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import useTitle from 'hooks/useTitle';
import { NFTCategory } from 'models/@types';
import Categories from 'components/common/categories';
import Div from 'components/global/div';
import DashboardContainer, { classes } from './_styles';
import DashboardListing from './components/listing';
import DashboardMobileView from './components/mobile-view';
import DashboardSales from './components/sales';
import Statistics from './components/statistics';

const DashboardPage: React.FunctionComponent = () => {
  useTitle({ title: 'Dashboard' });
  const [cat, setCat] = useState<NFTCategory>('character');
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('xl'), {
    noSsr: true,
  });
  const desktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  return (
    <>
      {desktop && <Categories activeItem={cat} onChangeCategory={setCat} />}
      <DashboardContainer>
        <Div className={classes.listCol}>
          {matches ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={6}>
                  <DashboardListing category={cat} />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <DashboardSales category={cat} />
                </Grid>
              </Grid>
            </>
          ) : (
            <DashboardMobileView category={cat} onSelectCat={setCat} />
          )}
        </Div>
        <Div className={classes.statisticsCol}>
          <Statistics />
        </Div>
      </DashboardContainer>
    </>
  );
};
export default DashboardPage;
