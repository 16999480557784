import Utils from 'libs/Utils';
import ColorPalette from 'utils/ColorPalette';

export interface CustomIconProps {
  color?: string;
  active?: boolean;
}

export const setColor = ({ color, active }: CustomIconProps): string => {
  if (color) {
    return color;
  }
  if (active) {
    return ColorPalette.primary;
  }
  const appTheme = Utils.getAppTheme();
  return appTheme === 'DARK' ? ColorPalette.iconDark : ColorPalette.iconLight;
};
