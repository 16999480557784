import React from 'react';
import { CustomIconProps, setColor } from '..';

const StarIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg
      style={{ marginInlineStart: 2 }}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 13.998"
    >
      <g
        id="vuesax_outline_star"
        data-name="vuesax/outline/star"
        transform="translate(-109.25 -189.32)"
      >
        <g id="star" transform="translate(109.25 189.32)">
          <path
            id="Vector"
            d="M10.689,14a2.674,2.674,0,0,1-1.342-.439L7.4,12.4a.921.921,0,0,0-.788,0l-1.954,1.16c-1.153.688-1.83.413-2.136.19s-.769-.793-.462-2.1l.462-2.013A.929.929,0,0,0,2.312,8.9L.7,7.277A1.788,1.788,0,0,1,.065,5.429,1.791,1.791,0,0,1,1.648,4.294l2.078-.347a.932.932,0,0,0,.56-.42L5.439,1.213C5.96.157,6.644,0,7,0S8.044.157,8.565,1.213L9.712,3.521a.965.965,0,0,0,.567.42l2.078.347a1.772,1.772,0,0,1,1.583,1.134,1.815,1.815,0,0,1-.632,1.849L11.692,8.9a.947.947,0,0,0-.208.728l.462,2.013c.3,1.311-.163,1.882-.462,2.1A1.327,1.327,0,0,1,10.689,14Z"
            transform="translate(0 0)"
            fill={iconColor}
          />
        </g>
      </g>
    </svg>
  );
};
export default StarIcon;
