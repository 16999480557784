import React from 'react';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import Strings from 'utils/Strings';
import { AttributeType } from 'models/@types';
import { CharacterGender } from 'models/Filters';
import { NftSectionContainer } from 'pages/nft/_styles';
import Div from 'components/global/div';
import NftAttribute from './attribute';

interface IProps {
  attributes: AttributeType[];
}

const NftAttributes: React.FunctionComponent<IProps> = ({ attributes }) => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  if (!attributes || attributes.length === 0) {
    return null;
  }

  const genderAttr = attributes.find(item => item.text.value.text === 'gender');
  const gender: CharacterGender = genderAttr ? (genderAttr.text.title as CharacterGender) : 'Male';

  return (
    <NftSectionContainer>
      <Typography variant="h5" fontWeight="bold">
        {Strings.attrAndRarities}
      </Typography>
      <Div className="d-flex flex-wrap" sx={{ width: '100%' }}>
        {attributes.map(attribute => (
          <NftAttribute
            key={`${attribute.text.title}-${attribute.text.value.text}`}
            rootSx={{
              width: matches ? '50%' : '25%',
              mt: 4,
              justifyContent: 'flex-start',
            }}
            gender={gender}
            {...attribute}
          />
        ))}
      </Div>
    </NftSectionContainer>
  );
};

export default NftAttributes;
