import store from 'store/index';
import { SaleType } from 'constants/filters/Sale';
import { Attributes, NFTCategory, SortType, ViewMode } from 'models/@types';
import {
  CharacterGender,
  CharacterRace,
  ItemRarity,
  ItemType,
  LandsRegion,
  StructureType,
} from 'models/Filters';
import { IQuery } from 'models/request/Query';
import * as Types from './types';

export function clearFilters(reset = false, resetForSale = false): Types.MarketplaceActionTypes {
  const { view, category } = store.getState().MarketPlace;
  return {
    type: Types.CLEAR_FILTERS,
    payload: {
      forSale: resetForSale,
      saleTypes: resetForSale ? ['fixed-price', 'auction'] : [],
      races: [],
      genders: [],
      attributes: [],
      resourceTypes: [],
      regions: [],
      rarities: [],
      types: [],
      structureTypes: [],
      queries: [],
      page: 1,
      sort: '+sale.price',
      view: reset ? 'grid' : view,
      category: reset ? 'character' : category,
      characterId: '',
    },
  };
}

export function setRaces(newState: CharacterRace[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_RACES,
    payload: newState,
  };
}

export function setAttributes(newState: Attributes[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_ATTRIBUTES,
    payload: newState,
  };
}

export function setResourceTypes(newState: Attributes[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_RESOURCE_TYPES,
    payload: newState,
  };
}

export function setForSale(newState: boolean): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_FOR_SALE,
    payload: newState,
  };
}

export function setSaleTypes(newState: SaleType[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_SALE_TYPES,
    payload: newState,
  };
}

export function setView(newState: ViewMode): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_VIEW,
    payload: newState,
  };
}

export function setCategory(newState: NFTCategory): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_CATEGORY,
    payload: newState,
  };
}

export function setGenders(newState: CharacterGender[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_GENDERS,
    payload: newState,
  };
}

export function setRegions(newState: LandsRegion[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_REGIONS,
    payload: newState,
  };
}

export function setRarities(newState: ItemRarity[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_RARITIES,
    payload: newState,
  };
}

export function setTypes(newState: ItemType[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_TYPES,
    payload: newState,
  };
}

export function setStructureTypes(newState: StructureType[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_STRUCTURE_TYPES,
    payload: newState,
  };
}

export function setQueries(newState: IQuery[]): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_QUERIES,
    payload: newState,
  };
}

export function setPage(newState: number): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_PAGE,
    payload: newState,
  };
}

export function setSort(newState: SortType): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_SORT,
    payload: newState,
  };
}

export function setCharacterId(newState: string): Types.MarketplaceActionTypes {
  return {
    type: Types.SET_CHARACTER_ID,
    payload: newState,
  };
}
