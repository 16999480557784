import { ComponentType } from 'react';
import { ITabItem } from 'components/common/tab-view';
import { CustomIconProps } from 'components/icons';
import ListingIcon from 'components/icons/dashboard/ListingIcon';
import SalesIcon from 'components/icons/dashboard/SalesIcon';
import VolumeIcon from 'components/icons/dashboard/VolumeIcon';

export type StatisticType = 'SALES' | 'LISTING' | 'VOLUME';
export interface IStatisticItem {
  type: StatisticType;
  icon: ComponentType<CustomIconProps>;
}

export const statisticItems: IStatisticItem[] = [
  { type: 'SALES', icon: SalesIcon },
  { type: 'LISTING', icon: ListingIcon },
  { type: 'VOLUME', icon: VolumeIcon },
];

export type StatisticTimeType = '24h' | '7d' | '30d';
export const statisticsTime: ITabItem<StatisticTimeType>[] = [
  { key: '24h' },
  { key: '7d' },
  { key: '30d' },
];

export type DashboardTab = 'LISTING' | 'SALES';
export const dashboardTabs: ITabItem<DashboardTab>[] = [{ key: 'LISTING' }, { key: 'SALES' }];
