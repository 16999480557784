import { DashboardAPI } from 'apis/dashboard';
import { IDashboardListReq } from 'apis/dashboard/_';
import { DashboardItem, IDashboardItem } from 'models/Dashboard';
import { Statistics } from 'models/Statistics';

export const getStatisticsService = async (): Promise<Statistics | undefined> => {
  try {
    const response = await DashboardAPI.getStatistics();

    if (response.success) {
      return new Statistics(response.data as Statistics);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getLatestListingService = async (req: IDashboardListReq): Promise<DashboardItem[]> => {
  try {
    const response = await DashboardAPI.getLatestListing(req);
    if (response.success) {
      const latestListing = response.data as IDashboardItem[];
      return latestListing.map(item => new DashboardItem(item));
    }
    return [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getLatestSalesService = async (req: IDashboardListReq): Promise<DashboardItem[]> => {
  try {
    const response = await DashboardAPI.getLatestSales(req);
    if (response.success) {
      const latestListing = response.data as IDashboardItem[];
      return latestListing.map(item => new DashboardItem(item));
    }
    return [];
  } catch (e) {
    console.log(e);
    return [];
  }
};
