import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useDispatch } from 'react-redux';
import { alpha } from '@mui/material';
import { getBidTokenAction, getMarketFeeAction } from 'store/constants/actions';
import { checkUserExistenceAction, loadUserDataAction } from 'store/user/actions';
import { getHRC20Decimals } from 'services/ContractBasics';
import { getCategoriesService } from 'services/MarketPlace';
import Div from 'components/global/div';
import MetamaskProvider from 'components/providers/Metamask';

const AppProvider: React.FunctionComponent = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const queryClient = new QueryClient();
  const dispatch = useDispatch();

  const coreFetching = async () => {
    try {
      const userExists = Boolean(await dispatch(checkUserExistenceAction() as any));
      await getCategoriesService();
      await getHRC20Decimals();
      await dispatch(getBidTokenAction());
      await dispatch(getMarketFeeAction());
      if (userExists) {
        await dispatch(loadUserDataAction());
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    coreFetching().finally(() => {});
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: theme => alpha(theme.palette.background.default, 0.9),
          zIndex: 100000,
        }}
      >
        <ThreeDots color="#A199AD" />
      </Div>
    );
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools />
      </QueryClientProvider>
      <MetamaskProvider />
    </>
  );
};
export default AppProvider;
