import { createURL, sendRequest } from 'apis/index';
import {
  EditProfileReq,
  EmailLoginReq,
  ForgotPasswordReq,
  IEditProfileReq,
  IEmailLoginReq,
  IForgotPasswordReq,
  INonceReq,
  IResetPasswordReq,
  ISignupReq,
  IVerifyEmailReq,
  IVerifyWalletReq,
  NonceReq,
  ResetPasswordReq,
  SignupReq,
  VerifyEmailReq,
  VerifyWalletReq,
} from 'apis/user/_';
import Response from 'models/request/Response';

const baseUrl = 'users';

const UserApi = {
  EmailLogin: (req: IEmailLoginReq): Promise<Response> => {
    return sendRequest({
      apiName: 'EMAIL_LOGIN',
      method: 'POST',
      url: createURL({
        baseUrl,
        args: ['email-login'],
      }),
      body: new EmailLoginReq(req).body(),
    });
  },
  Signup: (req: ISignupReq): Promise<Response> => {
    return sendRequest({
      apiName: 'SIGN_UP',
      method: 'POST',
      url: createURL({
        baseUrl,
        args: ['signup'],
      }),
      body: new SignupReq(req).body(),
    });
  },
  getUserProfile: (): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_USER_PROFILE',
      method: 'GET',
      auth: true,
      url: createURL({
        baseUrl,
        args: ['profile'],
      }),
    });
  },
  getUserActivities: (): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_USER_ACTIVITIES',
      method: 'GET',
      auth: true,
      url: createURL({
        baseUrl,
        args: ['activities'],
      }),
    });
  },
  getHasActivity: (): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_HAS_ACTIVITY',
      method: 'GET',
      auth: true,
      url: createURL({
        baseUrl,
        args: ['activities', 'has-activity'],
      }),
    });
  },
  EditProfileName: (req: IEditProfileReq): Promise<Response> => {
    return sendRequest({
      apiName: 'EDIT_PROFILE',
      method: 'PATCH',
      auth: true,
      url: createURL({
        baseUrl,
        args: ['profile'],
      }),
      body: new EditProfileReq(req).body(),
    });
  },
  nonce: (req: INonceReq): Promise<Response> => {
    return sendRequest({
      apiName: 'NONCE',
      method: 'POST',
      auth: true,
      url: createURL({
        baseUrl,
        args: ['nonce'],
      }),
      body: new NonceReq(req).body(),
    });
  },
  verifyWallet: (req: IVerifyWalletReq): Promise<Response> => {
    return sendRequest({
      apiName: 'VERIFY_WALLET',
      method: 'POST',
      auth: true,
      url: createURL({
        baseUrl,
        args: ['verify-wallet'],
      }),
      body: new VerifyWalletReq(req).body(),
    });
  },
  loginWithMetamask: (req: IVerifyWalletReq): Promise<Response> => {
    return sendRequest({
      apiName: 'LOGIN_WITH_METAMASK',
      method: 'POST',
      auth: true,
      url: createURL({
        baseUrl,
        args: ['login'],
      }),
      body: new VerifyWalletReq(req).body(),
    });
  },
  getUserInformation: (req: INonceReq): Promise<Response> => {
    return sendRequest({
      apiName: 'GET_USER_INFORMATION',
      method: 'GET',
      url: createURL({
        baseUrl,
        args: [req.wallet],
      }),
    });
  },
  forgotPassword: (req: IForgotPasswordReq): Promise<Response> => {
    return sendRequest({
      apiName: 'FORGOT_PASSWORD',
      method: 'POST',
      url: createURL({
        baseUrl,
        args: ['forgot-password'],
      }),
      body: new ForgotPasswordReq(req).body(),
    });
  },
  verifyEmail: (req: IVerifyEmailReq): Promise<Response> => {
    return sendRequest({
      apiName: 'VERIFY_EMAIL',
      method: 'POST',
      url: createURL({
        baseUrl,
        args: ['verify-email'],
      }),
      body: new VerifyEmailReq(req).body(),
    });
  },
  resendVerification: (req: IForgotPasswordReq): Promise<Response> => {
    return sendRequest({
      apiName: 'RESEND_VERIFICATION',
      method: 'POST',
      url: createURL({
        baseUrl,
        args: ['resend-verification'],
      }),
      body: new ForgotPasswordReq(req).body(),
    });
  },
  resetPassword: (req: IResetPasswordReq): Promise<Response> => {
    return sendRequest({
      apiName: 'RESET_PASSWORD',
      method: 'POST',
      url: createURL({
        baseUrl,
        args: ['reset-password'],
      }),
      body: new ResetPasswordReq(req).body(),
    });
  },
};

export default UserApi;
