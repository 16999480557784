import React from 'react';
import { useSelector } from 'react-redux';
import { Theme, useMediaQuery } from '@mui/material';
import { RootState } from 'store';
import { User } from 'models/User';
import { userInventories } from 'pages/profile/functions';
import ResourceCard from 'components/common/resource-card';

interface IProps {
  userData?: User;
}

const ProfileSummaryInventory: React.FunctionComponent<IProps> = ({ userData }) => {
  const user = useSelector<RootState, User | null>(state => state.User.user);

  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  // const inventories = getInventories(userData ?? user);
  const inventories = userInventories(userData ? userData.nfts : user ? user.nfts : undefined);

  return (
    <div className={`d-flex align-items-center ${matches ? 'flex-row' : 'flex-column'}`}>
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        {inventories?.map(({ icon: Icon, ...inventory }, index) => (
          <ResourceCard key={index} icon={<Icon />} count={inventory.amount} />
        ))}
      </div>
      {/* {matches ? <GDivider sx={{ mx: 2 }} /> : <Divider sx={{ my: 2 }} flexItem />} */}
      {/* <div className="d-flex align-items-center justify-content-center flex-wrap">
        {tokens ? (
          <>
            {tokens.map((token, index) => (
              <ResourceCard key={index} icon={token.icon} count={token.count} />
            ))}
          </>
        ) : null}
      </div> */}
    </div>
  );
};

export default ProfileSummaryInventory;
