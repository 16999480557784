import * as React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import Strings from 'utils/Strings';
import { CardBody, CardDescription } from 'models/@types';
import Div from 'components/global/div';
import FragmentWrapper from 'components/template/fragment-wrapper';
import GDivider from '../divider';

interface IProps {
  title?: string;
  rank?: number;
  body?: CardBody;
  description?: CardDescription;
  titleProps?: TypographyProps<'div'>;
  classic?: boolean;
  action?: React.ReactNode;
}

const CMPCardContent: React.FunctionComponent<IProps> = ({
  title,
  body,
  rank,
  description,
  titleProps,
  classic = false,
  action,
}) => {
  return (
    <>
      <Div sx={{ mb: classic ? 3 : 4 }} className="d-flex flex-column justify-content-between">
        {title && (
          <Typography
            noWrap
            sx={{ mb: '12px', maxWidth: '100%' }}
            variant="body1"
            component="span"
            {...titleProps}
          >
            {title}
          </Typography>
        )}
        {body && (
          <div className="d-flex align-items-center">
            {body.map(({ icon, title: bodyTitle }, index, arr) => (
              <FragmentWrapper key={`${bodyTitle}-${index}`}>
                {icon}
                <Typography sx={{ mx: 1 }} variant="body2" color={bodyTitle}>
                  {bodyTitle}
                </Typography>
                {arr.length - 1 !== index ? <GDivider sx={{ mx: 1 }} variant="fullWidth" /> : null}
              </FragmentWrapper>
            ))}
          </div>
        )}
        {rank && (
          <div className="d-flex align-items-center mt-3">
            <Typography color="textSecondary">{Strings.rank}:</Typography>
            <Typography className="ms-3">{rank.toString()}</Typography>
          </div>
        )}
      </Div>
      {description && (
        <div className="d-flex align-items-center flex-wrap">
          {description.icon}
          <Typography sx={{ ml: 0.5, mr: 1.5 }} variant="h6" color="text.primary">
            {description.value}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {`$${description.price}`}
          </Typography>
        </div>
      )}
      {action}
    </>
  );
};

export default CMPCardContent;
