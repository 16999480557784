import UserApi from 'apis/user';
import { IEditProfileReq } from 'apis/user/_';
import { setAppLoading } from 'store/app/actions';
import { ThunkCustomAction, ThunkCustomDispatch } from 'store/index';
import Strings from 'utils/Strings';
import { getUserMagicBalance, logoutService } from 'services/User';
import { IUser, User, UserToken } from 'models/User';
import { showSnackbar } from 'components/global/snackbar';
import * as Types from './types';

// @ts-ignore
const { ethereum } = window;

export const setUserToken = (newState: UserToken | null): Types.UserActionTypes => {
  return {
    type: Types.SET_USER_TOKEN,
    payload: newState,
  };
};

export const setBalance = (newState: string): Types.UserActionTypes => {
  return {
    type: Types.SET_BALANCE,
    payload: newState,
  };
};

export const setUser = (newState: User | null): Types.UserActionTypes => {
  return {
    type: Types.SET_USER,
    payload: newState,
  };
};

export const loadUserDataAction = (): ThunkCustomAction<void> => {
  return async (dispatch: ThunkCustomDispatch): Promise<void> => {
    const response = await UserApi.getUserProfile();
    if (response) {
      const user = new User(response.data as IUser);
      if (!ethereum || (user && user.wallet !== ethereum.selectedAddress)) {
        logoutService();
        showSnackbar({
          severity: 'warning',
          message: 'Please login with your verified wallet',
        });
      }
      dispatch(setUser(user));
    }
    const balance = await getUserMagicBalance();
    dispatch(setBalance(balance));
  };
};

export const editUserProfileAction = (data: IEditProfileReq): ThunkCustomAction<void> => {
  return async (dispatch: ThunkCustomDispatch): Promise<void> => {
    dispatch(setAppLoading(true));
    const response = await UserApi.EditProfileName(data);
    try {
      if (response.success) {
        dispatch(loadUserDataAction());
        showSnackbar({
          message: Strings.profileEdited,
          severity: 'success',
        });
        dispatch(setAppLoading(false));
        return;
      }
      dispatch(setAppLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(setAppLoading(false));
    }
  };
};

export const deleteUserDataAction = (): ThunkCustomAction<void> => {
  return async (dispatch: ThunkCustomDispatch): Promise<void> => {
    await dispatch(setUserToken(null));
    await dispatch(setUser(null));
    new UserToken().deleteFromLocalStorage();
  };
};

export const checkUserExistenceAction = (): ThunkCustomAction<void> => {
  return async (dispatch: ThunkCustomDispatch): Promise<boolean> => {
    const tokenModel = new UserToken();
    await tokenModel.readFromLocalStorage();
    if (tokenModel.isAllSet()) {
      dispatch(setUserToken(tokenModel));
      return true;
    }
    return false;
  };
};
