export type SaleType = 'auction' | 'fixed-price';

export interface ISaleType {
  text: string;
  type: SaleType;
}

export const saleTypesItems: ISaleType[] = [
  { type: 'auction', text: 'Auction' },
  { type: 'fixed-price', text: 'Fixed Price' },
];
