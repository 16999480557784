import React from 'react';

const ForgotPasswordIcon: React.FunctionComponent = () => {
  return (
    <svg
      id="shield-security"
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      viewBox="0 0 128 128"
    >
      <path
        id="Vector"
        d="M39.52.833,10.187,11.82C4.587,13.953,0,20.567,0,26.593v43.2C0,74.113,2.827,79.82,6.293,82.38L35.627,104.3c5.173,3.893,13.653,3.893,18.827,0L83.787,82.38A17.808,17.808,0,0,0,90.08,69.793v-43.2c0-5.973-4.587-12.64-10.187-14.72L50.56.887A18.144,18.144,0,0,0,39.52.833Z"
        transform="translate(18.933 10.42)"
        fill="#611caf"
        opacity="0.4"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M26.667,13.333A13.334,13.334,0,1,0,9.333,25.973V40a4,4,0,0,0,8,0V25.973A13.269,13.269,0,0,0,26.667,13.333Z"
        transform="translate(50.667 42.667)"
        fill="#611caf"
      />
      <path id="Vector-3" data-name="Vector" d="M0,0H128V128H0Z" fill="none" opacity="0" />
    </svg>
  );
};
export default ForgotPasswordIcon;
