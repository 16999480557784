import React from 'react';
import { CustomIconProps, setColor } from '..';

const NotificationIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g
        id="vuesax_linear_lamp-charge"
        data-name="vuesax/linear/lamp-charge"
        transform="translate(-549 -188)"
      >
        <g id="lamp-charge">
          <path
            id="Vector"
            d="M1.172,0,.1,1.86c-.24.41-.04.75.43.75H1.8c.48,0,.67.34.43.75L1.172,5.22"
            transform="translate(559.828 195.89)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.75"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M4.19,16.044v-1.16A8.579,8.579,0,0,1,0,7.9,7.919,7.919,0,0,1,9.69.194a7.784,7.784,0,0,1,5.27,4.07c2.09,4.2-.11,8.66-3.34,10.61v1.16c0,.29.11.96-.96.96H5.15C4.05,17,4.19,16.574,4.19,16.044Z"
            transform="translate(553.11 189.996)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.75"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,.487a12.808,12.808,0,0,1,7,0"
            transform="translate(557.5 209.512)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.75"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(549 188)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
export default NotificationIcon;
