import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonBase, Theme, Typography, useMediaQuery } from '@mui/material';
import { IVerifyWalletReq } from 'apis/user/_';
import { setAppLoading } from 'store/app/actions';
import useAuth from 'hooks/useAuth';
import useTitle from 'hooks/useTitle';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import { signupService } from 'services/User';
import { createSign } from 'services/Wallet';
import Links from 'pages/login/components/Links';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import MetaMaskIcon from 'components/icons/login/MetaMaskIcon';
import AuthLayout, { AuthLayoutClasses } from 'components/layouts/auth-layout';
import EmailVerification from './components/email-verification';
import InputRow from './components/input-row';

interface IState {
  name: string;
  email: string;
  password: string;
  nameError: boolean;
  emailError: boolean;
  passwordError: boolean;
  openModal: boolean;
  walletInfo?: IVerifyWalletReq;
}

const SignupPage: React.FunctionComponent = () => {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const [emailVerification, setEmailVerification] = useState(false);
  const { authoredSuccessfully } = useAuth();

  useTitle({ title: 'Signup' });
  const dispatch = useDispatch();
  const [state, setState] = useState<IState>({
    name: '',
    email: '',
    password: '',
    nameError: false,
    emailError: false,
    passwordError: false,
    openModal: false,
    walletInfo: undefined,
  });

  const onConnect = async () => {
    dispatch(setAppLoading(true));
    const sign = await createSign();
    if (sign) {
      setState(prevState => ({
        ...prevState,
        walletInfo: sign,
        openModal: true,
      }));
    }
    dispatch(setAppLoading(false));
  };

  const submitSignup = async (event: any) => {
    event.preventDefault();
    const { email, password, name } = state;
    const invalidEmail = email === '' || !Utils.emailValidation(email);
    const invalidPassword = password.length < 8;
    const invalidName = name.length < 3;

    if (invalidEmail || invalidPassword || invalidName) {
      setState(prevState => ({
        ...prevState,
        passwordError: invalidPassword,
        nameError: invalidName,
        emailError: invalidEmail,
      }));
      return;
    }
    if (!state.walletInfo) {
      return;
    }
    dispatch(setAppLoading(true));
    const response = await signupService({
      name: state.name,
      email: state.email,
      password: state.password,
      message: state.walletInfo.message,
      wallet: state.walletInfo.wallet,
      signature: state.walletInfo.signature,
    });
    if (response) {
      dispatch(setAppLoading(false));
      setEmailVerification(true);
      return;
    }
    dispatch(setAppLoading(false));
  };

  const handleModalClose = () => setEmailVerification(false);

  const onVerifiedEmail = async () => {
    await authoredSuccessfully('SIGN_UP');
  };

  return (
    <AuthLayout>
      <div className={AuthLayoutClasses.form}>
        <Typography
          fontWeight={600}
          textAlign="center"
          className={matches ? 'mb-5' : 'mb-4'}
          sx={{ maxWidth: 270, lineHeight: '24px' }}
        >
          {Strings.connectToSignup}
        </Typography>
        <ButtonBase
          className="d-flex flex-column align-items-center justify-content-center"
          sx={{
            borderRadius: '18px',
            width: matches ? '200px' : '132px',
            height: matches ? '200px' : '132px',
          }}
          onClick={onConnect}
        >
          <MetaMaskIcon size={matches ? '132' : '74'} />
          <Typography variant="h6" fontWeight="bold" className="mt-2">
            Metamask
          </Typography>
        </ButtonBase>
        <CMPButton
          variant="text"
          className="mt-3 px-4"
          sx={{ textTransform: 'none' }}
          component={Link}
          to="/login"
        >
          {Strings.loginText}
        </CMPButton>
      </div>
      <DialogModal
        maxWidth="xs"
        open={state.openModal}
        onClose={() => setState(prevState => ({ ...prevState, openModal: false }))}
      >
        <form className="d-flex flex-column align-items-center" onSubmit={submitSignup}>
          <Typography
            fontWeight={600}
            textAlign="center"
            className="mb-3"
            sx={{ maxWidth: 280, lineHeight: '24px' }}
          >
            {Strings.completeDesc}
          </Typography>
          <InputRow
            title={Strings.name}
            inputValue={state.name}
            onInputChange={name =>
              setState(prevState => ({
                ...prevState,
                ...(prevState.nameError && { nameError: false }),
                name,
              }))
            }
            error={state.nameError}
            defaultVariant
          />
          <InputRow
            title={Strings.email}
            inputValue={state.email}
            onInputChange={email =>
              setState(prevState => ({
                ...prevState,
                ...(prevState.emailError && { emailError: false }),
                email,
              }))
            }
            error={state.emailError}
            defaultVariant
          />
          <InputRow
            passwordType
            title={Strings.password}
            inputValue={state.password}
            onInputChange={password =>
              setState(prevState => ({
                ...prevState,
                ...(prevState.passwordError && { passwordError: false }),
                password,
              }))
            }
            error={state.passwordError}
            defaultVariant
          />
          <CMPButton
            className="w-100 mt-5"
            type="submit"
            sx={{ height: 48 }}
            disabled={state.name === '' || state.email === '' || state.password === ''}
          >
            {Strings.signup}
          </CMPButton>
        </form>
      </DialogModal>
      <Typography fontWeight="bold" align="center" className="my-4">
        {Strings.acceptTerms}
      </Typography>
      <Links />
      <EmailVerification
        open={emailVerification}
        onClose={handleModalClose}
        email={state.email}
        onVerified={onVerifiedEmail}
      />
    </AuthLayout>
  );
};
export default SignupPage;
