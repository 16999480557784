import { User, UserToken } from 'models/User';

export interface UserStoreState {
  userToken: UserToken | null;
  user: User | null;
  balance: string;
}

export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_BALANCE = 'SET_BALANCE';

interface SetUserToken {
  type: typeof SET_USER_TOKEN;
  payload: UserToken | null;
}

interface SetUser {
  type: typeof SET_USER;
  payload: User | null;
}

interface SetBalance {
  type: typeof SET_BALANCE;
  payload: string;
}

export type UserActionTypes = SetUserToken | SetUser | SetBalance;
