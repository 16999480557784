import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setAttributes } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import Strings from 'utils/Strings';
import { CategoryAttribute } from 'models/@types';
import FilterContainer from 'components/common/filter-container';
import CMPSideMenu from 'components/common/side-menu';
import Div from 'components/global/div';

interface IProps {
  attrs: CategoryAttribute[];
}

const CharacterAttributes: React.FunctionComponent<IProps> = ({ attrs }) => {
  const dispatch = useDispatch();
  const { attributes } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);
  let selectedCount = 0;
  attributes.map(item => {
    selectedCount += item.checkedItems.length;
  });

  const onSelectItem = (attr: string, selectedItem: string) => {
    const currentAttr = attributes.find(item => item.text === attr);
    if (currentAttr) {
      const currentAttrItems = currentAttr.checkedItems;
      const index = currentAttrItems.indexOf(selectedItem);
      if (index > -1) {
        currentAttrItems.splice(index, 1);
      } else {
        currentAttrItems.push(selectedItem);
      }
      const currentAttrIndex = attributes.findIndex(item => item.text === attr);
      attributes.splice(currentAttrIndex, 1);
      dispatch(setAttributes([...attributes, { text: attr, checkedItems: currentAttrItems }]));
      return;
    }
    dispatch(setAttributes([...attributes, { text: attr, checkedItems: [selectedItem] }]));
  };

  return (
    <FilterContainer
      count={selectedCount > 0 ? selectedCount : undefined}
      className="mt-3"
      title={Strings.attrs}
      body={
        <Div
          className="d-flex flex-wrap align-items-center justify-content-between mb-3"
          sx={{ position: 'relative' }}
        >
          {attrs.map(({ attribute, values }) => {
            const checked = attributes.find(attr => attr.text === attribute);
            return (
              <CMPSideMenu
                key={attribute}
                title={attribute}
                items={values}
                onCheckItem={selectedItem => onSelectItem(attribute, selectedItem)}
                checkedItems={checked?.checkedItems || []}
              />
            );
          })}
        </Div>
      }
    />
  );
};
export default CharacterAttributes;
