import { ComponentType } from 'react';
import { CharacterGender, CharacterRace } from 'models/Filters';
import { CustomIconProps } from 'components/icons';
import FemaleIcon from 'components/icons/main/FemaleIcon';
import MaleIcon from 'components/icons/main/MaleIcon';

// Character Races
export interface ICharacterRace {
  color: string;
  type: CharacterRace;
}
export const characterRaces: ICharacterRace[] = [
  { color: '#0BB4E7', type: 'Wizards' },
  { color: '#F4B63A', type: 'Elves' },
  { color: '#FF6B6B', type: 'Apes' },
  { color: '#1DD1A1', type: 'Trolls' },
  { color: '#9D73CD', type: 'Felines' },
  { color: '#D5A35C', type: 'Yetis' },
  { color: '#F485E5', type: 'Dwarves' },
];

// Character Genders
export interface IGenderItem {
  type: CharacterGender;
  icon: ComponentType<CustomIconProps>;
}
export const genderItems: IGenderItem[] = [
  { icon: MaleIcon, type: 'Male' },
  { icon: FemaleIcon, type: 'Female' },
];
