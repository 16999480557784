import React from 'react';
import CMPButton from 'components/global/button';

type Preset = '1' | '7' | '14' | '30';

interface IProps {
  onSelect: (value: Preset) => void;
  selected: string;
}

const DurationPresets: React.FunctionComponent<IProps> = ({ onSelect, selected }) => {
  const items: Preset[] = ['1', '7', '14', '30'];

  const onChange = (value: Preset) => {
    onSelect(value);
  };

  return (
    <div className="d-flex align-items-center justify-content-end mb-4">
      {items.map(item => (
        <CMPButton
          key={item}
          variant={(selected as Preset) === item ? 'contained' : 'text'}
          color={(selected as Preset) === item ? 'primary' : 'inherit'}
          onClick={() => onChange(item)}
          sx={{ minWidth: '58px', textTransform: 'none' }}
          size="small"
        >
          {item} d
        </CMPButton>
      ))}
    </div>
  );
};
export default DurationPresets;
