import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import Strings from 'utils/Strings';
import CMPInput from 'components/global/input';
import HidePasswordIcon from 'components/icons/login/HidePasswordIcon';
import ShowPasswordIcon from 'components/icons/login/ShowPasswordIcon';

type InputType = 'text' | 'password';

interface IProps {
  password: string;
  onChanged: (val: string) => void;
  defaultVariant?: boolean;
  withMargin?: boolean;
  hidePlaceholder?: boolean;
  error?: boolean;
}

const PasswordInput: React.FunctionComponent<IProps> = ({
  password,
  onChanged,
  defaultVariant = true,
  withMargin = true,
  hidePlaceholder,
  error,
}) => {
  const [type, setType] = useState<InputType>('password');
  return (
    <CMPInput
      filled
      fullWidth
      placeholder={!hidePlaceholder ? Strings.password : undefined}
      className={withMargin ? 'mt-3' : undefined}
      type={type}
      autoComplete="off"
      value={password}
      defaultVariant={defaultVariant}
      endAdornment={
        <IconButton
          onClick={() => setType(prevState => (prevState === 'password' ? 'text' : 'password'))}
        >
          {type === 'password' ? <ShowPasswordIcon /> : <HidePasswordIcon />}
        </IconButton>
      }
      onChanged={onChanged}
      hasError={error}
    />
  );
};
export default PasswordInput;
