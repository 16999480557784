import React from 'react';
import clsx from 'clsx';
import { ThreeDots } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';
import { getSingleNFTService } from 'services/MarketPlace';
import { NFTDetails } from 'models/NFTDetails';
import ActionButton from 'pages/nft/components/action-button';
import ContractContainer from 'components/global/contract-container';
import DialogModal from 'components/global/dialog-modal';
import Div from 'components/global/div';
import CMPImage from 'components/global/image';
import Logo from 'components/icons/Logo';
import LocationIcon from 'components/icons/main/LocationIcon';
import { IState } from '.';

interface ITooltipProps {
  state: IState;
  onClose: () => void;
}

const Tooltip: React.FunctionComponent<ITooltipProps> = ({ state, onClose }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { data, isLoading } = useQuery<NFTDetails | null>(
    ['land-item', state.land.tokenId, state.land.contract],
    () =>
      getSingleNFTService({
        tokenId: state.land.tokenId.toString(),
        contract: state.land.contract,
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
    },
  );
  return (
    <DialogModal
      open
      onClose={onClose}
      PaperProps={{
        style: {
          position: 'absolute',
          top: state.yScreen || 0,
          left:
            window.innerWidth - state.xScreen > 520 ? state.xScreen + 16 || 0 : state.xScreen - 380,
          maxWidth: '420px',
        },
      }}
      hideHeader
      // contentProps={{ className: 'd-flex align-items-center' }}
    >
      {isLoading ? (
        <Div
          className="d-flex align-items-center justify-content-center w-100"
          sx={{ height: 128 }}
        >
          <ThreeDots color={palette.text.secondary} />
        </Div>
      ) : (
        <>
          {data && (
            <>
              <div className="d-flex align-items-center mb-3">
                <Div
                  className="d-flex align-items-center justify-content-center"
                  sx={{
                    borderRadius: '18px',
                    width: '128px',
                    height: '128px',
                    overflow: 'hidden',
                    background: theme =>
                      theme.palette.mode === 'light'
                        ? 'linear-gradient(to bottom, #EEE7F6 , #B4A5C8)'
                        : 'linear-gradient(to bottom, #292037 , #534B5F)',
                  }}
                >
                  <CMPImage asset={data.asset} width={128} height={128} />
                </Div>
                <div className="ms-4">
                  <Typography variant="body1" className="mb-3">
                    {state.land.attributes ? data.region?.text ?? '--' : '--'}
                  </Typography>
                  <div className="d-flex align-items-center">
                    <LocationIcon color={data.region?.color ?? undefined} />
                    <Typography variant="body1" color="textSecondary" className="ms-2">
                      {state.xLand}, {-state.yLand}
                    </Typography>
                  </div>
                  {data.sale && (
                    <div
                      style={{ marginInlineStart: '2px' }}
                      className="d-flex align-items-center mt-3"
                    >
                      <Logo size="small" purple />
                      <Typography
                        variant="h6"
                        component="span"
                        color="textPrimary"
                        className="ms-2"
                      >
                        {data.priceStr}
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="span"
                          className="ms-3"
                        >
                          ${data.usdtPrice}
                        </Typography>
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <ContractContainer
                containerClassName={clsx('d-flex align-items-center')}
                containerSx={{
                  height: 30,
                  px: 1,
                  mx: 0,
                  my: 2,
                  cursor: data.owner.name !== '--' ? 'pointer' : undefined,
                }}
                variant="caption"
                onClick={
                  data.owner.name !== '--'
                    ? () => navigate(`/users/${data.owner.wallet}`)
                    : undefined
                }
              >
                {data.owner.wallet}
              </ContractContainer>
              <ActionButton data={data} matches withTopMargin={false} roundedButton={false} />
            </>
          )}
        </>
      )}
    </DialogModal>
  );
};
export default Tooltip;
