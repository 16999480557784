import { ComponentType } from 'react';
import { ItemRarity, ItemType } from 'models/Filters';
import { CustomIconProps } from 'components/icons';
import ArtifactsIcon from 'components/icons/main/ArtifactsIcon';
import PotionsIcon from 'components/icons/main/PotionsIcon';

export interface ITypeItem {
  type: ItemType;
  icon: ComponentType<CustomIconProps>;
}
export const typeItems: ITypeItem[] = [
  { icon: ArtifactsIcon, type: 'Artifacts' },
  { icon: PotionsIcon, type: 'Potions' },
];

export interface IRarityFilter {
  count: number;
  color: string;
  type: ItemRarity;
}
export const rarityItems: IRarityFilter[] = [
  { count: 1, type: 'Common', color: '#A199AD' },
  { count: 2, type: 'Uncommon', color: '#2ECC71' },
  { count: 3, type: 'Rare', color: '#0BB4E7' },
  { count: 4, type: 'Mythical', color: '#F4B63A' },
];
