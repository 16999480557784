import * as Types from './types';

const initialState: Types.UserStoreState = {
  userToken: null,
  user: null,
  balance: '0.00',
};

function userReducer(state = initialState, action: Types.UserActionTypes): Types.UserStoreState {
  switch (action.type) {
    case Types.SET_USER_TOKEN:
      return { ...state, userToken: action.payload };
    case Types.SET_USER:
      return { ...state, user: action.payload };
    case Types.SET_BALANCE:
      return { ...state, balance: action.payload };
    default:
      return state;
  }
}

export default userReducer;
