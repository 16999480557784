import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, IconButton, useTheme } from '@mui/material';
import { RootState } from 'store';
import { setLandView } from 'store/constants/actions';
import { setView } from 'store/marketplace/actions';
import { LandViewMode } from 'models/@types';
import GridIcon from 'components/icons/main/GridIcon';
import MapViewIcon from 'components/icons/main/MapViewIcon';
import View from './_styles';

const LandViewSelect: React.FunctionComponent = () => {
  const landView = useSelector<RootState, LandViewMode>(state => state.Constants.landView);
  const theme = useTheme();
  const dispatch = useDispatch();

  const onChangeMode = (mode: LandViewMode) => {
    if (mode !== landView) {
      dispatch(setLandView(mode));
      if (mode === 'grid') {
        dispatch(setView('grid'));
      }
    }
  };

  return (
    <View>
      <IconButton onClick={() => onChangeMode('grid')}>
        <GridIcon color={theme.palette.text[landView === 'grid' ? 'primary' : 'secondary']} />
      </IconButton>
      <Divider orientation="vertical" sx={{ height: 24, margin: '0 4px' }} />
      <IconButton onClick={() => onChangeMode('land')}>
        <MapViewIcon color={theme.palette.text[landView === 'land' ? 'primary' : 'secondary']} />
      </IconButton>
    </View>
  );
};
export default LandViewSelect;
