import React from 'react';
import TabView from 'components/common/tab-view';
import Div from 'components/global/div';
import { classes } from '../../_styles';
import { statisticsTime, StatisticTimeType } from '../../data';

interface IProps {
  activeTab: StatisticTimeType;
  onTabChange: (tab: StatisticTimeType) => void;
}

const StatisticsHeader: React.FunctionComponent<IProps> = ({ activeTab, onTabChange }) => {
  const onChange = (tab: StatisticTimeType) => {
    if (activeTab !== tab) {
      onTabChange(tab);
    }
  };

  return (
    <Div className={classes.statisticsHeader}>
      <TabView items={statisticsTime} activeTab={activeTab} onTabChange={onChange} />
    </Div>
  );
};
export default StatisticsHeader;
