import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import Strings from 'utils/Strings';
import { getStatisticsService } from 'services/Dashboard';
import { Statistics as StatisticsType } from 'models/Statistics';
import Div from 'components/global/div';
import StatisticsIcon from 'components/icons/dashboard/StatisticsIcon';
import { classes } from '../../_styles';
import { statisticItems, StatisticTimeType } from '../../data';
import StatisticsHeader from './_Header';

const getFilteredData = (data: StatisticsType | undefined, activeTab: StatisticTimeType) => {
  if (!data) {
    return {
      sales: '0',
      listing: '0',
      volume: '0',
    };
  }
  switch (activeTab) {
    case '24h':
      return {
        sales: data.dailySales.totalSales,
        listing: data.dailyListing.totalSales,
        volume: data.dailyVolume,
      };
    case '7d':
      return {
        sales: data.weeklySales.totalSales,
        listing: data.weeklyListing.totalSales,
        volume: data.weeklyVolume,
      };
    case '30d':
      return {
        sales: data.monthlySales.totalSales,
        listing: data.monthlyListing.totalSales,
        volume: data.monthlyVolume,
      };
    default:
      return {
        sales: '0',
        listing: '0',
        volume: '0',
      };
  }
};

const Statistics: React.FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState<StatisticTimeType>('24h');
  const { data: statistics } = useQuery(['statistics'], () => getStatisticsService(), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
  });

  const data = getFilteredData(statistics, activeTab);

  return (
    <Div sx={{ position: 'sticky', top: 68 }}>
      <Div
        className="d-flex align-items-center"
        sx={{
          height: 52,
          justifyContent: 'center',
          '@media screen and (min-width: 900px)': {
            paddingInlineStart: '34px',
            justifyContent: 'flex-start',
          },
        }}
      >
        <StatisticsIcon />
        <Typography className="ms-2" sx={{ fontSize: '18px', fontWeight: 500 }}>
          {Strings.statistics}
        </Typography>
      </Div>
      <Div className={classes.statisticsContainer}>
        <StatisticsHeader activeTab={activeTab} onTabChange={setActiveTab} />
        <Div className={classes.statisticsList}>
          {statisticItems.map(({ icon: Icon, type }) => (
            <Div key={type} className={classes.statisticsRow}>
              <Icon />
              <Typography variant="body1" color="textSecondary" className="mt-2">
                {type}
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {type === 'SALES'
                  ? data?.sales
                  : type === 'LISTING'
                  ? data?.listing
                  : `$${data?.volume}`}
              </Typography>
            </Div>
          ))}
        </Div>
      </Div>
    </Div>
  );
};
export default Statistics;
