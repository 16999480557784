import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import { INFTDetailSale } from 'models/NFTDetails';
import { NftSectionContainer } from 'pages/nft/_styles';
import Description from 'components/global/description';
import LogoIcon from 'components/icons/LogoIcon';
import { StyledTableCell, StyledTableRow } from './_styles';

interface IProp {
  history?: INFTDetailSale[];
}

const SaleHistory: React.FunctionComponent<IProp> = ({ history }) => {
  const navigate = useNavigate();
  if (!history || history.length === 0) {
    return null;
  }

  const navigateToUserPage = (wallet: string) => {
    navigate(`/users/${wallet}`);
  };

  return (
    <NftSectionContainer>
      <Typography sx={{ mb: 4 }} variant="h5" fontWeight="bold">
        {Strings.saleHistory}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 5,
          border: theme => `1px solid ${theme.palette.divider}`,
          boxShadow: 'none',
        }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">BUYER</StyledTableCell>
              <StyledTableCell align="center">SELLER</StyledTableCell>
              <StyledTableCell align="center">PRICE</StyledTableCell>
              <StyledTableCell align="center">STATUS</StyledTableCell>
              <StyledTableCell align="center">TYPE</StyledTableCell>
              <StyledTableCell align="center">DATE</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history
              .slice()
              .reverse()
              .map(row => (
                <StyledTableRow key={row.saleId}>
                  <StyledTableCell
                    align="center"
                    style={{ cursor: row.buyer.name ? 'pointer' : undefined }}
                    onClick={
                      row.buyer.name ? () => navigateToUserPage(row.buyer.wallet) : undefined
                    }
                  >
                    <>
                      {row.buyer.name ? '@ ' : null}
                      {row.buyer.name || '--'}
                    </>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ cursor: row.seller.name ? 'pointer' : undefined }}
                    onClick={
                      row.seller.name ? () => navigateToUserPage(row.seller.wallet) : undefined
                    }
                  >
                    {row.seller.name && (
                      <Typography
                        sx={{ paddingBottom: '4px', marginInlineEnd: 1 }}
                        variant="body1"
                        component="span"
                      >
                        @
                      </Typography>
                    )}
                    {row.seller.name || '--'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Description
                      containerSx={{ justifyContent: 'center' }}
                      valueProps={{ fontSize: '1.125rem', ml: 1 }}
                      icon={<LogoIcon />}
                      value={Utils.removeRC20Decimal(Number(row.price || row.minPrice)).toString()}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.status || '--'}</StyledTableCell>
                  <StyledTableCell align="center">{row.type || '--'}</StyledTableCell>
                  <StyledTableCell align="center">
                    {moment(row.startTime).startOf('seconds').fromNow()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </NftSectionContainer>
  );
};

export default SaleHistory;
