import React from 'react';
import { Divider, IconButton } from '@mui/material';
import Div from 'components/global/div';
import ZoomInIcon from 'components/icons/main/ZoomInIcon';
import ZoomOutIcon from 'components/icons/main/ZoomOutIcon';

const Zoom: React.FunctionComponent = () => {
  return (
    <Div
      sx={{
        position: 'absolute',
        top: 24,
        right: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme => theme.palette.filterBg,
        borderRadius: '18px',
        width: '48px',
        height: '96px',
      }}
    >
      <IconButton id="land-zoom-in-button" sx={{ width: 36, height: 36 }}>
        <ZoomInIcon />
      </IconButton>
      <Divider
        sx={{
          width: '24px',
          height: '2px',
          color: theme => theme.palette.text.secondary,
          my: '4px',
        }}
      />
      <IconButton id="land-zoom-out-button" sx={{ width: 36, height: 36 }}>
        <ZoomOutIcon />
      </IconButton>
    </Div>
  );
};
export default Zoom;
