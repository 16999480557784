import React, { useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import Utils from 'libs/Utils';
import AppConfig from 'models/env/AppConfig';
import AppLoading from 'components/global/app-loading';
import CMPSnackbar from 'components/global/snackbar';
import Navbar from 'components/layouts/navbar';
import SideBar from 'components/layouts/side-bar';
import AppProvider from 'components/providers/App';
import RouterProvider from 'components/providers/Router';
import ThemeProvider from 'components/providers/Theme';
import './assets/scss/app.scss';
import './assets/scss/custom-classes.css';

const App: React.FunctionComponent = () => {
  useLayoutEffect(() => {
    const appConfig = new AppConfig().readFromLocalStorage();
    if (appConfig) {
      Utils.changeTheme(appConfig.theme);
    }
  }, []);
  return (
    <Provider store={store}>
      <ThemeProvider>
        <AppProvider>
          <BrowserRouter basename="/">
            <div className="cosmic-nft-marketplace">
              <Navbar />
              <SideBar />
              <RouterProvider />
            </div>
          </BrowserRouter>
          <AppLoading />
          <CMPSnackbar />
        </AppProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
