import React, { useEffect } from 'react';
import Strings from 'utils/Strings';
import { harmonyChainID } from 'constants/Statics';
import { showSnackbar } from 'components/global/snackbar';
// import { logoutService } from 'services/User';

// @ts-ignore
const { ethereum } = window;

const MetamaskProvider: React.FunctionComponent = () => {
  useEffect(() => {
    if (ethereum) {
      ethereum.on('accountsChanged', (accounts: string[]) => {
        if (accounts.length === 0) {
          showSnackbar({
            severity: 'warning',
            message: Strings.disconnectWallet,
          });
          // logoutService();
        }
      });
      ethereum.on('chainChanged', (chainId: string) => {
        if (parseInt(chainId) !== harmonyChainID) {
          showSnackbar({
            message: Strings.harmony,
            severity: 'warning',
          });
          // logoutService();
        }
      });
    }
  }, []);

  return null;
};
export default MetamaskProvider;
