import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Fade, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Utils from 'libs/Utils';
import Div from 'components/global/div';
import ArrowDownIcon from 'components/icons/main/ArrowDownIcon';
import MoreIcon from 'components/icons/main/MoreIcon';
import DropDownDiv, { classes } from './_styles';

export interface IDropDownItem<T> {
  key: T;
  text: string;
  extra?: string;
}

interface IProps<T> {
  className?: string;
  items: IDropDownItem<T>[];
  selected?: IDropDownItem<T>;
  selectedComponent?: ReactNode;
  onSelect?: (item: IDropDownItem<T>) => void;
  minWidth?: string | number;
  maxWidth?: string | number;
  iconMode?: boolean;
  smallItems?: boolean;
  textItems?: boolean;
}

function CMPDropdown<T>({
  items,
  selected,
  selectedComponent,
  onSelect,
  className,
  minWidth,
  maxWidth,
  iconMode,
  textItems,
}: IProps<T>): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
  };

  const onSelectItem = (item: IDropDownItem<T>) => {
    if (onSelect) {
      onSelect(item);
      setAnchorEl(null);
    }
  };

  return (
    <Div
      className={className}
      sx={{ position: 'relative', width: iconMode ? 'fit-content' : minWidth || 220, maxWidth }}
    >
      {iconMode ? (
        <IconButton
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '12px',
            backgroundColor: theme => theme.palette.iconBg,
          }}
          onClick={handleClick}
        >
          <MoreIcon />
        </IconButton>
      ) : (
        <DropDownDiv
          onClick={handleClick}
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {selectedComponent}
          {selected ? (
            <Typography fontWeight="bold">{selected.text}</Typography>
          ) : selectedComponent ? null : (
            '--'
          )}
          <div className={clsx(classes.icon, { [classes.open]: open, [classes.close]: !open })}>
            <ArrowDownIcon />
          </div>
        </DropDownDiv>
      )}
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
        BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } }}
        MenuListProps={{ disablePadding: true, 'aria-labelledby': 'fade-button' }}
        onClick={textItems ? e => e.stopPropagation() : undefined}
        PaperProps={{
          sx: {
            boxShadow: 0,
            width: minWidth || 220,
            maxWidth,
            padding: 0,
            background: theme => theme.palette.filterBg,
            border: theme =>
              Utils.getAppTheme() === 'LIGHT' ? `1px solid ${theme.palette.divider}` : undefined,
            borderRadius: '24px',
            overflow: 'hidden',
            marginTop: '8px',
          },
        }}
      >
        {items.map(item => (
          <MenuItem
            key={item.text}
            style={{
              minHeight: '42px',
              fontSize: '14px',
              opacity: textItems ? 1 : undefined,
            }}
            selected={item.key === selected?.key}
            disableRipple
            disabled={textItems}
            onClick={
              onSelect
                ? e => {
                    e.stopPropagation();
                    onSelectItem({ key: item.key, text: item.text });
                  }
                : e => e.stopPropagation()
            }
          >
            {item.extra ? (
              <Div
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography>{item.text}</Typography>
                <Typography>{item.extra}</Typography>
              </Div>
            ) : (
              item.text
            )}
          </MenuItem>
        ))}
      </Menu>
    </Div>
  );
}
export default CMPDropdown;
