import React, { useState } from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';
import store from 'store';
import { setAppLoading } from 'store/app/actions';
import Strings from 'utils/Strings';
import { ERC721SellService, SellModelInputType } from 'services/SellNFT';
import LabeledInput from 'components/common/labeled-input';
import CMPButton from 'components/global/button';
import Div from 'components/global/div';
import { showSnackbar } from 'components/global/snackbar';
import LogoIcon from 'components/icons/LogoIcon';
import DurationPresets from '../duration-presets';

interface IProp {
  tokenId: number;
  address: string;
  onSuccess: () => void;
}

const AuctionSell: React.FunctionComponent<IProp> = ({ tokenId, address, onSuccess }) => {
  // const [disableRoyaltyState, setDisableRoyaltyState] = useState(false);
  const fee = store.getState().Constants.marketFee;
  const [value, setValue] = useState<SellModelInputType>({
    duration: '',
    minPrice: '',
    royaltyBps: '0',
    startTime: null,
  });

  // useEffect(() => {
  //   (async () => {
  //     const disabled = await disableRoyalty(address, Number(tokenId));
  //     setDisableRoyaltyState(disabled);
  //   })();
  // }, []);

  const handleAuctionSell = async () => {
    const success = await ERC721SellService({
      type: 'auction',
      address,
      tokenId,
      duration: parseInt(value.duration) * 24 * 3600,
      minPrice: +value.minPrice,
      royaltyBps: +value.royaltyBps * 100,
    });
    if (success) {
      const timeout = setTimeout(() => {
        showSnackbar({
          message: Strings.saleSuccess,
          severity: 'success',
        });
        onSuccess();
        store.dispatch(setAppLoading(false));
        clearTimeout(timeout);
      }, 5000);
    }
  };

  return (
    <Div sx={{ m: 2 }}>
      <Typography align="justify" variant="body1">
        {Strings.auctionDesc}
      </Typography>
      <Div sx={{ mt: 3 }}>
        <LabeledInput
          beforeInputHelperElement={<LogoIcon />}
          label={Strings.minPrice}
          onChange={val => setValue(prev => ({ ...prev, minPrice: val }))}
          type="number"
          value={value.minPrice}
        />
        <LabeledInput
          label={Strings.startTime}
          beforeInputHelperElement={<Typography variant="body1">{Strings.date}</Typography>}
          value={moment(new Date().getTime() + 2 * 60 * 1000).format('YY/MM/DD - HH:mm')}
          type="text"
          disabled
        />
        <LabeledInput
          beforeInputHelperElement={<Typography variant="body1">{Strings.days}</Typography>}
          label={Strings.duration}
          onChange={val => setValue(prev => ({ ...prev, duration: (+val).toFixed() }))}
          type="number"
          value={value.duration}
        />
        <DurationPresets
          onSelect={duration => setValue(prev => ({ ...prev, duration }))}
          selected={value.duration}
        />
        {/* <LabeledInput
          label={Strings.royaltyBps}
          onChange={val => setValue(prev => ({ ...prev, royaltyBps: val }))}
          type="number"
          value={value.royaltyBps}
          disabled={disableRoyaltyState}
        /> */}
      </Div>
      <Typography align="center">
        {Strings.saleHelper((+value.minPrice - +value.minPrice * (+fee / 100)).toFixed(2), fee)}
      </Typography>
      <CMPButton
        disabled={value.duration === '' || value.minPrice === ''}
        sx={{ height: 56, width: '100%', mt: 3, fontSize: '18px' }}
        onClick={handleAuctionSell}
      >
        {Strings.startAuction}
      </CMPButton>
    </Div>
  );
};

export default AuctionSell;
