import PostRequest from 'models/request/Post';

export interface IEmailLoginReq {
  email: string;
  password: string;
}

export class EmailLoginReq extends PostRequest {
  email: string;
  password: string;
  constructor(props: IEmailLoginReq) {
    super();
    this.email = props.email;
    this.password = props.password;
  }

  body(): Record<string, any> {
    return {
      email: this.email,
      password: this.password,
    };
  }
}

export interface ISignupReq {
  email: string;
  password: string;
  name: string;
  wallet: string;
  message: string;
  signature: string;
}

export class SignupReq extends PostRequest {
  email: string;
  password: string;
  name: string;
  wallet: string;
  message: string;
  signature: string;
  constructor(props: ISignupReq) {
    super();
    this.email = props.email;
    this.password = props.password;
    this.name = props.name;
    this.wallet = props.wallet;
    this.message = props.message;
    this.signature = props.signature;
  }

  body(): Record<string, any> {
    return {
      email: this.email,
      password: this.password,
      name: this.name,
      wallet: this.wallet,
      message: this.message,
      signature: this.signature,
    };
  }
}

export interface IEditProfileReq {
  name?: string;
  email?: string;
  password?: string;
}

export class EditProfileReq extends PostRequest {
  name?: string;
  email?: string;
  password?: string;
  constructor(props: IEditProfileReq) {
    super();
    this.name = props.name;
    this.email = props.email;
    this.password = props.password;
  }

  body(): Record<string, any> {
    const req: IEditProfileReq = {};
    if (this.name) req.name = this.name;
    if (this.email) req.email = this.email;
    if (this.password) req.password = this.password;
    return req;
  }
}

export interface INonceReq {
  wallet: string;
}

export class NonceReq extends PostRequest {
  wallet: string;

  constructor(props: INonceReq) {
    super();
    this.wallet = props.wallet;
  }

  body(): Record<string, any> {
    return {
      wallet: this.wallet,
    };
  }
}

export interface IVerifyWalletReq {
  wallet: string;
  message: string;
  signature: string;
}

export class VerifyWalletReq extends PostRequest {
  wallet: string;
  message: string;
  signature: string;
  constructor(props: IVerifyWalletReq) {
    super();
    this.wallet = props.wallet;
    this.message = props.message;
    this.signature = props.signature;
  }

  body(): Record<string, any> {
    return {
      wallet: this.wallet,
      message: this.message,
      signature: this.signature,
    };
  }
}

export interface IForgotPasswordReq {
  email: string;
}

export class ForgotPasswordReq extends PostRequest {
  email: string;

  constructor(props: IForgotPasswordReq) {
    super();
    this.email = props.email;
  }

  body(): Record<string, any> {
    return {
      email: this.email,
    };
  }
}

export interface IVerifyEmailReq {
  email: string;
  OTP: string;
}

export class VerifyEmailReq extends PostRequest {
  email: string;
  OTP: string;

  constructor(props: IVerifyEmailReq) {
    super();
    this.email = props.email;
    this.OTP = props.OTP;
  }

  body(): Record<string, any> {
    return {
      email: this.email,
      OTP: this.OTP,
    };
  }
}

export interface IResetPasswordReq {
  token: string;
  password: string;
}

export class ResetPasswordReq extends PostRequest {
  token: string;
  password: string;

  constructor(props: IResetPasswordReq) {
    super();
    this.token = props.token;
    this.password = props.password;
  }

  body(): Record<string, any> {
    return {
      token: this.token,
      password: this.password,
    };
  }
}
