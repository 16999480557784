import { ComponentType } from 'react';
import { StructureType } from 'models/Filters';
import { CustomIconProps } from 'components/icons';
import AdvancedIcon from 'components/icons/main/AdvancedIcon';
import RegularIcon from 'components/icons/main/RegularIcon';
import SimpleIcon from 'components/icons/main/SimpleIcon';

export interface IStructureType {
  type: StructureType;
  icon: ComponentType<CustomIconProps>;
}

export const structureTypes: IStructureType[] = [
  { icon: SimpleIcon, type: 'Simple' },
  { icon: RegularIcon, type: 'Regular' },
  { icon: AdvancedIcon, type: 'Advanced' },
];
