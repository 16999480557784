import Axios, { AxiosResponse } from 'axios';
import store from 'store';
import { SERVER_BASE_URL } from 'libs/Configs';
import Utils from 'libs/Utils';
import { logoutService } from 'services/User';
import { AJVError, RequestMethod } from 'models/@types';
import { ApiName } from 'models/ApiNames';
import { IQuery } from 'models/request/Query';
import Response, { IServerResponse } from 'models/request/Response';
import { showSnackbar } from 'components/global/snackbar';

interface ICreateUrl {
  baseUrl: string;
  args?: Array<string>;
  queries?: Array<IQuery>;
}

export const setAuthToken = (): Record<string, string> => {
  const { userToken } = store.getState().User;
  if (userToken) {
    return { Authorization: `Bearer ${userToken.token}` };
  }
  return {};
};

export const createURL = ({ baseUrl, args = [], queries = [] }: ICreateUrl): string => {
  const fullUrl = `${SERVER_BASE_URL}/${baseUrl}`;
  let url = args.length === 0 ? fullUrl : [fullUrl, ...args].join('/');
  queries.forEach((query, i) => {
    if (i === 0) {
      url += '?';
    }
    url += `${query.key}=${query.value}`;
    if (i !== queries.length - 1) {
      url += '&';
    }
  });
  return url;
};

export const axiosAgent = Axios.create({
  baseURL: SERVER_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000 * 5,
  // withCredentials: true,
});

export interface ISendRequestArgs {
  url: string;
  method: RequestMethod;
  apiName: ApiName;
  auth?: boolean;
  body?: Record<string, any>;
  otherHeaders?: Record<string, any>;
}

export const sendRequest = async ({
  url,
  method = 'GET',
  auth = false,
  apiName,
  otherHeaders,
  ...props
}: ISendRequestArgs): Promise<Response> => {
  let headers: Record<string, any> = {};
  if (auth) {
    headers = {
      ...headers,
      ...setAuthToken(),
    };
  }
  if (otherHeaders) {
    headers = {
      ...headers,
      ...otherHeaders,
    };
  }
  try {
    let response: AxiosResponse<IServerResponse>;
    switch (method) {
      case 'GET':
        response = await axiosAgent.get(url, { headers });
        break;
      case 'PATCH':
        response = await axiosAgent.patch(url, props.body, { headers });
        break;
      case 'DELETE':
        response = await axiosAgent.delete(url, { headers });
        break;
      case 'POST':
      default:
        // eslint-disable-next-line no-case-declarations
        const body: any = { ...props.body };
        response = await axiosAgent.post(url, body, { headers });
        break;
    }
    const data = response.data as IServerResponse;
    return new Response(data);
  } catch (err: any) {
    const statusCode = err.response.status as number;
    // console.log(err.response.data.message);
    if (statusCode === 400) {
      if (
        err.response.data.message &&
        err.response.data.message !== '' &&
        (!err.response.data.errors || err.response.data.errors.length === 0)
      ) {
        showSnackbar({
          message: err.response.data.message,
          severity: 'error',
        });
        return new Response(err.response.data);
      }
      const errorObject = err.response.data.errors[0];
      const errorMessage = Utils.parseAJV(errorObject as AJVError);
      showSnackbar({
        message: errorMessage,
        severity: 'error',
      });
      return new Response(err.response.data);
    }
    if (statusCode === 500) {
      // console.log(err.response.data.message);
      return new Response(err.response.data);
    }
    if (statusCode === 403 || statusCode === 401) {
      logoutService();
    }
    return new Response(err.response.data);
  }
};
