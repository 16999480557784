import React from 'react';
import { CustomIconProps, setColor } from '..';

const LogoutIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g
        id="vuesax_linear_logout"
        data-name="vuesax/linear/logout"
        transform="translate(-748 -444)"
      >
        <g id="logout">
          <path
            id="Vector"
            d="M0,5.07C.31,1.47,2.16,0,6.21,0h.13c4.47,0,6.26,1.79,6.26,6.26v6.52c0,4.47-1.79,6.26-6.26,6.26H6.21c-4.02,0-5.87-1.45-6.2-4.99"
            transform="translate(756.9 446.49)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.75"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M11.38,0H0"
            transform="translate(751.62 456)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.75"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M3.35,0,0,3.35,3.35,6.7"
            transform="translate(750.5 452.65)"
            fill="none"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.75"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(772 468) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
export default LogoutIcon;
