import React from 'react';
import { CustomIconProps, setColor } from '..';

const ArrowDownIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g data-name="arrow-down">
        <path
          d="M10.56,0,6.213,4.347a1.324,1.324,0,0,1-1.867,0L0,0"
          transform="translate(2.72 5.967)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
        <path
          data-name="Vector"
          d="M0,0H16V16H0Z"
          transform="translate(16 16) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};
export default ArrowDownIcon;
