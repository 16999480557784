import { IMarketPlaceState } from 'store/marketplace/types';
import { IQuery } from 'models/request/Query';

export const createFilterQuery = (filteredObj: IMarketPlaceState): IQuery[] => {
  const initialValue: IQuery[] = [];

  return Object.keys(filteredObj).reduce((acc, current) => {
    let typedKey = current;
    const value = filteredObj[typedKey as keyof IMarketPlaceState];
    const ignoreItems = ['view', 'page', 'queries', 'sort', 'category', 'forSale', 'saleTypes'];

    if (
      ignoreItems.includes(typedKey) ||
      !value ||
      (typeof value === 'object' && value.length === 0)
    )
      return acc;

    if (typedKey === 'races') {
      typedKey = 'subCategory';
    }

    if (typedKey === 'characterId') {
      typedKey = 'tokenId';
    }

    if (typedKey === 'regions') {
      typedKey = 'attributes.REGION';
    }

    if (typedKey === 'attributes') {
      const localPrefix = 'attributes';
      const result = filteredObj[typedKey].reduce(
        (localAcc, { checkedItems, text }) => [
          { key: localPrefix + '.' + text.toLocaleLowerCase(), value: String(checkedItems) },
          ...localAcc,
        ],
        initialValue,
      );
      return [...result, ...acc];
    }

    return [
      {
        key: typedKey,
        value:
          typedKey === 'sale'
            ? ''
            : typedKey === 'subCategory'
            ? String(value).slice(0, String(value).length - 1)
            : String(value),
      },
      ...acc,
    ];
  }, initialValue);
};
