import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import store, { RootState } from 'store';
import { setSnackbar } from 'store/app/actions';
import { ISnackbar } from 'models/@types';

export const showSnackbar = (data: ISnackbar): void => {
  store.dispatch(setSnackbar(data));
};

const CMPSnackbar: React.FunctionComponent = () => {
  const snackbar = useSelector<RootState, ISnackbar | null>(state => state.App.snackbar);
  if (!snackbar) {
    return null;
  }

  const onClose = () => {
    store.dispatch(setSnackbar(null));
  };

  return (
    <Snackbar
      open
      autoHideDuration={snackbar.message.length < 50 ? 3000 : 6000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={onClose}
      sx={{
        minWidth: 320,
        boxSizing: 'content-box',
        marginTop: 2,
        '@media screen and (min-width: 600px)': { paddingInlineStart: '84px', marginTop: 0 },
        '& .MuiPaper-root': { borderRadius: '18px', padding: '12px', alignItems: 'center' },
      }}
    >
      <Alert
        severity={snackbar.severity}
        sx={{ width: '100%', fontWeight: 'bold' }}
        variant="filled"
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};
export default CMPSnackbar;
