import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { RootState } from 'store';
import { UserStoreState } from 'store/user/types';
import useAuth from 'hooks/useAuth';
import ColorPalette from 'utils/ColorPalette';
import Strings from 'utils/Strings';
import CMPButton from 'components/global/button';
import Div from 'components/global/div';
import UserIcon from 'components/icons/header/UserIcon';
import Logo from 'components/icons/Logo';
import CMPNavbar from './_styles';
import ChangeTheme from './components/ChangeTheme';
import Links from './components/Links';

const Navbar: React.FunctionComponent = () => {
  const { isLoggedIn } = useAuth();
  const { user, balance } = useSelector<RootState, UserStoreState>(state => state.User);
  const loggedIn = isLoggedIn();
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  return (
    <>
      <CMPNavbar>
        {matches ? <Links desktopView /> : <Logo />}
        <div className="d-flex align-items-center">
          <CMPButton
            rounded={!loggedIn}
            variant={loggedIn ? 'outlined' : 'contained'}
            color={loggedIn ? 'inherit' : 'primary'}
            sx={{
              marginInlineEnd: 2,
              height: loggedIn ? 58 : 36,
              border: theme => (loggedIn ? `1px solid ${theme.palette.divider}` : undefined),
              maxWidth: '160px',
            }}
            startIcon={<UserIcon color={loggedIn ? undefined : ColorPalette.white} />}
            component={Link}
            to={loggedIn ? '/profile' : '/login'}
          >
            {loggedIn ? (
              <Typography fontSize={16} fontWeight="bold" noWrap className="d-flex flex-column">
                {user?.name}
                <Typography color="textSecondary" variant="caption" fontWeight="normal">
                  Balance:{' '}
                  <Typography color="textPrimary" variant="caption" fontWeight="bold">
                    {balance}
                  </Typography>
                </Typography>
              </Typography>
            ) : (
              <Typography fontSize={16} fontWeight="bold" noWrap>
                {Strings.login}
              </Typography>
            )}
          </CMPButton>
          <ChangeTheme />
        </div>
      </CMPNavbar>
      {!matches && (
        <Div
          sx={{
            marginTop: '-8px',
            marginRight: '-8px',
            marginLeft: '-8px',
            position: 'sticky',
            top: 68,
            zIndex: 1,
            background: theme => theme.palette.background.default,
          }}
        >
          <Links />
        </Div>
      )}
    </>
  );
};
export default Navbar;
