import { ethers } from 'ethers';
import ERC1155Artifact from 'constants/artifacts/ERC1155.json';
import ERC20Artifact from 'constants/artifacts/ERC20.json';
import ERC721Artifact from 'constants/artifacts/ERC721.json';
import MarketArtifact from 'constants/artifacts/MarketUpgradeable.json';
import { ContractAddress } from 'constants/ContractAddress';

// @ts-ignore
const { ethereum } = window;

// Testnet: https://api.s0.b.hmny.io,   Mainnet: https://api.harmony.one;
const providerAddress = 'https://api.s0.b.hmny.io';

export const CreateHRC20Contract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.HRC20, ERC20Artifact.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(providerAddress);
  return new ethers.Contract(ContractAddress.HRC20, ERC20Artifact.abi, provider);
};

export const CreateHRC721Contract = (address: string): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(address, ERC721Artifact.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(providerAddress);
  return new ethers.Contract(address, ERC721Artifact.abi, provider);
};

export const CreateHRC1155Contract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.HRC1155, ERC1155Artifact.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(providerAddress);
  return new ethers.Contract(ContractAddress.HRC1155, ERC1155Artifact.abi, provider);
};

export const CreateMarketContract = (): ethers.Contract | null => {
  if (ethereum && ethereum.selectedAddress) {
    if (!ethereum) return null;
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return new ethers.Contract(ContractAddress.MARKET, MarketArtifact.abi, signer);
  }
  const provider = new ethers.providers.JsonRpcProvider(providerAddress);
  return new ethers.Contract(ContractAddress.MARKET, MarketArtifact.abi, provider);
};
