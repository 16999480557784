import React, { useState } from 'react';
import { Typography } from '@mui/material';
import store from 'store';
import { setAppLoading } from 'store/app/actions';
import Strings from 'utils/Strings';
import { NFTBuyService } from 'services/BuyNFT';
import LabeledInput from 'components/common/labeled-input';
import CMPButton from 'components/global/button';
import { showSnackbar } from 'components/global/snackbar';

interface IAuctionBuyProps {
  currentValue: string;
  saleId: number;
  onSuccess?: (e?: any) => void;
  notAddTenPercent?: boolean;
}

const AuctionBuy: React.FunctionComponent<IAuctionBuyProps> = ({
  currentValue,
  saleId,
  onSuccess,
  notAddTenPercent,
}) => {
  const minimumValid = notAddTenPercent
    ? Number(currentValue).toFixed(0)
    : (Number(currentValue) + 0.1 * Number(currentValue)).toFixed(0);
  const [value, setValue] = useState(minimumValid || '0');

  const onAuctionBuy = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const response = await NFTBuyService('auction', Number(value), saleId);
    if (response && onSuccess) {
      const timout = setTimeout(() => {
        onSuccess(event);
        showSnackbar({
          message: Strings.buySuccess,
          severity: 'success',
        });
        store.dispatch(setAppLoading(false));
        clearTimeout(timout);
      }, 5000);
    }
  };

  return (
    <div className="mt-4">
      {!notAddTenPercent && (
        <Typography variant="body1" className="mb-4" textAlign="center">
          The bid price should be more than 10% of current item value
        </Typography>
      )}
      <LabeledInput
        prevent
        value={value}
        onChange={setValue}
        type="number"
        label="Bid Amount"
        hasError={Number(value) < Number(minimumValid)}
      />
      <CMPButton
        onClick={onAuctionBuy}
        disabled={Number(value) < Number(minimumValid)}
        sx={{ height: 56, width: '100%', mt: 3, fontSize: '18px' }}
      >
        {Strings.confirmBuy}
      </CMPButton>
    </div>
  );
};
export default AuctionBuy;
