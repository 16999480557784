import React from 'react';
import { useDispatch } from 'react-redux';
import { setAppLoading } from 'store/app/actions';
import useAuth from 'hooks/useAuth';
import Strings from 'utils/Strings';
import { loginWithMetamask } from 'services/Wallet';
import CMPButton from 'components/global/button';
import MetaMaskIcon from 'components/icons/login/MetaMaskIcon';

const MetamaskLogin: React.FunctionComponent = () => {
  const { setToken, authoredSuccessfully } = useAuth();
  const dispatch = useDispatch();

  const handleLoginWithMetamask = async () => {
    dispatch(setAppLoading(true));
    const token = await loginWithMetamask();
    if (token) {
      setToken(token);
      await authoredSuccessfully('LOGIN');
      dispatch(setAppLoading(false));
      return;
    }
    dispatch(setAppLoading(false));
  };

  return (
    <CMPButton
      sx={{ height: 56, width: '100%' }}
      startIcon={<MetaMaskIcon />}
      onClick={handleLoginWithMetamask}
    >
      {Strings.metamaskLogin}
    </CMPButton>
  );
};
export default MetamaskLogin;
