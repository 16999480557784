import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { IResetPasswordPageParams } from 'routes/params';
import { setAppLoading } from 'store/app/actions';
import useTitle from 'hooks/useTitle';
import Strings from 'utils/Strings';
import { resetPasswordService } from 'services/User';
import Links from 'pages/login/components/Links';
import InputRow from 'pages/signup/components/input-row';
import CMPButton from 'components/global/button';
import { showSnackbar } from 'components/global/snackbar';
import AuthLayout, { AuthLayoutClasses } from 'components/layouts/auth-layout';

interface IState {
  password: string;
  confirmPassword: string;
}

const ResetPasswordPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams<IResetPasswordPageParams>();
  const [state, setState] = useState<IState>({ password: '', confirmPassword: '' });

  useTitle({ title: 'Reset Password' });

  if (token === undefined) {
    navigate('/character');
    return null;
  }

  const submitSignup = async (event: any) => {
    event.preventDefault();
    const { confirmPassword, password } = state;
    if (confirmPassword !== password) {
      return;
    }
    if (password.length < 8) {
      return;
    }
    dispatch(setAppLoading(true));
    const response = await resetPasswordService(token, password);
    dispatch(setAppLoading(false));
    if (response) {
      showSnackbar({
        severity: 'success',
        message: Strings.passwordReset,
      });
      navigate('/login');
    }
  };

  return (
    <AuthLayout>
      <form className={AuthLayoutClasses.form} onSubmit={submitSignup}>
        <Typography
          variant="body1"
          fontWeight={600}
          textAlign="center"
          className="mb-3"
          sx={{ maxWidth: 280, lineHeight: '24px' }}
        >
          {Strings.resetPassword}
        </Typography>
        <InputRow
          passwordType
          title={Strings.password}
          inputValue={state.password}
          onInputChange={password =>
            setState(prevState => ({
              ...prevState,
              password,
            }))
          }
        />
        <InputRow
          passwordType
          title={Strings.confirmPassword}
          inputValue={state.confirmPassword}
          onInputChange={confirmPassword =>
            setState(prevState => ({
              ...prevState,
              confirmPassword,
            }))
          }
        />
        <CMPButton
          className="w-100 mt-5"
          type="submit"
          sx={{ height: 48 }}
          disabled={
            state.password === '' ||
            state.confirmPassword === '' ||
            state.password !== state.confirmPassword ||
            state.password.length < 8
          }
        >
          {Strings.reset}
        </CMPButton>
      </form>
      <div className="mt-auto">
        <Typography fontWeight="bold" align="center" className="mb-4">
          {Strings.acceptTerms}
        </Typography>
        <Links />
      </div>
    </AuthLayout>
  );
};
export default ResetPasswordPage;
