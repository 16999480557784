import { styled } from '@mui/material';
import Utils from 'libs/Utils';

const CategoriesDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderRadius: 24,
  background: theme.palette.filterBg,
  border: Utils.getAppTheme() === 'LIGHT' ? `1px solid ${theme.palette.divider}` : undefined,
  flexWrap: 'wrap',
  padding: 8,
}));
export default CategoriesDiv;
