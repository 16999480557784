import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { INFTPageParams } from 'routes/params';
import useTitle from 'hooks/useTitle';
import { getSingleNFTService } from 'services/MarketPlace';
import { NFTDetails } from 'models/NFTDetails';
import BackButton from 'components/common/back-button';
import FetchWrapper from 'components/layouts/fetch-wrapper';
import Information from './components/informations';
import NftAttributes from './components/nft-body/attributes';
import SaleHistory from './components/sale-history';

interface IProp {}

const NftPage: React.FunctionComponent<IProp> = () => {
  const { tokenId, contract } = useParams<INFTPageParams>();

  const { isLoading, data, refetch } = useQuery<NFTDetails | null>(
    ['NFTDetails', tokenId],
    () => getSingleNFTService({ tokenId: tokenId || '', contract: contract || '' }),
    { refetchOnWindowFocus: false, refetchInterval: false },
  );

  useTitle({ title: data?.metadata.name || '' });

  return (
    <FetchWrapper loading={isLoading} error={data === null}>
      <BackButton />
      <Information data={data} onRefresh={refetch} />
      {data?.category !== 'land' && (
        <NftAttributes
          attributes={
            data?.attributes
              ? Object.entries(data?.attributes).map(([key, value]) => ({
                  text: { title: value, value: { text: key } },
                }))
              : []
          }
        />
      )}
      <SaleHistory history={data?.saleHistory} />
    </FetchWrapper>
  );
};

export default NftPage;
