import React, { CSSProperties } from 'react';
import { IAsset } from 'models/@types';

interface IProps {
  asset: IAsset;
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: CSSProperties;
}

const CMPImage: React.FunctionComponent<IProps> = ({ asset, width, height, style, className }) => {
  return (
    <img
      src={asset.src}
      alt={asset.alt}
      width={width}
      height={height}
      className={className}
      style={style}
    />
  );
};
export default CMPImage;
