import React from 'react';
import clsx from 'clsx';
import { OutlinedInputProps, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import Div from 'components/global/div';
import CMPInput from 'components/global/input';

interface LabeledInputProps<T extends string | number> {
  value: T | undefined;
  onChange?: (value: string) => void;
  type: OutlinedInputProps['type'];
  label: string;
  beforeInputHelperElement?: React.ReactNode;
  sx?: SxProps<Theme>;
  hasError?: boolean;
  disabled?: boolean;
  prevent?: boolean;
}

function LabeledInput<T extends string | number>({
  value,
  onChange,
  type,
  beforeInputHelperElement,
  label,
  sx,
  hasError,
  disabled,
  prevent,
}: LabeledInputProps<T>): React.ReactElement {
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  return (
    <Div
      className={clsx('d-flex mb-3', {
        'flex-row align-items-center justify-content-between': matches,
        'flex-column': !matches,
      })}
      sx={sx}
    >
      <Typography
        variant="body1"
        className={clsx({
          'mb-2 ms-5': !matches && beforeInputHelperElement,
          'mb-2 ms-2': !matches && !beforeInputHelperElement,
        })}
      >
        {label}
      </Typography>
      <div className="d-flex align-items-center">
        {beforeInputHelperElement ? (
          <div style={{ minWidth: 38 }}>{beforeInputHelperElement}</div>
        ) : null}
        <CMPInput
          className={clsx({ 'ms-2': beforeInputHelperElement })}
          filled
          autoComplete="off"
          fullWidth
          value={value}
          type={type}
          onChanged={onChange}
          hasError={hasError}
          disabled={disabled}
          prevent={prevent}
        />
      </div>
    </Div>
  );
}

export default LabeledInput;
