import React from 'react';
import { CustomIconProps, setColor } from '..';

const ZoomInIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="Group_18" data-name="Group 18" transform="translate(-781 -725)">
        <path
          id="Vector"
          d="M0,0H10"
          transform="translate(782 731)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,10V0"
          transform="translate(787 726)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
export default ZoomInIcon;
