import { styled } from '@mui/material';
import Utils from 'libs/Utils';
import ColorPalette from 'utils/ColorPalette';

export const classes = {
  icon: 'icon',
  open: 'open',
  close: 'close',
  count: 'count',
};

const Container = styled('div')(({ theme }) => ({
  borderRadius: 18,
  border: Utils.getAppTheme() === 'LIGHT' ? `1px solid ${theme.palette.divider}` : undefined,
  // overflow: 'hidden',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.filterBg,
  [theme.breakpoints.up('md')]: {
    borderRadius: 24,
  },
  [`& .${classes.icon}`]: {
    width: 16,
    height: 16,
    transitionDuration: 300,
  },
  [`& .${classes.open}`]: {
    transform: 'rotate(-180deg)',
    transition: '300ms ease all',
  },
  [`& .${classes.close}`]: {
    transform: 'rotate(0)',
    transition: '300ms ease all',
  },
  [`& .${classes.count}`]: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorPalette.activeBg,
    color: ColorPalette.active,
    fontWeight: 'bold',
    marginInlineStart: 16,
  },
}));

export const Button = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 16,
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent',
  height: 52,
  [theme.breakpoints.up('md')]: {
    height: 68,
  },
}));

export default Container;
