import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import { RootState } from 'store';
import { IMarketPlacePageParams } from 'routes/params';
import { setCategory } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import useTitle from 'hooks/useTitle';
import { NFTCategory } from 'models/@types';
import Categories from 'components/common/categories';
import Div from 'components/global/div';
import NFTListContainer, { classes } from './_styles';
import FiltersSection from './components/filters';
import NFTList from './components/nft-list';

const MarketPlacePage: React.FunctionComponent = () => {
  const { category: catParam } = useParams<IMarketPlacePageParams>();
  const navigate = useNavigate();
  useTitle({ title: 'Marketplace' });
  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const dispatch = useDispatch();
  const { category } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);
  const onChangeCategory = (cat: NFTCategory) => {
    navigate(`/${cat}`);
  };

  useLayoutEffect(() => {
    if (catParam) {
      dispatch(setCategory(catParam));
    }
  }, [catParam]);

  return (
    <>
      {matches && (
        <Categories activeItem={category} onChangeCategory={onChangeCategory} className="me-2" />
      )}
      <NFTListContainer>
        <Div className={classes.filtersCol}>
          <FiltersSection />
        </Div>
        <Div className={classes.listCol}>
          <NFTList />
        </Div>
      </NFTListContainer>
    </>
  );
};
export default MarketPlacePage;
