import React, { useState } from 'react';
import Strings from 'utils/Strings';
import { SaleMethodTab, saleMethodTabs } from 'pages/nft/types';
import TabView from 'components/common/tab-view';
import DialogModal from 'components/global/dialog-modal';
import AuctionSell from './_Auction';
import FixedPriceSell from './_Fixed';

interface IProps {
  open: boolean;
  onClose: () => void;
  tokenId: number;
  address: string;
}

const SellNFT: React.FunctionComponent<IProps> = ({ open, onClose, address, tokenId }) => {
  const [activeTab, setActiveTab] = useState<SaleMethodTab>('FIXED PRICE');
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title={Strings.saleMethod}
      contentProps={{ className: 'small-scrollbar' }}
    >
      <TabView
        items={saleMethodTabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        buttonStyle={{ height: 48 }}
      />
      {activeTab === 'FIXED PRICE' ? (
        <FixedPriceSell tokenId={tokenId} address={address} onSuccess={onClose} />
      ) : (
        <AuctionSell tokenId={tokenId} address={address} onSuccess={onClose} />
      )}
    </DialogModal>
  );
};
export default SellNFT;
