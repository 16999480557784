import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from 'components/icons/main/CloseIcon';

interface IDialogModalProps extends DialogProps {
  open: boolean;
  onClose: (e?: any) => void;
  title?: string;
  hideCloseIcon?: boolean;
  contentProps?: DialogContentProps;
  hideHeader?: boolean;
}

const DialogModal: React.FunctionComponent<IDialogModalProps> = ({
  open,
  onClose,
  title,
  hideCloseIcon,
  children,
  PaperProps,
  contentProps,
  hideHeader,
  ...props
}) => {
  const { palette } = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '24px',
          backgroundColor: theme => theme.palette.filterBg,
          backgroundImage: 'none',
          width: '100%',
          margin: 2,
          '@media screen and (min-width: 600px)': {
            marginInlineStart: '100px',
          },
        },
        ...PaperProps,
      }}
      {...props}
    >
      {!hideHeader && (
        <DialogTitle
          sx={{ height: 64, padding: 2, position: 'relative' }}
          className="d-flex align-items-center justify-content-end"
        >
          <Typography
            component="p"
            variant="body1"
            textAlign="center"
            fontWeight="bold"
            sx={{ width: '100%', position: 'absolute', margin: '0 -16px', zIndex: 1 }}
          >
            {title}
          </Typography>
          {!hideCloseIcon && (
            <IconButton onClick={onClose} sx={{ zIndex: 2 }}>
              <CloseIcon color={palette.text.secondary} />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent
        sx={{ '@media screen and (max-width: 600px)': { padding: 0 } }}
        {...contentProps}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
export default DialogModal;
