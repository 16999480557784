import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { setAppLoading } from 'store/app/actions';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import { forgotPasswordService } from 'services/User';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';
import CMPInput from 'components/global/input';
import ForgotPasswordIcon from 'components/icons/login/ForgotPasswordIcon';

interface IProps {
  open: boolean;
  onClose: () => void;
}

// function emailIsValid(email: string) {
//   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
// }

const ForgotPassword: React.FunctionComponent<IProps> = ({ open, onClose }) => {
  const [email, setEmail] = useState<string>('');
  const dispatch = useDispatch();

  const handleForgotPassword = async () => {
    if (Utils.emailValidation(email)) {
      dispatch(setAppLoading(true));
      await forgotPasswordService(email);
      dispatch(setAppLoading(false));
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="xs"
      contentProps={{ className: 'd-flex flex-column align-items-center small-scrollbar' }}
    >
      <ForgotPasswordIcon />
      <Typography variant="body1" fontWeight={700} className="mt-3">
        {Strings.forgotPassword}
      </Typography>
      <Typography
        fontWeight={600}
        textAlign="center"
        className="mt-4"
        sx={{ maxWidth: 450, lineHeight: '24px' }}
      >
        {Strings.forgotDesc}
      </Typography>
      <CMPInput
        placeholder={Strings.email}
        style={{ maxWidth: 450 }}
        value={email}
        onChanged={setEmail}
        className="mt-4"
        filled
        fullWidth
        defaultVariant
      />
      <CMPButton
        fullWidth
        className="mt-5"
        sx={{ minHeight: 56, maxWidth: 450 }}
        disabled={!Utils.emailValidation(email)}
        onClick={handleForgotPassword}
      >
        {Strings.emailMe}
      </CMPButton>
    </DialogModal>
  );
};
export default ForgotPassword;
