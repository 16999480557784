import React from 'react';
import { CustomIconProps, setColor } from '..';

const MarketPlaceIcon: React.FunctionComponent<CustomIconProps> = props => {
  const iconColor = setColor(props);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g id="marketplace" transform="translate(-622 -188)">
        <g id="shopping-cart" transform="translate(622 188)">
          <path id="Vector" d="M0,0H16V16H0Z" fill="none" opacity="0" />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M2.333,1.167A1.167,1.167,0,1,1,1.167,0,1.167,1.167,0,0,1,2.333,1.167Z"
            transform="translate(9.667 12.667)"
            fill={iconColor}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M2.333,1.167A1.167,1.167,0,1,1,1.167,0,1.167,1.167,0,0,1,2.333,1.167Z"
            transform="translate(4.333 12.667)"
            fill={iconColor}
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M2.393,1.793,2.26,3.427A.529.529,0,0,0,2.793,4H13a.532.532,0,0,0,.533-.493,1.971,1.971,0,0,0-1.993-2.14H3.347A1.818,1.818,0,0,0,2.94.56,1.773,1.773,0,0,0,1.66,0H.5A.5.5,0,0,0,0,.5.5.5,0,0,0,.5,1H1.66a.732.732,0,0,1,.733.793Z"
            transform="translate(0.833 0.833)"
            fill={iconColor}
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M11.007,0H.78A.536.536,0,0,0,.247.487l-.24,2.9A1.948,1.948,0,0,0,1.947,5.5H9.36a2,2,0,0,0,1.953-1.82l.22-3.113A.522.522,0,0,0,11.007,0Z"
            transform="translate(2.667 5.833)"
            fill={iconColor}
          />
        </g>
      </g>
    </svg>
  );
};
export default MarketPlaceIcon;
