import { Card, styled } from '@mui/material';

const CMPCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 340,
  borderRadius: '24px',
  background: theme.palette.filterBg,
  border: 0,
  overflow: 'hidden',
  transitionDuration: '150ms',
  cursor: 'pointer',
  ['&:hover']: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  },
  ['& .MuiCardMedia-root']: {
    objectFit: 'cover',
    padding: 0,
    background:
      theme.palette.mode === 'light'
        ? 'linear-gradient(to bottom, #EEE7F6 , #B4A5C8)'
        : 'linear-gradient(to bottom, #292037 , #534B5F)',
  },
  ['& .MuiCardContent-root']: {
    borderBottomRightRadius: 24,
    borderBottomLeftRadius: 24,
    borderTop: 0,
    borderRight: 1,
    borderLeft: 1,
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.mode === 'light' ? theme.palette.divider : theme.palette.filterBg,
  },
}));
export default CMPCard;
