import { styled } from '@mui/material';

export const classes = {
  form: 'form',
};

const AuthContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 'fit-content',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 450,
  margin: '8px auto',
  minHeight: 'calc(100vh - 232px)',
  [theme.breakpoints.up('sm')]: {
    minHeight: 'calc(100vh - 132px)',
    margin: '16px auto',
  },
  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 16,
    border: `1px solid ${theme.palette.divider}`,
    padding: 12,
    [theme.breakpoints.up('sm')]: {
      padding: 24,
    },
  },
}));
export default AuthContainer;
