import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Theme, useMediaQuery } from '@mui/material';
import { RootState } from 'store';
import { setLandView } from 'store/constants/actions';
import { setCategory, setForSale } from 'store/marketplace/actions';
import { IMarketPlaceState } from 'store/marketplace/types';
import { NFTCategory } from 'models/@types';
import { User } from 'models/User';
import NFTList from 'pages/market-place/components/nft-list';
import Sort from 'pages/market-place/components/sort';
import ViewSelect from 'pages/market-place/components/view-select';
import Categories from 'components/common/categories';
import TabView, { ITabItem } from 'components/common/tab-view';
import GDivider from 'components/global/divider';
import ForSaleIcon from 'components/icons/profile/ForSaleIcon';
import InventoryIcon from 'components/icons/profile/InventoryIcon';

export type ProfileItemTabs = 'Inventory' | 'For Sale';

interface IProps {
  wallet?: string;
}

const ProfileItems: React.FunctionComponent<IProps> = ({ wallet }) => {
  const dispatch = useDispatch();
  const user = useSelector<RootState, User | null>(state => state.User.user);
  const { category } = useSelector<RootState, IMarketPlaceState>(state => state.MarketPlace);
  const [activeTab, setActiveTab] = useState<ProfileItemTabs>('Inventory');
  const matches = useMediaQuery<Theme>('(min-width:1250px)', {
    noSsr: true,
  });

  useEffect(() => {
    dispatch(setLandView('grid'));
  }, []);

  const onChangeTab = (tab: ProfileItemTabs) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      dispatch(setCategory(category));
      dispatch(setForSale(tab === 'For Sale'));
    }
  };

  if (!user && !wallet) {
    return <Navigate to="/login" />;
  }

  if (!wallet && user && !user.wallet) {
    return null;
  }

  const onChangeCategory = (cat: NFTCategory) => {
    dispatch(setCategory(cat));
    dispatch(setForSale(activeTab === 'For Sale'));
  };

  const items: ITabItem<ProfileItemTabs>[] = [
    { key: 'Inventory', icon: InventoryIcon },
    { key: 'For Sale', icon: ForSaleIcon },
  ];

  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 mt-4 pb-4">
      <TabView
        w100
        className="justify-content-center"
        items={items}
        onTabChange={onChangeTab}
        activeTab={activeTab}
        buttonStyle={{ maxWidth: '120px', height: '48px' }}
        style={{ maxHeight: '48px' }}
      />
      <GDivider orientation="horizontal" sx={{ backgroundSize: '100%' }} />
      <div
        className={clsx('d-flex my-4 w-100 px-4', {
          'align-items-center justify-content-between': matches,
          'flex-column': !matches,
        })}
      >
        <Categories
          activeItem={category}
          onChangeCategory={onChangeCategory}
          fullWidth={!matches}
          withoutIcon
        />
        <div
          className={clsx('d-flex align-items-center', {
            'justify-content-between mt-2': !matches,
          })}
        >
          <Sort />
          <ViewSelect />
        </div>
      </div>
      <NFTList user={wallet || user?.wallet} centerGrid showFilters={false} />
    </div>
  );
};
export default ProfileItems;
