import { AnyAction, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import appReducer from './app/reducers';
import constantsReducer from './constants/reducers';
import marketplaceReducer from './marketplace/reducers';
import userReducer from './user/reducers';

const rootReducer = combineReducers({
  App: appReducer,
  User: userReducer,
  MarketPlace: marketplaceReducer,
  Constants: constantsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type ThunkCustomAction<T = Promise<void>> = ThunkAction<T, RootState, unknown, AnyAction>;

export type ThunkCustomDispatch = ThunkDispatch<any, unknown, AnyAction>;

const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== 'production'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, {}, enhancer);
export default store;
