import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RootState } from 'store';
import { setAppLoading } from 'store/app/actions';
import { UserStoreState } from 'store/user/types';
import Utils from 'libs/Utils';
import Strings from 'utils/Strings';
import { connectMetamaskService } from 'services/Wallet';
import { User } from 'models/User';
import CMPButton from 'components/global/button';
import LogoIcon from 'components/icons/LogoIcon';
import CopyIcon from 'components/icons/profile/CopyIcon';
import EditIcon from 'components/icons/profile/EditIcon';
import EditNameModal from './edit-name-modal';

interface IProps {
  userData?: User;
}

const ProfileSummaryInformation: React.FunctionComponent<IProps> = ({ userData }) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const { user, balance } = useSelector<RootState, UserStoreState>(state => state.User);
  const dispatch = useDispatch();

  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const handleEditNameModalOnClose = () => {
    setOpen(false);
  };

  if (!user && !userData) {
    return null;
  }

  const handleCopyAddress = () => {
    if (userData) {
      Utils.copyToClipboard(userData.wallet);
    } else if (user && user.wallet) {
      Utils.copyToClipboard(user.wallet);
    }
  };

  const handleVerifyWallet = async () => {
    dispatch(setAppLoading(true));
    await connectMetamaskService();
    dispatch(setAppLoading(false));
  };

  return (
    <>
      <div className="d-flex align-items-center mt-4">
        <Typography
          variant="h4"
          className="mr-2"
          textAlign="center"
          sx={{ marginBottom: '4px', fontSize: 36 }}
        >
          {userData ? userData.name : user ? user.name : '--'}
        </Typography>
        {!userData && (
          <IconButton onClick={() => setOpen(true)}>
            <EditIcon color={palette.primary.main} />
          </IconButton>
        )}
      </div>
      {user && !userData && (
        <div className="d-flex align-items-center mt-4">
          <Typography component="span" color="textSecondary" variant="body1">
            Balance:
          </Typography>
          <Typography component="span" variant="h6" className="ms-2">
            {balance}
          </Typography>
        </div>
      )}
      {(user && user.wallet) || (userData && userData.wallet) ? (
        <div
          className={`d-flex align-items-center ${matches ? 'flex-row my-4' : 'flex-column my-2'}`}
        >
          <div className={`d-flex align-items-center ${matches ? 'mr-5' : ''}`}>
            <LogoIcon />
            <Typography className="ml-2">
              {userData ? userData.minimizedWallet : user ? user.minimizedWallet : '--'}
            </Typography>
          </div>
          <CMPButton
            variant="text"
            onClick={handleCopyAddress}
            startIcon={<CopyIcon color={palette.text.secondary} />}
            color="inherit"
            sx={{ width: 84, height: 32 }}
          >
            <Typography color="text.secondary">{Strings.copy}</Typography>
          </CMPButton>
        </div>
      ) : (
        !userData && (
          <CMPButton
            sx={{ height: 56, width: '100%', maxWidth: 320, my: 2, fontSize: '18px' }}
            onClick={handleVerifyWallet}
          >
            {Strings.verifyWallet}
          </CMPButton>
        )
      )}
      <EditNameModal open={open} onClose={handleEditNameModalOnClose} />
    </>
  );
};

export default ProfileSummaryInformation;
