import QueryRequest, { IQuery } from './Query';

export interface IPagination {
  pageSize?: number;
  pageNumber: number;
}

export const PAGINATION_SIZE = 12;

export default class Pagination implements QueryRequest {
  pageSize: number;
  pageNumber: number;

  constructor({ pageSize = PAGINATION_SIZE, pageNumber }: IPagination) {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }

  queries(): IQuery[] {
    return [
      {
        key: 'pageSize',
        value: this.pageSize.toString(),
      },
      { key: 'pageNumber', value: this.pageNumber.toString() },
    ];
  }
}
