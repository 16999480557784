import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Theme, useMediaQuery } from '@mui/material';
import { IEditProfileReq } from 'apis/user/_';
import { editUserProfileAction } from 'store/user/actions';
import Strings from 'utils/Strings';
import LabeledInput from 'components/common/labeled-input';
import CMPButton from 'components/global/button';
import DialogModal from 'components/global/dialog-modal';

interface IProps {
  open: boolean;
  onClose: () => void;
}

type EditForm = {
  name?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
};

const EditNameModal: React.FunctionComponent<IProps> = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const [editForm, setEditForm] = useState<EditForm>();

  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!editForm) return;
    const reqParam: IEditProfileReq = {};

    if (editForm?.name && editForm.name.trim().length > 0) reqParam.name = editForm.name.trim();
    if (editForm?.email && editForm.email.trim().length > 0) reqParam.email = editForm.email.trim();
    if (
      editForm?.password &&
      editForm?.confirmPassword &&
      editForm.password.trim().length > 0 &&
      editForm.password === editForm.confirmPassword
    ) {
      reqParam.password = editForm.password.trim();
    }
    await dispatch(editUserProfileAction(reqParam));
    setEditForm(undefined);
    onClose();
  };

  return (
    <DialogModal open={open} maxWidth="sm" title={Strings.editUser} onClose={onClose}>
      <form
        className="d-flex flex-column justify-content-between"
        style={{ minHeight: 200, margin: 16 }}
        onSubmit={handleFormSubmit}
      >
        <LabeledInput
          label={Strings.name}
          onChange={val => setEditForm(prev => ({ ...prev, name: val }))}
          type="text"
          value={editForm?.name}
          sx={{ mt: matches ? 4 : 2 }}
        />
        <LabeledInput
          label={Strings.email}
          onChange={val => setEditForm(prev => ({ ...prev, email: val }))}
          type="email"
          value={editForm?.email}
          sx={{ mt: matches ? 4 : 2 }}
        />
        <LabeledInput
          label={Strings.password}
          onChange={val => setEditForm(prev => ({ ...prev, password: val }))}
          type="password"
          value={editForm?.password}
          sx={{ mt: matches ? 4 : 2 }}
        />
        <LabeledInput
          label={Strings.confirmPassword}
          onChange={val => setEditForm(prev => ({ ...prev, confirmPassword: val }))}
          type="password"
          value={editForm?.confirmPassword}
          sx={{ mt: matches ? 4 : 2 }}
        />
        <CMPButton sx={{ height: 56, width: '100%', mt: 3, fontSize: '18px' }} type="submit">
          {Strings.submit}
        </CMPButton>
      </form>
    </DialogModal>
  );
};

export default EditNameModal;
