const Strings = {
  login: 'Login',
  noName: '--',
  metamaskLogin: 'Login With MetaMask',
  acceptTerms: 'By continuing, you agree to our Terms of Use & Privacy Policy',
  email: 'Email',
  buy: 'Buy',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  rememberMe: 'Remember Me',
  forgotPass: 'Forgot Password?',
  signupText: 'Do not have account? Signup',
  details: 'Details',
  loginText: 'Already have an account? Login',
  signup: 'Signup',
  reset: 'Reset',
  discord: 'Discord',
  terms: 'Terms of Use',
  privacy: 'Privacy Policy',
  characterId: 'Character ID',
  landId: 'Land ID',
  petId: 'Pet ID',
  apply: 'Apply',
  filter: 'Filter',
  clear: 'Clear',
  races: 'Races',
  saleTypes: 'Sale Types',
  regions: 'Regions',
  rarity: 'Rarity',
  attrs: 'Attributes',
  gender: 'Gender',
  types: 'Types',
  forSale: 'For Sale',
  saleHistory: 'Sale History',
  attributes: 'Attributes',
  attrAndRarities: 'Attributes & Rarities',
  effect: 'Effect',
  items: 'Items',
  statistics: 'Statistics',
  listing: 'Latest LISTING',
  sales: 'Latest SALES',
  saleMethod: 'Sale Method',
  buyMethod: 'Buy Method',
  offer: 'Offer',
  forgotPassword: 'Forgot Password?',
  copied: 'Copied to you clipboard.',
  forgotDesc:
    'Enter the email address linked with your account. we will email you a link to reset your password',
  emailMe: 'Email Me',
  completeAccount: 'Complete Your Account!',
  connectToSignup:
    'Please connect your Metamask wallet inorder to create an account in Cosmic NFT Marketplace',
  completeDesc: "Welcome! What should we call you? Let's complete setting up your account.",
  name: 'Name',
  save: 'Save',
  verify: 'Verify Your Email!',
  verifyDesc: (email: string): string =>
    `We have sent you a verification code in ${email}, Please enter the code.`,
  vCode: 'Verification Code',
  confirm: 'Confirm',
  logOut: 'Logout',
  notifications: 'Notifications',
  activity: 'Activity',
  copy: 'Copy',
  nothingFound: 'No item found.',
  error: 'An error occurred',
  applyFilters: 'Apply Filters',
  successSignup: 'You have successfully signed up.',
  successLogin: 'You have successfully logged in.',
  duration: 'Duration',
  minPrice: 'Minimum Price',
  price: 'Price',
  startTime: 'Start Time',
  royaltyBps: 'Royalty',
  days: 'Days',
  owner: 'OWNER',
  confirmSale: 'Confirm',
  confirmOffer: 'Confirm',
  confirmBuy: 'Confirm',
  fixedPriceDesc:
    "Your asset will be listed on the marketplace with a fixed price. In order to get it back, you'll have to cancel the sale.",
  startAuction: 'Start auction',
  saleHelper: (value: string, percent: string): string =>
    `You'll receive ${value} after subtracting a ${percent}% fee`,
  auctionDesc:
    'Auctions can either price up or down over time. When time expires, the price will stop changing, but your asset will still stay in the Marketplace. In order to bring it back to your inventory, you have to cancel the auction.',
  editUser: 'Edit User',
  submit: 'Submit',
  verifyWallet: 'Verify Wallet',
  profileEdited: 'Your profile has been updated.',
  noEdited: 'Enter the new name correctly',
  installMetamask: 'Metamask is not installed in your device. Install it first',
  walletVerified: 'Your wallet has been successfully verified.',
  date: 'Date',
  successOffer: 'Your offer has been made successfully.',
  saleSuccess: 'Your Sale has been added successfully',
  buySuccess: 'Your Buy has been added successfully',
  saleCancel: 'Your Sale has been canceled successfully',
  offerCancel: 'Your Offer has been canceled successfully',
  emailVerified: 'Your email address verified successfully',
  verificationCode: 'Please check your email for verification code',
  resetPassword: 'Reset Your Password',
  harmony: 'You are not connected to BSC Testnet.',
  disconnectWallet: 'You have to login again after your wallet is disconnected.',
  passwordReset: 'Your password has been reset. You can login with your new password.',
  finalize: 'Your finalize operation has been done successfully.',
  offers: 'Offers',
  acceptOffers: 'Accept Offer',
  successAcceptOffers: 'This Offer has been accepted successfully',
  rank: 'Overall Rank',
  genderRank: 'Gender Rank',
  cancelButton: 'Cancel Sale',
  finalizeButton: 'Finalize',
};
export default Strings;
