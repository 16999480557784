import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Fade, Menu, Typography, ListItemButton } from '@mui/material';
import { deleteUserDataAction } from 'store/user/actions';
import Utils from 'libs/Utils';
import ColorPalette from 'utils/ColorPalette';
import Strings from 'utils/Strings';
import { getUserActivitiesService, IActivityResponse } from 'services/User';
import GIconButton from 'components/global/icon-button';
import LogoutIcon from 'components/icons/profile/LogoutIcon';
import NotificationIcon from 'components/icons/profile/NotificationIcon';

const ProfileSummaryActions: React.FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data } = useQuery<IActivityResponse>(
    ['user-activities'],
    () => getUserActivitiesService(),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(deleteUserDataAction());
    navigate('/login');
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center px-4"
        style={{ position: 'relative' }}
      >
        <div className="d-flex align-items-center">
          <GIconButton
            sx={{
              width: '42px',
              height: '42px',
              position: 'relative',
              '& svg': { position: 'absolute', left: '5px' },
            }}
            onClick={handleLogout}
          >
            <LogoutIcon color={ColorPalette.error} />
          </GIconButton>
          <Typography className="ml-2">{Strings.logOut}</Typography>
        </div>

        <div className="d-flex align-items-center">
          <Typography className="mr-2">{Strings.activity}</Typography>
          <GIconButton onClick={handleClick}>
            <NotificationIcon color={data?.hasActivity ? ColorPalette.active : undefined} />
          </GIconButton>
          {data && (
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } }}
              PaperProps={{
                sx: {
                  mt: 2,
                  boxShadow: 0,
                  width: 'fit-content',
                  maxWidth: '500px',
                  padding: 0,
                  background: theme => theme.palette.filterBg,
                  border: theme =>
                    Utils.getAppTheme() === 'LIGHT'
                      ? `1px solid ${theme.palette.divider}`
                      : undefined,
                  borderRadius: '24px',
                  overflow: 'hidden',
                  marginTop: '8px',
                  p: 2,
                },
              }}
              MenuListProps={{
                disablePadding: true,
                className: 'small-scrollbar pe-3',
                sx: { maxHeight: '400px', overflow: 'auto' },
              }}
            >
              {data.activities.map(item => {
                const Icon = item.category.icon;
                return (
                  <ListItemButton
                    key={item.createdAt}
                    onClick={() =>
                      navigate(`/${item.category.catName}/${item.contract}/${item.tokenIds[0]}`)
                    }
                    className="d-flex"
                    sx={{
                      minHeight: '62px',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      '@media screen and (min-width: 600px)': {
                        flexDirection: 'row',
                        alignItems: 'center',
                      },
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <Typography
                        color="textSecondary"
                        sx={{
                          width: '128px',
                          '@media screen and (min-width: 600px)': {
                            width: '142px',
                          },
                        }}
                      >
                        {item.time}
                      </Typography>
                      <Icon />
                    </div>
                    <Typography
                      sx={{
                        maxWidth: '240px',
                        '@media screen and (min-width: 600px)': { ml: 3 },
                      }}
                    >
                      {item.typeText} {item.category.name} #{item.IDs}
                    </Typography>
                  </ListItemButton>
                );
              })}
            </Menu>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileSummaryActions;
