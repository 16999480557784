import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import Strings from 'utils/Strings';
import ContractContainer from 'components/global/contract-container';
import Div from 'components/global/div';

interface IProps {
  name: string;
  contract: string;
}

const InformationOwner: React.FunctionComponent<IProps> = ({ contract, name }) => {
  const navigate = useNavigate();

  const matches = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'), {
    noSsr: true,
  });
  const desktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'), {
    noSsr: true,
  });

  return (
    <Div sx={{ my: matches ? 1 : 4, width: '100%', maxWidth: 444 }}>
      <Typography variant="body1" sx={{ my: 0.5 }} color="textSecondary">
        {Strings.owner}
      </Typography>
      <div
        className={clsx('d-flex', {
          'align-items-center': desktop,
          'flex-column align-items-start': !desktop,
        })}
      >
        <Typography
          variant="body1"
          component="span"
          className="d-flex align-items-center me-4"
          noWrap
          sx={{ cursor: name !== '--' ? 'pointer' : undefined, minWidth: 'fit-content' }}
          onClick={name !== '--' ? () => navigate(`/users/${contract}`) : undefined}
        >
          <Typography
            sx={{ marginBottom: '4px', marginInlineEnd: 1 }}
            variant="body1"
            component="span"
          >
            @
          </Typography>
          {name}
        </Typography>
        <ContractContainer
          containerClassName={clsx('d-flex align-items-center', { 'align-self-end': desktop })}
          containerSx={{
            height: 30,
            px: desktop ? 2 : 1,
            mx: desktop ? 1 : 0,
            my: desktop ? 0 : 2,
            cursor: name !== '--' ? 'pointer' : undefined,
          }}
          variant={desktop ? 'body2' : 'caption'}
          onClick={name !== '--' ? () => navigate(`/users/${contract}`) : undefined}
        >
          {contract}
        </ContractContainer>
      </div>
    </Div>
  );
};

export default InformationOwner;
