import React from 'react';
import { Typography } from '@mui/material';
import { KeyValueOutputType } from 'models/@types';
import Div from '../div';

interface IProp extends KeyValueOutputType {}

const KeyValueOutput: React.FunctionComponent<IProp> = ({
  title,
  subTitle,
  value,
  titleProps,
  valueTextProps,
  rootSx,
}) => {
  return (
    <Div sx={{ ...rootSx }}>
      <Typography
        sx={{ textTransform: 'uppercase', my: 0.5 }}
        color="textSecondary"
        variant="body1"
        {...titleProps}
      >
        {title}
        {subTitle && (
          <Typography color="textSecondary" className="ms-3" component="span">
            {subTitle}
          </Typography>
        )}
      </Typography>
      <Div className="d-flex align-items-center">
        {value.icon}
        <Typography
          sx={{ mx: value.icon ? 1 : 0, textTransform: 'capitalize' }}
          variant="body1"
          {...valueTextProps}
        >
          {value.text}
        </Typography>
      </Div>
    </Div>
  );
};

export default KeyValueOutput;
